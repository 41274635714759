import url from "../../../utils/ApiValues/url";
import label from "../../../utils/constants/label";
import Networking from "../../../utils/network/apiaccess";
import * as actionType from "../../action_type/action_type";


export function TestMiri(data, onSuccess,onFailure) {

    return (dispatch) => {
        getTestMiriAction(dispatch,data, onSuccess,onFailure);
      }
  }
  function getTestMiriAction(dispatch,data, onSuccess,onFailure) {
       dispatch({
        type: actionType.LOADER_TEST_MIRI,
        payload: true,
      })
      dispatch({
        type: actionType.EMPTY_TEST_MIRI,
        payload: [],
      })
      Networking.PostApi(url.TestMiri,data)
      .then(result=>{ 
        if(result.status=="S"){
          dispatch({
            type: actionType.LOADER_TEST_MIRI,
            payload: false,
          })
          dispatch({
            type: actionType.TEST_MIRI,
            payload: result,
          })
        onSuccess(result)
       
        }else{
          dispatch({
            type: actionType.LOADER_TEST_MIRI,
            payload: false,
          })
        onFailure(result)
        }
        })
      .catch(function (error) {
        onFailure(label.apiError.status)
          dispatch({
            type: actionType.LOADER_TEST_MIRI,
            payload: false,
          })
               // 
       });
  //  setTimeout(() => {

  }
