const Domain = window.globalConfig.baseUrl
export default {
    Domain,
    Login: `${Domain}${"/api/Miri/ValidateMiri"}`,
    Products: `${Domain}${"/api/Miri/GetProducts"}`,
    BankList: `${Domain}${"/api/Miri/GetBankDetails"}`,
    ProfileCreation: `${Domain}${"/api/Miri/CreateProfile"}`,
    CheckEmail: `${Domain}${"/api/Miri/CheckEmail"}`,
    TestMiri: `${Domain}${"/api/Miri/TestMiriID"}`,
    UploadUserProfile: `${Domain}${"/api/Miri/UpdateUserDetails"}`
}