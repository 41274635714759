import React from "react";
import { withStyles } from "@material-ui/core/styles";
import color from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Skeleton from "@material-ui/lab/Skeleton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Popover from '@material-ui/core/Popover';
import { Typography } from "@material-ui/core";
import ImageCapture from './imageCapture';
import Alert from "../../component/alert/alert";
import DefaultProfile from '../../utils/assets/avatar.png'

const styles = (theme) => ({
  textBoxStyle: {
    minHeight: 40,
    width: "auto",
    outline: "none",
    borderRadius: 5,
    border: `1px solid ${color.LIGHT_BLACK}`,
    paddingLeft: "5px",
  },
  labelStyle: {
    fontSize: 15,
    color: color.LIGHT_BLACK,
    paddingBottom: 10,
  },
  errorStyle: {
    fontSize: 14,
    color: color.ERROR,
  },
  helperTextStyle: {
    fontSize: 14,
    color: color.ASH,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1.5rem 0",
    "& span + label": {
      display: "flex",
      position: "absolute",
    },
  },
  profileStyle: {
    width: 122,
    height: 122,
    borderRadius: "50%",
    border: `3px solid ${color.SECONDARY}`,
    backgroundColor: color.LIGHT_SECONDARY_BACKGROUND,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0.1rem 1rem 0.25rem",
  },
  editUser: {
    ZIndex: 2,
    position: "absolute",
    borderRadius: 12,
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.45rem 6.5rem",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      boxShadow: "0px 0px 10px",
      color: color.SHADOW_PURPLE,
      borderRadius: "50%",
      padding: 5,
      width: "0.85em",
      height: "0.85em",
      fontSize: "1.25rem",
      color: color.SECONDARY,
      backgroundColor: color.WHITE,
    },
  },
  imageStyle: {
    height: 140,
    width: 140,
    marginTop: -5,
    borderRadius: "50%",
    border: `3px solid ${color.SECONDARY_LIGHT}`,
    cursor: "pointer",
  },
  centerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  secondaryImageStyle: {
    height: 40,
    width: 40,
    marginTop: -5,
  },
  secondaryUserImageStyle: {
    width: 140,
    height: 140,
    borderRadius: "50%",
  },
  loaderSkeletonStyle: {
    height: 141,
    width: 141,
    position: "absolute",
    backgroundColor: color.GREY,
    borderRadius: "50%",
    transform: "none",
    transformOrigin: "unset",
  },
  skeletonStyle: {
    height: 228,
    width: 140,
    marginTop: -5,
    borderRadius: "50%",
    position: "absolute",
  },
  popoverListStyle: {
    '&:hover': {
      background: color.LIGHT_SECONDARY_BACKGROUND,
    },
  }
});

class Upload extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      selectFileOption: null,
      isImageCapture: false,
      cameraErrMessage: "",
      imageError: false,
    };
  }
  componentDidUpdate(prev) {
    const { isLoading } = this.props;
    if (isLoading != undefined) {
      if (this.state.loading != isLoading) {
        this.setState({ loading: isLoading });
      }
    }
  }
  fileSelect = (e) => {
    this.setState({ selectFileOption: e.currentTarget })
  }
  uploadFile = () => {
    this.setState({ imageError: false })
    this.setState({
      selectFileOption: null
    })
    let file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = "image/png, image/jpg, image/jpeg"
    file.click();
    file.onchange = (e) => {
      this.props.onChange(e)

    }
  }

  cameraError = (e) => {
    this.setState({
      isImageCapture: false,
      cameraErrMessage: e
    })

  }

  onClose = () => {
    this.setState({ cameraErrMessage: "" });
  };

  render() {
    const { classes } = this.props;
    console.log(this.state.isImageCapture);
    return (
      <ErrorBoundary>
        <div className={classes.container}>
          {this.state.isImageCapture &&
            <ImageCapture isModalOpen={this.state.isImageCapture} onError={(e) => { this.cameraError(e) }} onChange={(e) => {
              this.props.onChange(e); this.setState({ isImageCapture: false })
            }} />
          }
          <Alert
            open={this.state.cameraErrMessage.length > 0 ? true : false}
            message={this.state.cameraErrMessage}
            onClose={this.onClose}
            severity={"warning"}
          ></Alert>
          <Popover
            open={Boolean(this.state.selectFileOption)}
            anchorEl={this.state.selectFileOption}
            onClose={() => {
              this.setState({ selectFileOption: null })
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div style={{ padding: 10, display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, cursor: "pointer" }} className={classes.popoverListStyle} onClick={() => this.uploadFile()}>
                <CloudUpload style={{ padding: 5 }}
                />
                <Typography style={{ padding: 5 }}> Upload from my computer</Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: 5, cursor: "pointer" }} className={classes.popoverListStyle} onClick={() => this.setState({ imageError: false, isImageCapture: true, selectFileOption: null })}>
                <PhotoCamera style={{ padding: 5 }} />
                <Typography style={{ padding: 5 }}> Capture image</Typography>
              </div>
            </div>
          </Popover>
          <label onClick={(e) => this.fileSelect(e)}
            className={
              !this.props.editIcon
                ? [classes.imageStyle, classes.centerDiv].join(" ")
                : classes.imageStyle
            }
            htmlFor={this.props.disable ? "" : this.props.id}
          >
            <>
              {this.props.editIcon && (
                <div className={classes.editUser}>
                  <EditTwoToneIcon />
                </div>
              )}
              {this.state.loading && (
                <Skeleton className={classes.loaderSkeletonStyle}></Skeleton>
              )}
              {this.props.URL != "" ? (
                <>
                  {this.state.imageError ?
                    <img
                      src={DefaultProfile}
                      className={classes.secondaryUserImageStyle}
                    ></img>
                    :
                    <img
                      onError={() => this.setState({ imageError: true })}
                      src={this.props.URL}
                      className={classes.secondaryUserImageStyle}
                      onLoad={() => this.setState({ loading: false })}
                    ></img>
                  }
                </>
              ) : (
                !this.props.isLoading && <div onLoad={() => { this.setState({ loading: false }) }}>{this.props.placeholder}</div>
              )}
            </>
          </label>
          {this.props.error == "" ? (
            <span className={classes.helperTextStyle}>
              {this.props.helperText}
            </span>
          ) : (
            <span className={classes.errorStyle}>{this.props.error}</span>
          )}
        </div>
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(Upload);
