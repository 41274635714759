import { replaceNull } from "../../../utils/common_function/common_function";
import * as actionType from "../../action_type/action_type";
const initialState = {
  testMiriDetails: [],
  isLoading: false,
 
};
const test_miri_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TEST_MIRI:
      // 
      return {
        ...state,
        testMiriDetails: replaceNull(action.payload, ""),
      };
    case actionType.LOADER_TEST_MIRI:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.EMPTY_TEST_MIRI:
      return {
        ...state,
        testMiriDetails: [],
        isLoading: false,
      };
     
    default:
      return state;
  }
};
export default test_miri_reducer;
