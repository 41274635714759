import React from "react";
import { withStyles } from "@material-ui/core/styles";
import color from "../../utils/constants/color";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ErrorBoundary from "../error_boundary/error_boundary";

const styles = (theme) => ({
  textBoxStyle: {
    minHeight: 40,
    width: "auto",
    outline: "none",
    borderRadius: 5,
    border: `1px solid ${color.LIGHT_BLACK}`,
    paddingLeft: "5px",
    fontFamily: 'Noto Sans !important',
    color: color.BLACK,
    fontSize: 16,
    opacity:(p)=>p.disabled==true?0.5:1,
    cursor:(p)=>p.disabled==true?"not-allowed":"text"
  },
  labelStyle: {
    fontSize: 14,
    color: color.BLACK,
    paddingBottom:5,
    fontWeight: 400,
  },
  errorStyle: {
    fontSize: 12,
    color: color.ERROR,
    //letterSpacing: 1,
    height:14,
    marginTop: 5
  },
  helperTextStyle: {
    fontSize: 14,
    color: color.ASH,
    //letterSpacing: 1,
    height:14
  },
  container:{
      display:"flex",
      flexDirection:"column",
      minHeight:88,

      // '& :after': {
      //   content: '',
      //   position: 'absolute',
      //   left: '-50px',
      //   top: '50px',
      //   width: '0',
      //   height: '0',
      //   border: '50px solid red',
      //   borderTopColor: 'red',
      // }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    // background: "#F4F6FC",
    //backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    // background:"#F2F4F5",
    background:color.LIGHT_ASH,
    '& .MuiIconButton-root':{
      color:color.BLACK
    },

    '& .MuiInputBase-root':{
      color: color.BLACK
    }
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& ::-webkit-input-placeholder": {
      color: theme.palette.text.placeholder,
      fontSize: 14
    }
  },

  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  cursor: {
    cursor: 'context-menu'
  }
});

class TextBox extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    if (this.props.type == "Primary") {
      return (
        <ErrorBoundary>
        <div className={classes.container} >
          {/* <div> */}

            <span className={classes.labelStyle}>{this.props.label} {this.props.required!=undefined&&<span className={classes.errorStyle}>*</span>}</span>
            <input
              placeholder={this.props.placeholder}
              type={this.props.password==undefined ? "text" : "password"}
              value={this.props.value}
              className={classes.textBoxStyle}
              required={this.props.required!=undefined?true:false}
              // id={this.props.id}
              // style={{ textAlign: this.props.align != undefined ? this.props.align : "left", paddingRight: this.props != undefined ? this.props.paddingAlign : "0px", paddingLeft: this.props != undefined ? this.props.paddingLeftAlignment : "13px" }}
              {...this.props.otherProps}
              autoComplete="new-password"
              disabled={this.props.disabled}
              onChange={this.props.onChange}
            />
            {this.props.error == "" ? (
              <span className={classes.helperTextStyle}>{this.props.helperText}</span>
            ) : (
              <span className={classes.errorStyle}>{this.props.error}</span>
            )}
          {/* </div> */}
        </div>
        </ErrorBoundary>
      );
    } else {
      return(
        <ErrorBoundary>
        <Paper component="form" elevation={0} className={classes.root} style={this.props.style}>
        <IconButton 
        // className={classes.iconButton} 
        aria-label="search" className={classes.cursor}>
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          color="secondary"
          value={this.props.value}
        />
      </Paper>
      </ErrorBoundary>
      )
    }
  }
}
export default withStyles(styles)(TextBox);
