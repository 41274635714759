import { combineReducers } from 'redux'
import LoginReducer from './login/login_reducer';
import EntryFormListReducer from './List/entry_form_list_reducer'
import BankList from './List/bank_list_reducer'
import ProductList from './List/product_list_reducer'
import Header from './header/header_reducer'
import  TestMiri  from './login/test_miri_reducer';
export default combineReducers({

    Login: LoginReducer,
    EntryFormList:EntryFormListReducer,
    BankList:BankList
    ,ProductList:ProductList,
    Header:Header,
    TestMiri:TestMiri
  
})
