import url from "../../../utils/ApiValues/url";
import label from "../../../utils/constants/label";
import Networking from "../../../utils/network/apiaccess";
import * as actionType from "../../action_type/action_type";

export function getEntryFormList(data,token, storeData, onSuccess, onFailure) {
  return (dispatch) => {
    getEntryFormListAction(dispatch, data,token, storeData, onSuccess, onFailure);
  };
}
function getEntryFormListAction(
  dispatch,
  data,
  token,
  storeData,
  onSuccess,
  onFailure
) {
  dispatch({
    type: actionType.LOADER_ENTRY_FORM_DETAILS,
    payload: true,
  });
  dispatch({
    type: actionType.EMPTY_ENTRY_FORM_DETAILS,
    payload: [],
  });
  Networking.PostApiWithToken(url.ProfileCreation, data,token)
    .then((response) => {
      dispatch({
        type: actionType.LOADER_ENTRY_FORM_DETAILS,
        payload: false,
      });
   
      if (response.status == "S") {
        dispatch({
          type: actionType.ENTRY_FORM_DETAILS,
          payload:response.data,
        });
        onSuccess(response);
      } else {
       
        onFailure(response);
        if(response.status==401||response.status==405){
          dispatch({
            type: actionType.SESSION_EXPIRE,
            payload:true,
          });
        }
      }
     
    })
    .catch((err) => {
      
      onFailure(label.apiError.status);
      dispatch({
        type: actionType.LOADER_ENTRY_FORM_DETAILS,
        payload: false,
      });
      dispatch({
        type: actionType.EMPTY_ENTRY_FORM_DETAILS,
        payload: [],
      });
    });

}
export function checkEmailDuplicate(data,token, onSuccess, onFailure) {
  return (dispatch) => {
    checkEmailDuplicateAction(dispatch, data,token
      , onSuccess, onFailure);
  };
}
function checkEmailDuplicateAction(dispatch, data,token, onSuccess, onFailure) {
  Networking.PostApiWithToken(url.CheckEmail, data,token)
    .then((response) => {
      if (response.status == "S") {
        if(response.data!=null){
          if(JSON.parse(response.data).Table[0].Status=="S"){
            onSuccess(response);
          }else{
            
            onFailure(JSON.parse(response.data).Table[0].Message);
            if(response.status==401||response.status==405){
              dispatch({
                type: actionType.SESSION_EXPIRE,
                payload:true,
              });
            }
          }
        }
      } else {
        
        onFailure(response.message);
        if(response.status==401||response.status==405){
          dispatch({
            type: actionType.SESSION_EXPIRE,
            payload:true,
          });
        }
      }
    })
    .catch((err) => {
      onFailure(label.messages.apiError);
    });
}
