import { useEffect, useReducer } from "react";
import useKeyPress from "../../utils/common_function/focus";
import { makeStyles } from "@material-ui/core/styles";
import selectedTick from "../../utils/assets/img29.png";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import colors from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
import color from "../../utils/constants/color";

const initialState = { selectedIndex: "" };
const useStyles = makeStyles((theme) => {
  return {
    listStyleWithoutCheckbox: {
      padding: "8px 0px 8px 10px",
      margin: "auto",
      maxWidth: "100% !important",
      fontSize: "0.6rem !important",
      fontWeight: 500,
      overflow: "hidden",
      "&:hover": {
        backgroundColor:`${color.LIGHT_PURPLE} !important`,
      },
    },
    containerItemWithoutCheckbox: {
      position: "relative",
      cursor: "pointer",
      userSelect: "none",
      alignItems: "center",
      color:color.BLACK,
      margin: 0,
      width: "auto !important",
      fontSize: "14px",
      display: "block",
      fontWeight: "normal",
      textAlign: "left",
    },
    checkMark: {
      position: "absolute",
      top: "4px",
      left: 0,
      height: "18px",
      width: "18px",
      marginLeft: "3px",
      borderRadius: "3px",
      backgroundColor: color.WHITE,      
      border: `solid 1px ${color.GREY} !important`,
    },
    selectedCheckMark: {
      position: "absolute",
      top: "4px",
      left: 0,
      height: "18px",
      width: "18px",
      marginLeft: "3px",
      borderRadius: "3px",
      backgroundColor:color.WHITE,
      border: `solid 1px ${color.BROWN} !important`,
      background: `url(${selectedTick}) center center no-repeat`,
    },
    scrollableDivNormalSearch: {
      overflow: "auto",
      maxHeight: 143,
      color:color.BLACK,
      height: 140
    },
    scrollableDiv: {
      padding: 12,
      overflow: "auto",
      maxHeight: 245,
    },
    normalDropdownSelectedStyle: {
      backgroundColor:`${color.LIGHT_PINK}`,
      color: colors.brown,
    },
    options: {
      border: 0,
      marginTop: "-37px",
      width: "102%",
      marginLeft: "-1%",
      boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
      borderRadius: "5px",
      backgroundColor: color.WHITE,
      padding: 0,
      position: "absolute",
      top: "100%",
      right: 0,
      left: 0,
      zIndex: 999,
      margin: "3px 0px",
      listStyle: "none",
    },
    normalDropdownStyle: {
      backgroundColor: color.WHITE,
      "&:hover": {       
        backgroundColor:`${color.LIGHT_PINK} !important`,
      },
    },
    listStyle: {
      padding: "5px 0px",
      margin: "auto",
      maxWidth: "100% !important",
      fontSize: "0.6rem !important",
      fontWeight: 500,
      overflow: "hidden",
    
    },
    containerItem: {
      position: "relative",
      paddingLeft: "30px !important",
      cursor: "pointer",
      userSelect: "none",
      alignItems: "center",
      margin: 0,
      width: "auto !important",
      padding: "0.25rem 0px 0.25rem 33px !important",
      fontSize: "14px",
      display: "block",
      fontWeight: "normal",
      textAlign: "left",
    },
    normalDropdownOptions: {
      border: 0,
      marginTop: "1px",
      width: "102%",
      marginLeft: "-1%",
      boxShadow: "0px 0px 10px",
      color:color.SHADOW_PURPLE,
      borderRadius: "5px",
      backgroundColor: color.WHITE,
      padding: 0,
      position: "absolute",
      top: "100%",
      right: 0,
      left: 0,
      zIndex: 1,
      margin: "3px 0px",
      listStyle: "none",
    },
  };
});
function reducer(state, action) {
  switch (action.type) {
    case "arrowUp":
      return {
        selectedIndex:
          state.selectedIndex !== ""
            ? state.selectedIndex - 1
            : action.payload.length - 1,
      };
    case "arrowDown":
      return {
        selectedIndex:
          state.selectedIndex !== action.payload.length - 1
            ? state.selectedIndex == ""
              ? 0 + 1
              : state.selectedIndex + 1
            : 0,
      };
    case "select":
      return { selectedIndex: action.payload.index };
    default:
      throw new Error();
  }
}

const List = (props) => {
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const EnterPressed = useKeyPress("Enter");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: "arrowUp", payload: props.List });
    }
    if (EnterPressed) {
      if (state.selectedIndex != undefined) {
        if (props.onKeyPress != undefined) {
          props.onKeyPress(state.selectedIndex);
        }
      }
    }
  }, [arrowUpPressed, EnterPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      dispatch({ type: "arrowDown", payload: props.List });
    }
  }, [arrowDownPressed, EnterPressed]);

  const classes = useStyles();
  return (
    <ErrorBoundary>
      <ClickAwayListener onClickAway={props.onClickAway}>
        <ul
          className={
            props.type === "withcheckbox"
              ? classes.options
              : classes.normalDropdownOptions
          }
        >
          {props.children}
          <div
            className={
              props.type === "withcheckbox"
                ? classes.scrollableDiv
                : classes.scrollableDivNormalSearch
            }
            style={{
              maxHeight: props.height == undefined ? 245 : props.height,
            }}
          >
            {props.loader === true ? (
              <div
                style={{ paddingTop: 10, paddingBottom: 10, marginLeft: 10 }}
              >
                {"Loading..."}
              </div>
            ) : props.List.length == 0 ? (
              <div style={{ padding: 15 }}>{"No Data Available."}</div>
            ) : (
              props.List.map((item, i) => {
                return (
                  <div
                    className={
                      props.type == "withcheckbox"
                        ? ""
                        : i === state.selectedIndex || item.isChecked
                        ? classes.normalDropdownSelectedStyle
                        : classes.normalDropdownStyle
                    }
                  >
                    <li
                      className={
                        props.type === "withcheckbox"
                          ? classes.listStyle
                          : [
                              classes.listStyleWithoutCheckbox,
                              classes.sample,
                            ].join(" ")
                      }
                      key={item.value}
                      onClick={() => {
                        dispatch({
                          type: "select",
                          payload: { index: i, List: props.List },
                        });
                        if (props.onClick != undefined) {
                          props.onClick(item);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          i === state.selectedIndex
                            ? colors.RosyBrown
                            : "transparent",
                      }}
                      role="button"
                      aria-pressed={i === state.selectedIndex}
                      tabIndex={0}
                      onKeyPress={(e) => {
                        dispatch({
                          type: "select",
                          payload: { index: i, List: props.List },
                        });
                        if (props.onClick != undefined) {
                          props.onClick(item);
                        }
                      }}
                    >
                      {props.icon ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 20,
                            alignItems: "center",
                          }}
                          className={classes.containerItemWithoutCheckbox}
                        >
                          <img
                            src={props.iconList[i]}
                            style={{ width: 30, height: 30 }}
                          ></img>
                          <div>{item.label}</div>
                        </div>
                      ) : (
                        <label
                          className={
                            props.type === "withcheckbox"
                              ? classes.containerItem
                              : classes.containerItemWithoutCheckbox
                          }
                        >
                          {item.label}
                          {props.type === "withcheckbox" ? (
                            item.isChecked ? (
                              <span
                                className={classes.selectedCheckMark}
                              ></span>
                            ) : (
                              <span className={classes.checkMark}></span>
                            )
                          ) : (
                            ""
                          )}
                        </label>
                      )}
                    </li>
                  </div>
                );
              })
            )}
          </div>
        </ul>
      </ClickAwayListener>
    </ErrorBoundary>
  );
};

export default List;
