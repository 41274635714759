import labels from '../constants/label'
var axios = require('axios');
export default class Networking {
    static PostApi(url, data = '') {
        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                return response.data

            })
            .catch(function (error) {
                
                return labels.apiError.status
            });

    }
    static PostApiWithToken(url, data = '', token) {
        var config = {}
        config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
            data: data
        };
        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                
                if (error.response) {
                  
                        return {
                            status: error.response.status,
                            message: error.toString()
                        }
                    
                } else {
                  if(error.toString()=="Error: Network Error"){
                      return labels.apiError.status
                  }else{
                    if (error instanceof Error) {
                        return {
                            status: 401,
                            message: error.toString()
                        }
                    }
                  }
                    
                }
                return error.json().then((responseJson) => {
                    return {
                        error: new Error(
                            `HTTP ${error.status} ${error.statusText}: ${responseJson.msg}`
                        )
                    }
                })
            });
    }
    static GetApi(url, data = '',token) {
        var config = {
            method: 'get',
            url: url,
            headers: {
                
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return labels.apiError.status
            });

    }
    static GetApiWithToken(url, data = '',token) {
        var config = {
            method: 'get',
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                    
                if (error.response) {
                  
                    return {
                        status: error.response.status,
                        message: error.toString()
                    }
                
            } else {
              if(error.toString()=="Error: Network Error"){
                  return labels.apiError.status
              }else{
                if (error instanceof Error) {
                    return {
                        status: 401,
                        message: error.toString()
                    }
                }
              }
                
            }
            return error.json().then((responseJson) => {
                return {
                    error: new Error(
                        `HTTP ${error.status} ${error.statusText}: ${responseJson.msg}`
                    )
                }
            })
            });

    }
    // static FormPost(url, data = '') {
    //     const apiurl = ApiValues.BaseUrl + url;
    //     //  (apiurl)
    //     return fetch(apiurl, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'multipart/form-data',
    //             "apikey": "f3251620-b17e-45c9-9f38-60f31f27f7e8",
    //         },
    //         body: data
    //     })
    //         .then(response => response.json())
    //         .then(function (response) {
    //             return response;
    //         })
    //         .catch(error => {
    //             //  (error);
    //             return error;
    //         })
    // }
   
}
