import React from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Popover from "../../component/popover/popover";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { loggedOutAction } from "../../redux/action/login/login";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ErrorBoundary from "../error_boundary/error_boundary";
import Logo from "../../../src/utils/assets/favicon.png";
import Grid from "@material-ui/core/Grid";
import { updateHeader } from "../../redux/action/header/header";
import color from '../../utils/constants/color'
import label from "../../utils/constants/label";
const styles = (theme) => {
  return {
    container: {
      height: "max-content",
      padding: "1rem",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    userIcon: {
      width: 30,
      height: 30,
    },
    brand: {
      fontWeight: 600,
      color: color.SECONDARY,
      display: "flex",
      alignItems: "center",
      fontFamily: "Josefin Sans",
      fontSize: "1.5rem",
      marginLeft: "0.5rem",

      [theme.breakpoints.down('xs')]: {
        fontSize: "1.25rem",
      },
    },

    formHeader: {
      margin: 0,
      display: "flex",
      alignItems: "end",
      fontSize: "1rem",
    },

    logo: {
      height: 50,
    },
  };
};
class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  componentDidMount = () => {
    window.addEventListener("popstate", () => {
      if (this.props.headerName == "" || this.props.headerName.activeStep == null || this.props.headerName.activeStep == undefined || Number.isNaN(this.props.headerName.activeStep)) {
        let data = {};
        data.activeStep = 0;
        data.id = "";
        data.issuerID = "";
        data.completedStep = "";
        data.mainHeader = "Welcome";
        data.subHeader = "";
        data.name = "Popstate";
        data.enableBack = false;

        this.props.updateHeader(data);
        this.props.backIconPress();
      } else {
        if (this.props.headerName.activeStep == 0) {
          let data = {};
          data.activeStep = 0;
          data.id = this.props.headerName.id == null ? 1 : this.props.headerName.id;
          data.issuerID = this.props.headerName.issuerID;
          data.completedStep = "";
          data.mainHeader = "Welcome"
          data.subHeader = "";
          data.name = "Popstate";
          data.enableBack = Number.isNaN(this.props.headerName.activeStep) ? 0 : this.props.headerName.activeStep == 3 ? false : true;

          this.props.updateHeader(data);
          this.props.backIconPress();
        } else {
          if (this.props.headerName.activeStep == 2) {
            let data = {};
            data.activeStep = 2;
            data.id = this.props.headerName.id;
            data.issuerID = this.props.headerName.issuerID;
            data.completedStep = 1;
            data.mainHeader =
              this.props.headerName.activeStep == 1
                ? "Welcome"
                : this.props.headerName.id === 1
                  ? label.product.vkenSignIn
                  : this.props.headerName.id == 2
                    ? label.product.vken2FA
                    : this.props.headerName.id == 3 ? label.product.vkenPay : "Welcome";
            data.subHeader = "";
            data.name = "Popstate";
            data.enableBack = false;

            this.props.updateHeader(data);
            this.props.backIconPress();
          } else {
            let data = {};
            data.activeStep = Number.isNaN(this.props.headerName.activeStep) ? 0 : this.props.headerName.activeStep - 1;
            data.id = this.props.headerName.id;
            data.issuerID = this.props.headerName.issuerID;
            data.completedStep =
              this.props.headerName.completedStep != 0
                ? this.props.headerName.completedStep - 1
                : "";
            data.mainHeader =
              this.props.headerName.activeStep == 1
                ? "Welcome"
                : this.props.headerName.id === 1
                  ? label.product.vkenSignIn
                  : this.props.headerName.id == 2
                    ? label.product.vken2FA
                    : this.props.headerName.id == 3 ? label.product.vkenPay : "Welcome";
            data.subHeader = "";
            data.name = "Popstate";
            data.enableBack = this.props.headerName.activeStep == 3 ? false : true;

            this.props.updateHeader(data);
            this.props.backIconPress();
          }

        }
      }

    });
  };
  handleBack = () => {
    if (this.props.headerName.enableBack) {
      let data = {};
      data.activeStep = this.props.activeStep - 1;
      data.id = this.props.productID;
      data.issuerID = this.props.issuerID;
      data.completedStep = this.props.completedStep - 1;
      data.mainHeader =
        this.props.activeStep == 1
          ? "Welcome"
          : this.props.productID === 1
            ? label.product.vkenSignIn
            : this.props.productID == 2
              ? label.product.vken2FA
              : this.props.headerName.id == 3 ? label.product.vkenPay : "Welcome";
      data.subHeader = "";
      data.enableBack = this.props.activeStep == 3 ? false : true;
      this.props.updateHeader(data);

      if (this.props.headerName.enableBack) {
        this.props.backIconPress();
      }
    }
  };
  handleSteps = () => {

  }
  componentWillUnmount = () => {
    window.removeEventListener("popstate", () => {
      // 
      if (Number.isNaN(this.props.headerName.activeStep) || this.props.headerName.activeStep == null || this.props.headerName.activeStep == undefined) {
        if (this.props.headerName.activeStep == 0) {
          let data = {};
          data.activeStep = 0;
          data.id = "";
          data.issuerID = "";
          data.completedStep = "";
          data.mainHeader = "Welcome"
          data.subHeader = "";
          data.name = "Popstate";
          data.enableBack = false;
          this.props.updateHeader(data);
          this.props.backIconPress();
        } else {
          if (this.props.headerName.activeStep == 2) {
            let data = {};
            data.activeStep = 2;
            data.id = this.props.headerName.id;
            data.issuerID = this.props.headerName.issuerID;
            data.completedStep = 1;
            data.mainHeader =
              this.props.headerName.activeStep == 1
                ? "Welcome"
                : this.props.headerName.id === 1
                  ? label.product.vkenSignIn
                  : this.props.headerName.id == 2
                    ? label.product.vken2FA
                    : this.props.headerName.id == 3 ? label.product.vkenPay : "Welcome";
            data.subHeader = "";
            data.name = "Popstate";
            data.enableBack = false;
            this.props.updateHeader(data);
            this.props.backIconPress();
          } else {
            let data = {};
            data.activeStep = this.props.headerName.activeStep - 1;
            data.id = this.props.headerName.id;
            data.issuerID = this.props.headerName.issuerID;
            data.completedStep =
              this.props.headerName.completedStep != 0
                ? this.props.headerName.completedStep - 1
                : "";
            data.mainHeader =
              this.props.headerName.activeStep == 1
                ? "Welcome"
                : this.props.headerName.id === 1
                  ? label.product.vkenSignIn
                  : this.props.headerName.id == 2
                    ? label.product.vken2FA
                    : this.props.headerName.id == 3 ? label.product.vkenPay : "Welcome";
            data.subHeader = "";
            data.name = "Popstate";
            data.enableBack = this.props.headerName.activeStep == 3 ? false : true;
            this.props.updateHeader(data);
            this.props.backIconPress();
          }

        }
      } else {
        let data = {};
        data.activeStep = 0;
        data.id = "";
        data.issuerID = "";
        data.completedStep = "";
        data.mainHeader = "Welcome"
        data.subHeader = "";
        data.name = "Popstate";
        data.enableBack = false;
        this.props.updateHeader(data);
        this.props.backIconPress();
      }
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <ErrorBoundary>
        <div className={classes.container}>
          {this.props.headerName == "" ||
            this.props.headerName.mainHeader == "Welcome" ? (
            <div
              style={{
                height: "max-content",
                width: "100%",
              }}
            >
              <Grid container>
                <div>
                  <img src={Logo} className={classes.logo} />
                </div>
                <div className={classes.brand}>Autharc Portal</div>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: this.props.headerName.enableBack
                  ? "pointer"
                  : "not-allowed",
                fontSize: "medium",
                margin: "0.5rem 0rem",
                opacity: this.props.headerName.enableBack ? 1 : 0.5,
                alignItems: "flex-end"
              }}
              onClick={this.handleBack}
            >
              <ChevronLeftIcon></ChevronLeftIcon>
              <h3 className={classes.formHeader}>
                {" "}
                {this.props.headerName.mainHeader == undefined || this.props.headerName.mainHeader == "" &&
                  this.props.headerName.activeStep == 0 || Number.isNaN(this.props.headerName.activeStep)
                  ? "Welcome"
                  :
                  this.props.headerName.id === 1 ?
                    <span>
                      {/* <span>Vken<sup>{`\u00a9`}</sup>Sign-In</span> */}
                      <span>AutharcId</span>
                    </span>
                    : this.props.headerName.id === 2 ?
                      <span>
                        <span>AutharcToken</span>
                      </span>
                      :
                      <span>
                        <span>AutharcCard</span>
                      </span>

                      || "Welcome"}


                {/* this.props.headerName.mainHeader  */}
              </h3>
            </div>
          )}
          {this.props.activeStep != 0 && (
            <Popover
              open={this.state.open}
              onClose={() => {
                this.setState({ open: false });
              }}
              PopoverText={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    alignItems: "center",
                    width: "max-content",
                    margin: "0.5rem 0rem",
                  }}
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontSize: 16,
                      fontWeight: 400,
                      marginLeft: "0.5rem",
                    }}
                  >
                    {this.props.loginDetails.length != 0 &&
                      this.props.loginDetails[0].UserName != undefined &&
                      this.props.loginDetails[0].UserName != "" &&
                      this.props.loginDetails[0].UserName != null
                      ? this.props.loginDetails[0].UserName || "NA"
                      : "NA"}
                  </h3>
                  <ExpandMoreIcon></ExpandMoreIcon>
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 15,
                  cursor: "pointer",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.props.loggedOutAction(this.props.history);
                  this.setState({ open: false });
                }}
              >
                <ExitToAppIcon></ExitToAppIcon>
                <div>Logout</div>
              </div>
            </Popover>
          )}
        </div>
      </ErrorBoundary>
    );
  }
}
const mapStateToProps = (state) => ({
  loginDetails:
    state.Login.loginDetails.Table1 != null &&
      state.Login.loginDetails.Table1.length != 0
      ? state.Login.loginDetails.Table1
      : [],
  headerName: state.Header.headerName,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loggedOutAction, updateHeader }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
);
