import axios from "axios";
import publicIp from "public-ip";
import validator from 'validator';

export function replaceNull(someObj, replaceValue = "") {
  const replacer = (key, value) => {
    if (typeof value === "number") {
      return value;
    } else if (typeof value === "boolean") {
      return value;
    } else {
      return String(value) === "null" ||
        String(value) === "undefined" ||
        Object.keys(value).length === 0 ||
        String(value) === ""
        ? replaceValue
        : value;
    }
  };
  return JSON.parse(JSON.stringify(someObj, replacer));
}



export function validatePhoneNumber(number){
 const isValidPhoneNumber = validator.isMobilePhone(number)
 return (isValidPhoneNumber)
}

export const getClientIp = async () => {
  return publicIp.v4().then((result) => {
    if(result!=null){
      localStorage.setItem("IPAddress",result)
    }
  return result
  });
 
 
};
export const alphabetSpaceOnly = (value) => {
  if (value != undefined) {
    return /^[A-Za-z]+(\s?$|\s{1,}[A-Za-z]+)+$/.test(value.toString()) ||
      value === ""
      ? true
      : false;
  }
};
export const alphaNumericOnly = (value) => {
  if (value != undefined) {
    return /^[A-Za-z0-9]+$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const numbersOnly = (value) => {
  if (value != undefined) {
    return /^\d+$/.test(value.toString()) || value === "" ? true : false;
  }
};
export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;
  if (!email || email.length <= 0) return "Kindly Provide  Email";
  if (!re.test(email)) {
    return "Kindly Provide Valid Email";
  }
  if (email.length > 200) return "Maximum Length Exceed";
  return "";
};
export const fnBrowserDetect = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
};
export const getIpAddress = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};
export function getValueOrDefault(value) {
  return value === null ? "" : value;
}