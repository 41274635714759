import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import ProductSelection from "../product_selection/product_selection";
import EntryForm from "../entry_form/entryform";
import UserDetails from "../user_details/user_details";
import Card from "@material-ui/core/Card";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  loggedOutAction,
  updateProfileImage,
} from "../../redux/action/login/login";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { bindActionCreators } from "redux";
import Header from "../../component/header/header";
import { updateHeader } from "../../redux/action/header/header";
import Trymiri from "../try_miri/try_miri";
import { Redirect } from "react-router-dom";
import path from "../../utils/constants/path";
import Avatar from "../../utils/assets/avatar.png";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import PhoneInTalkTwoToneIcon from "@material-ui/icons/PhoneInTalkTwoTone";
import InfoSlider from "../product_selection/infoSlider";
import classNames from "classnames";
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import DialogBox from "../../component/dialog/dialog";
import Button from "../../component/button/button";
import label from "../../utils/constants/label";
import color from "../../utils/constants/color";
import Upload from "../../component/upload/upload";
import { getProfileUpload } from "../../redux/action/user_profile/user_profile";
import Alert from "../../component/alert/alert";
import { getBankList } from "../../redux/action/List/bank_list";
import { getProductList } from "../../redux/action/List/product_list";
import moment from "moment";

const styles = (theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "15% 85%",
  },

  logout: {
    [theme.breakpoints.up("xl")]: {
      minHeight: 390,
    },
  },

  cardLogout: {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    color: color.BLACK,
    "& .MuiSvgIcon-root": {
      color: color.SECONDARY,
      paddingRight: "0.5rem",
    },
  },

  stepper: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 5,
    },

    "& .MuiStepLabel-alternativeLabel": {
      fontFamily: "Noto Sans",
      fontSize: 12,
      color: color.BLACK,
      "& .MuiStepIcon-root.MuiStepIcon-completed,.MuiStepIcon-root.MuiStepIcon-active":
      {
        color: color.SECONDARY,
        fontSize: "1.1rem",
      },

      "& .MuiStepIcon-root": {
        color: color.GREY,
        fontSize: "1.1rem",
      },
    },

    "& .MuiStepConnector-alternativeLabel.Mui-disabled": {
      "& + span > span.MuiStepLabel-labelContainer > .MuiStepLabel-alternativeLabel":
      {
        color: color.DARK_GREY,
      },
    },

    "& .MuiStep-alternativeLabel": {
      padding: 0,

      "& .MuiStepConnector-line": {
        borderColor: "rgba(124, 124, 124, 0.21)",
      },
    },

    "& .MuiStepper-alternativeLabel": {
      justifyContent: "center",
    },
  },
  cardStepper: {
    margin: "0rem 2.15rem",
    // boxShadow: "0px 0px 10px",
    boxShadow:'0px 2px 8px 0px',
    color: color.LIGHT_BLUE,
    background: "none",
    borderRadius: "0.75rem",
    [theme.breakpoints.down("md")]: {
      margin: "0rem 1rem",
    },

    [theme.breakpoints.down('sm')]: {
      margin: "0rem 0rem 0rem 1rem",
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: 10
    },
    "& + div": {
      margin: "0rem 2.15rem",
      [theme.breakpoints.down("md")]: {
        margin: "0rem 1rem",
      },

      [theme.breakpoints.down('sm')]: {
        margin: "0rem 0rem 0rem 1rem",
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },

    "& .MuiStepper-root": {
      borderRadius: "0.75rem",
      padding: 8,
    },
  },

  userProfile: {
    height: 140,
    borderRadius: "50%",
    width: 140,
    margin: "1.5rem 0",
    border: `3px solid ${color.BLACK}`,
  },

  userIcon: {
    display: "flex",
    justifyContent: "center",
  },
  profile: {
    padding: "0px 3px",
    color: color.BLACK,
    "& div:first-child": {
      fontSize: 18,
      fontWeight: 600,
      color: color.PRIMARY,
      "& + p": {
        margin: "0.4rem 0",
      },
    },
  },
  secondaryUserImageStyle: {
    height: 140,
    borderRadius: "50%",
    width: 140,
    //boxShadow: "20px 20px 20px 20px rgb(153 204 255 / 0.3)",
    // "&:hover": {
    //   boxShadow: "0 0 20px 0 rgb(0 0 0 / 19%)",
    //   transform: `scale(${1.1})`,
    // },
    backgroundColor: '#fff'
  },

  userName: {
    margin: 0,
    paddingBottom:10
  },

  txtWelcome: {
    margin: 0,
    fontSize: "19px",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    textTransform: "uppercase",
    fontFamily: "Josefin Sans",
    justifyContent: "center",
    color: "#080C12",
    fontWeight: '100px'
  },

  profileInfo: {
    display: "flex",
    flexWrap: "nowrap",
    "& .MuiSvgIcon-root": {
      margin: "0px 10px 0px 0px",
      color: color.SECONDARY,
      "& + div": {
        textOverflow: "ellipsis",
        overflow: "hidden"
      }
    },
  },

  productCard: {
    padding: 20,
    boxShadow: "0px 0px 30px",
    color: color.SHADOW_PURPLE,
    borderRadius: "0.75rem",
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },

  slider: {
    // backgroundImage: `linear-gradient(${color.WHITE} 15%,#738cf2)`,
    // background: 'radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%, hsla(214, 38%, 75%, 1) 100%)',
    
    // background:`radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%,  ${color.SECONDARY} 100%)`,
    padding: 20,
    boxShadow: "0px 0px 30px",
    color: color.SHADOW_PURPLE,
    borderRadius: "0.75rem",
    cursor: "pointer",
    "& div:first-child":{
      background: '#fff',
      borderRadius: '0.375rem',
      padding:2
    },

    "& div.MuiMobileStepper-root": {
      padding: 0
    },


    [theme.breakpoints.down('sm')]: {
      marginTop: "1rem",
    },
  },

  profileCard: {
   // backgroundImage: `linear-gradient(#738cf2,${color.WHITE})`,
    // backgroundColor: '#738cf2',
    //opacity: 0.7,
    //boxShadow: "20px 20px 20px 20px rgb(153 204 255 / 10%)",
   // background: 'radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%, hsla(214, 38%, 75%, 1) 100%)',
    "& div": {

    },
  },

  container: {
    display: "flex",
    justifyContent: "space-around",
    margin: "0rem 1rem",

    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "unset",
    },
  }
});

class StepperContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      activeStep: 0,
      productID: "",
      steps: ["Product Selection", "Account Creation", "Download", "Try Demo"],
      issuerID: "",
      image: "",
      Name: "",
      open: "",
      message: "",
      severity: "",
      userImageError: "",
      loader: false,
      completedStep: "",
    };
  }

  componentDidMount() {
    window.addEventListener("unload", this.handleTab);
    this.props.getBankList(this.props.token);
    this.props.getProductList(this.props.token);
    if (Object.keys(this.props.headerName).length != 0) {
      if (this.props.headerName.activeStep == undefined) {
        this.setState({ activeStep: 0 });
      } else {
        this.setState({
          activeStep: this.props.headerName.activeStep,
          completedStep: this.props.headerName.completedStep,
        });
      }
      if (this.props.headerName.issuerID == undefined) {
        this.setState({ issuerID: "" });
      } else {
        this.setState({ issuerID: this.props.headerName.issuerID });
      }
      if (this.props.headerName.id == undefined) {
        this.setState({ productID: "" });
      } else {
        this.setState({ productID: this.props.headerName.id });
      }
    } else {
      this.setState({ activeStep: 0 });
    }
    if (
      this.props.loginDetails.length != 0 &&
      this.props.loginDetails[0].UserImage != null &&
      this.props.loginDetails[0].UserImage != ""
    ) {
      this.setState({
        image: `${window.globalConfig.LoginImage}${this.props.loginDetails[0].UserImage}`,
      });
    }

  }
  handleTab = () => {
    let data = {}
    data.activeStep = 0
    data.issuerID = ""
    data.id = ""
    data.completedStep = ""
    data.mainHeader = "Welcome"
    data.subHeader = ""
    data.enableBack = true
    this.props.updateHeader(data)
  }
  componentWillUnmount() {
    window.removeEventListener('unload', this.handleTab)
  }
  componentDidUpdate(prev) {
    const { headerName } = this.props
    if (prev.headerName.activeStep != headerName.activeStep) {
      this.setState({ activeStep: headerName.activeStep })
    }
  }
  handleNextStep = (value) => {
    if (value.id != undefined && value.id != "") {
      this.setState({ productID: value.id });
    }
    this.setState({ issuerID: value.issuerID });
    this.setState({
      activeStep: value.activeStep,
      completedStep: value.completedStep,
    });
  };

  handlePreviousStep = () => {
    this.setState((prevState) => {
      return {
        activeStep: prevState.activeStep - 1,
        completedStep: prevState.completedStep - 1,
      };
    });
  };

  goToStep = (value) => {
    this.setState({ activeStep: value.activeStep });

    this.setState({
      steps: ["Product Selection", "Account Creation", "Download", `Try Demo`],
    });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ProductSelection
            productID={this.state.productID}
            issuerID={this.state.issuerID}
            goToStep={this.goToStep}
            handleNextStep={this.handleNextStep}
            handlePreviousStep={this.handlePreviousStep}
          ></ProductSelection>
        );
      case 1:
        return (
          <EntryForm
            goToStep={this.goToStep}
            activeStep={this.state.activeStep}
            productID={this.state.productID}
            issuerID={this.state.issuerID}
            handleNextStep={this.handleNextStep}
            handlePreviousStep={this.handlePreviousStep}
          ></EntryForm>
        );
      case 2:
        return (
          <UserDetails
            productID={this.state.productID}
            issuerID={this.state.issuerID}
            activeStep={this.state.activeStep}
            goToStep={this.goToStep}
            productName={
              this.state.productID == 1
                ? label.product.vkenSignIn
                : this.state.productID == 2
                  ? label.product.vken2FA
                  : label.product.vkenPay
            }
            handleNextStep={this.handleNextStep}
            handlePreviousStep={this.handlePreviousStep}
          ></UserDetails>
        );
      case 3:
        return (
          <Trymiri
            productID={this.state.productID}
            goToStep={this.goToStep}
            activeStep={this.state.activeStep}
            handleNextStep={this.handleNextStep}
            productName={
              this.state.productID == 1
                ? label.product.vkenSignIn
                : this.state.productID == 2
                  ? label.product.vken2FA
                  : label.product.vkenPay
            }
            handlePreviousStep={this.handlePreviousStep}
          ></Trymiri>
        );
      default:
        return "Unknown step";
    }
  };
  onClickExpire = () => {
    if (this.props.sessionExpire) {
      setTimeout(() => {
        this.props.loggedOutAction(this.props.history);
      }, 1000);
    }
  };

  profileImageUpload = (event) => {
    if (event.target) {
      if (event.target.files[0]) {
        //
        const file = event.target.files[0];
        var extension = event.target.files[0].name.split(".").pop();
        if (
          extension == "png" ||
          extension == "jpg" ||
          extension == "jpeg" ||
          extension == "PNG" ||
          extension == "JPG" ||
          extension == "JPEG"
        ) {
          this.setState({ Name: event.target.files[0], userImageError: "" });
          let data = new FormData();
          data.append("file", event.target.files[0]);
          data.append("userID", this.props.loginDetails[0].UserID);
          this.setState({ loader: true, image: "" });
          this.props.getProfileUpload(
            data,
            this.props.token,
            this.onSuccess,
            this.onFailure
          );
        } else {
          this.setState({
            open: true,
            message: label.messages.imageError,
            severity: "error",
          });
        }

        // here you can do whatever you want with your image. Now you are sure that it is an image
      }
    } else {
      const myRenamedFile = new File([event], "captureImage" + moment().format("YYYYMMDDHHmmss").toString() + ".png");
      if (myRenamedFile) {
        const file = myRenamedFile;
        var extension = "png"
        if (
          extension == "png" ||
          extension == "jpg" ||
          extension == "jpeg" ||
          extension == "PNG" ||
          extension == "JPG" ||
          extension == "JPEG"
        ) {
          this.setState({ Name: myRenamedFile, userImageError: "" });
          let data = new FormData();
          data.append("file", myRenamedFile);
          data.append("userID", this.props.loginDetails[0].UserID);
          this.setState({ loader: true, image: "" });
          this.props.getProfileUpload(
            data,
            this.props.token,
            this.onSuccess,
            this.onFailure
          );
        } else {
          this.setState({
            open: true,
            message: label.messages.imageError,
            severity: "error",
          });
        }
      }

    }

  };
  onSuccess = () => {
    this.setState({
      image: URL.createObjectURL(this.state.Name),
      loader: false,
    });
    this.props.updateProfileImage(this.state.Name.name);
  };
  onFailure = (data) => {
    this.setState({
      open: true,
      message: "Profile Upload Failed",
      severity: "error",
      loader: false,
    });
  };
  render() {
    const { classes } = this.props;
    try {
      return (
        <div>
          <Header
            productID={this.state.productID}
            issuerID={this.state.issuerID}
            completedStep={this.state.completedStep}
            backIconPress={this.handlePreviousStep}
            activeStep={this.state.activeStep}
          ></Header>
          {/* <DialogBox width={320} open={this.props.sessionExpire}>
            Your Session Expired .Redirecting to Sign-In...
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                text={label.buttonLabel.signIn}
                radius={10}
                color={color.WHITE}
                type="Primary"
                onClick={this.onClickExpire()}
                backgroundColor={color.SECONDARY}
              ></Button>
            </div>
          </DialogBox> */}
          <div
            container
            spacing={3} className={classes.container}
          >
            {this.state.activeStep === 0 && (
              <Grid item xs={12} sm={4} md={3}>
                <Card
                  elevation={0}
                  className={classNames(classes.productCard, classes.profileCard)}
                >
                  <Grid container>
                    <div className={classes.txtWelcome}>
                      {this.props.headerName.mainHeader || "Welcome"}
                    </div>

                    <Grid item xs={12} className={classes.userIcon}>
                      <Upload
                        type="Primary"
                        editIcon={true}
                        id={"userProfile"}
                        URL={this.state.image}
                        placeholder={
                          <img
                            src={Avatar}
                            className={classes.secondaryUserImageStyle}
                          ></img>
                        }
                        isLoading={this.state.loader}
                        className={classes.userProfile}
                        error={this.state.userImageError}
                        onChange={this.profileImageUpload}
                      ></Upload>
                    </Grid>

                    <Grid item xs={12} className={classes.profile}>
                      <div>
                        {this.props.loginDetails.length != 0 &&
                          this.props.loginDetails[0].UserName != null &&
                          this.props.loginDetails[0].UserName != undefined &&
                          this.props.loginDetails[0].UserName != "" && (
                            <p className={classes.userName}>
                              {this.props.loginDetails[0].UserName}
                            </p>
                          )}
                      </div>
                      {this.props.loginDetails.length != 0 &&
                        this.props.loginDetails[0].Designation != null &&
                        this.props.loginDetails[0].Designation != undefined &&
                        this.props.loginDetails[0].Designation != "" && (
                          <p>{this.props.loginDetails[0].Designation}</p>
                        )}
                      {this.props.loginDetails.length != 0 &&
                        this.props.loginDetails[0].Email != null &&
                        this.props.loginDetails[0].Email != undefined &&
                        this.props.loginDetails[0].Email != "" && (
                          <div className={classes.profileInfo}>
                            <MailOutlineTwoToneIcon />{" "}
                            <div>{this.props.loginDetails[0].Email}</div>
                          </div>
                        )}
                      {this.props.loginDetails.length != 0 &&
                        this.props.loginDetails[0].Mobile != null &&
                        this.props.loginDetails[0].Mobile != undefined &&
                        this.props.loginDetails[0].Mobile != "" && (
                          <div className={classes.profileInfo}>
                            <PhoneInTalkTwoToneIcon />{" "}
                            <div>{this.props.loginDetails[0].Mobile}</div>
                          </div>
                        )}
                    </Grid>
                  </Grid>
                </Card>
                <Card
                  elevation={0}
                  style={{ padding: "15px 20px", marginTop: "0.5rem" }}
                  className={classes.productCard}
                  onClick={() => {
                    this.props.loggedOutAction(this.props.history);
                  }}
                >
                  <div className={classes.cardLogout}>
                    <ExitToAppTwoToneIcon /> Logout
                  </div>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} sm={this.state.activeStep !== 0 ? 12 : 8} md={this.state.activeStep !== 0 ? 11 : 6}>
              <Card className={classes.cardStepper} elevation={0}>
                <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  className={classes.stepper}
                >
                  {this.state.steps.map((label, index) => (
                    <Step
                      key={label}
                      completed={
                        this.props.headerName.activeStep == 0 ? false : Number.isInteger(this.props.headerName.completedStep) && Math.sign(this.props.headerName.completedStep) != -1 ? this.props.headerName.completedStep >= index ? true : false : false
                      }
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Card>

              <div>{this.getStepContent(this.state.activeStep)}</div>
            </Grid>

            {this.state.activeStep === 0 && (
              <Grid item xs={12} sm={12} md={3} style={{}}>
                <Card
                  elevation={0}
                  className={classNames(classes.slider, classes.logout)}
                >
                  <InfoSlider></InfoSlider>
                </Card>
              </Grid>
            )}
          </div>
          <Alert
            open={this.state.open}
            message={this.state.message}
            onClose={this.onClose}
            severity={this.state.severity}
          ></Alert>
        </div>
      );
    } catch (err) {
      return <Redirect to={path.notFound} />;
    }
  }
}
const mapStateToProps = (state) => ({
  headerName: state.Header.headerName,
  loader: state.EntryFormList.isLoading,
  loginDetails:
    state.Login.loginDetails.Table1 != null &&
      state.Login.loginDetails.Table1.length != 0
      ? state.Login.loginDetails.Table1
      : [],
  token: state.Login.token,
  sessionExpire: state.Login.sessionExpire,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateHeader,
      loggedOutAction,
      getProfileUpload,
      updateProfileImage,
      getBankList,
      getProductList,
    },
    dispatch
  );
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(StepperContainer))
);
