import { replaceNull } from "../../../utils/common_function/common_function";
import * as actionType from "../../action_type/action_type";
const initialState = {
  bankList: [],
  isLoading: false,
};
const bank_list_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.BANK_LIST:
     
        return {
          ...state,
          bankList:replaceNull(action.payload,""),
        };
    case actionType.LOADER_BANK_LIST:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.EMPTY_BANK_LIST:
      return {
        ...state,
         bankList: [],
         isLoading: action.payload,
      };
    default:
      return state;
  }
};
export default bank_list_reducer;
