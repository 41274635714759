import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import color from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: color.PRIMARY,
  },
 
  closeButton: {
    position: "absolute",
    right: 4,
    top: 4,
    color: theme.palette.grey[500],
  },
  width: {
    "& .MuiDialog-paperWidthSm":{
      width:(p)=>p.width!=undefined?p.width:"auto",
      padding:20,
      "&::-webkit-scrollbar":{
        width:7,
        height:7
      },      
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: color.SCROLLBARTHUMB,
        borderRadius:10
      }
    }
  },
});


class DialogBox extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onClose=()=>{
   if(this.props.onClose!=undefined)
    {
      this.props.onClose()
    }
  }
  render() {
     const { classes } = this.props;
    return (
      <ErrorBoundary>
      <div>
        <Dialog
           onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          hideBackdrop={this.props.hideBackdrop}
          className={classes.width}
         
          open={this.props.open}
        >
          
       {this.props.children}
        </Dialog>
      </div>
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(DialogBox);
