import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect, useState } from "react";
import label from "../../utils/constants/label";
import color from "../../utils/constants/color"

function QrGenerator(props) {
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState(400);
  const [bgColor, setBgColor] = useState("ffffff");
  const [qrCode, setQrCode] = useState("");

  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode(
      `http://api.qrserver.com/v1/create-qr-code/?data=${props.word}&size=${size}x${size}&bgcolor=${bgColor}`
    );
  }, [props.word, size, bgColor]);

 

  return (
    <div className="App">
     
      {props.word!=""&&loading &&  <Skeleton className={props.skeletonClassName}></Skeleton>}
      {props.word==""?<div   className={props.className} style={{display:"flex",alignItems:"center",border:`1px solid ${color.GREY}`}}> {label.messages.noDataAvailable}</div>:
      <img	
        src={qrCode}
        alt=""
        className={props.className}
        
        onLoad={() => setLoading(false)}
      />}
     
    </div>
  );
}

export default QrGenerator;
