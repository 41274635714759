import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from "@material-ui/core/styles";
import Button from "../../component/button/button";
import color from "../../utils/constants/color";
import CloseIcon from '@material-ui/icons/Close';
class App extends React.Component {
    constructor() {
        super();
        this.cameraNumber = 0;
        this.state = {
            imageDataURL: null,
            isModelOpen: true,
        };
    }

    componentDidMount() {
        this.initializeMedia()
    }

    initializeMedia = async () => {
        this.setState({ imageDataURL: null });

        if (!("mediaDevices" in navigator)) {
            navigator.mediaDevices = {};
        }

        if (!("getUserMedia" in navigator.mediaDevices)) {
            navigator.mediaDevices.getUserMedia = function (constraints) {
                var getUserMedia =
                    navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia Not Implemented"));
                }

                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        }

        //Get the details of video inputs of the device
        const videoInputs = await this.getListOfVideoInputs();

        //The device has a camera
        if (videoInputs.length) {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        deviceId: {
                            exact: videoInputs[this.cameraNumber].deviceId,
                        },
                    },
                })
                .then((stream) => {
                    this.player.srcObject = stream;
                })
                .catch((error) => {
                    console.error("camera blocked", error);
                    this.props.onError("Camera permission unavailable. Kindly provide permission to continue.")
                });
        } else {
            this.props.onError("The device does not have a camera")
        }
    };

    capturePicture = () => {
        var canvas = document.createElement("canvas");
        canvas.width = this.player.videoWidth;
        canvas.height = this.player.videoHeight;
        var contex = canvas.getContext("2d");
        contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
        this.player.srcObject.getVideoTracks().forEach((track) => {
            track.stop();
        });


        this.setState({ imageDataURL: canvas.toDataURL() });
    };

    getListOfVideoInputs = async () => {
        // Get the details of audio and video output of the device
        const enumerateDevices = await navigator.mediaDevices.enumerateDevices();

        //Filter video outputs (for devices with multiple cameras)
        return enumerateDevices.filter((device) => device.kind === "videoinput");
    };

    modelClose = () => {
        // this.setState({ isModelOpen: false })

        if (!this.state.imageDataURL) {
            this.player.srcObject.getVideoTracks().forEach((track) => {
                track.stop();
            });
        }
        this.props.onError("")
    }
    dataURLtoFile = (dataURL, filename) => {
        var arr = dataURL.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    onSubmit = () => {
        var file = this.dataURLtoFile(this.state.imageDataURL, 'image/png');


        this.props.onChange(file)
    }

    render() {
        return (
            <div>
                <Dialog maxWidth={"md"}
                    open={this.props.isModalOpen}
                    onClose={() => this.modelClose()}
                >
                    <DialogTitle>
                        <div style={{ flex: 1, display: "flex", flexDirection: 'row' }}>
                            <div style={{ display: "flex", flex: 0.5, flexDirection: 'column' }}>
                                Profile Picture
                            </div>
                            <div onClick={() => this.modelClose()} style={{ display: "flex", flex: 0.5, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', }}>
                                <CloseIcon style={{ cursor: 'pointer', alignSelf: 'flex-end' }}></CloseIcon>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 400, height: 300 }}>
                                    {this.state.imageDataURL ?
                                        <img style={{ width: 400, height: 300 }} src={this.state.imageDataURL} alt="cameraPic" />
                                        :
                                        <video
                                            width={400}
                                            height={300}
                                            ref={(refrence) => {
                                                this.player = refrence;
                                            }}
                                            autoPlay
                                        >
                                        </video>
                                    }
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 20 }}>

                                    {this.state.imageDataURL ?
                                        <div style={{ display: 'flex', flexDirection: "row" }}>
                                            <div style={{ paddingRight: 30 }}>
                                                <Button
                                                    text={"Retake"}
                                                    style={{ marginRight: 30 }}
                                                    radius={10}
                                                    color={color.WHITE}
                                                    type="Primary"
                                                    onClick={() => this.initializeMedia()}
                                                    backgroundColor={color.SECONDARY}
                                                ></Button>
                                            </div>                                            <Button
                                                text={"Submit"}
                                                radius={10}
                                                color={color.SECONDARY}
                                                type="Primary"
                                                onClick={() => this.onSubmit()}
                                            ></Button>
                                        </div>
                                        :
                                        <Button
                                            text={"Capture"}
                                            radius={10}
                                            color={color.WHITE}
                                            type="Primary"
                                            onClick={this.capturePicture}
                                            backgroundColor={color.SECONDARY}
                                        ></Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >

        );
    }
}

const styles = (theme) => ({
    button: {
        color: color.WHITE,
        background: "#00AFEF",
        boxShadow: "none",
        '&:hover': {
            background: "#00AFEF",
        }
    },

})

export default withStyles(styles)(App);

