import React from "react";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../error_boundary/error_boundary";
import color from "../../utils/constants/color";
const styles = (theme) => ({
  alert: {
    backgroundColor: color.WHITE,
    border:(p)=>p.severity=="warning"?`1px solid ${color.WARNING}`:p.severity=="success"? '1px solid ${color.LIGHT_SUCCESS}':"1px solid ${color.LIGHT_WARNING}",
    color:(p)=>p.severity=="warning"? color.WARNING : p.severity=="success"? color.SUCCESS :color.ERROR,
    fontFamily: 'Noto Sans',
    [theme.breakpoints.down(600)]: {
      marginBottom: "26%",
    },
  },  
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class CommonSnackbar extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.props.onClose != undefined) {
        this.props.onClose();
      }
    }, 4000);
  }
  render() {
    
    const { classes } = this.props;
    return (
      <ErrorBoundary>
      <Snackbar
        open={this.props.open}
        autoHideDuration={3000}
        onClose={this.props.onClose}
      >
        <Alert
          onClose={this.props.onClose}
          classes={{
            root:classes.alert
          }}
          severity={this.props.severity}
        >
          {this.props.message}
        </Alert>
      </Snackbar>
      </ErrorBoundary>
    );
  }
}

export default withStyles(styles)(withRouter(CommonSnackbar));
