import * as actionType from "../../action_type/action_type";
import Networking from "../../../utils/network/apiaccess";

import url from "../../../utils/ApiValues/url";
import label from "../../../utils/constants/label";
export function getProfileUpload(data, token, onSuccess, onFailure) {
  return (dispatch) => {
    getProfileUploadAction(dispatch, data, token, onSuccess, onFailure);
  };
}
function getProfileUploadAction(dispatch, data, token, onSuccess, onFailure) {
  Networking.PostApiWithToken(
    url.UploadUserProfile,
    data,
    token,
    onSuccess,
    onFailure
  )
    .then((result) => {
      if (result.status == "S") {
        onSuccess(result);
      } else {
        onFailure(result);
        
        if (result.status == 401 || result.status == 405) {
          dispatch({
            type: actionType.SESSION_EXPIRE,
            payload: true,
          });
        }
      }
    })
    .catch(function (error) {
      onFailure(label.apiError.status);
    });
}
