import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import QrReader from "react-qr-reader";
import color from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: color.PRIMARY,
  },
  clearIcon: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    zIndex: 2,
    position:"absolute",
    right: 5,
    margin: 8,
    borderRadius: 4,
    fontSize: 10,
    width: 20,
    height: 20,
    color: color.WHITE,
    alignItems: "center",
    background: color.BLACK,

    '& + div > section > section div':{
      position:"absolute !important",
      display:"block !important",
      boxShadow:"none !important",
      border:"none !important",
      top:"20px !important",
      width:"70% !important",
      height: "70% !important",
      margin: "1.75rem 2.25rem",
      background: "linear-gradient(to right, #F64E60 4px, transparent 4px) 0 0, linear-gradient(to right, #F64E60 4px, transparent 4px) 0 100%, linear-gradient(to left, #F64E60 4px, transparent 4px) 100% 0, linear-gradient(to left, #F64E60 4px, transparent 4px) 100% 100%, linear-gradient(to bottom,#F64E60 4px, transparent 4px) 0 0, linear-gradient(to bottom, #F64E60 4px, transparent 4px) 100% 0, linear-gradient(to top, #F64E60 4px, transparent 4px) 0 100%, linear-gradient(to top, #F64E60 4px, transparent 4px) 100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "20px 20px",
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
 
});


class QrScanner extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const previewStyle = {
      height: 250,
      width: 230,
      display: "flex",
      justifyContent: "center",
    };
    const camStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
    };
    const { classes } = this.props;
    return (
      <ErrorBoundary>
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          hideBackdrop={true}
          className={this.props.className}
          open={this.props.open}
          elevation={0}
        >
          <div className={classes.clearIcon} onClick={this.props.handleClose}>
            &#x2715;
          </div>
        
          <div style={camStyle}>
            <QrReader
              delay={300}
              facingMode={"user"}
              style={previewStyle}
              onError={this.props.handleError}
              onScan={this.props.handleScan}
            />
          </div>
        </Dialog>
      </div>
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(QrScanner);
