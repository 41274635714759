import { replaceNull } from "../../../utils/common_function/common_function";
import * as actionType from "../../action_type/action_type";
const initialState = {
  headerName: "",
};
const header_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_HEADER:
      return {
        ...state,
        headerName: action.payload,
      };
    case actionType.EMPTY_HEADER:
      return {
        ...state,
        headerName: "",
      };

    default:
      return state;
  }
};
export default header_reducer;
