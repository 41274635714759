import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import logo from "../../utils/assets/Dglogo.png";
import Logo from "../../../src/utils/assets/favicon.png";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: <div style={{ display: "flex" }}> <span>AutharcId</span></div>,
    description:
      <div>The <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>AutharcId</span> applet can also be used on a cell phone to create a <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}><span>AutharcId</span> single-use</span> login number that can then be typed into a computer to create the most secure means possible of accessing online systems. By using two separate devices, the user is completely protected from <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>PC hackers</span> and <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>keylogging malware.</span> This gives your customers secure online access from any public computer without fear of static login credentials being stolen. It's important to note that identity theft can still occur from keyloggers installed on those computers if the user is typing sensitive information into the system once they have been granted access.</div>,
  },
  {
    label: <div style={{ display: "flex" }}><span>AutharcToken</span></div>,
    description:
      <div>
        The <span>AutharcToken</span> soft token technology gives you a greater level of <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>authentication security</span> than traditional tokens. <span>AutharcToken</span> is a <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}> soft token, </span> software based technology that authorizes secure access to computer systems through the use of personal computers,smart phones, tablets and other PDAs (personal digital assistants). It offers an efficient solution for the distribution, activation and reuse of tokens that is critical to strong and reliable authentication. <span>AutharcToken</span> allows an institution to establish an enterprise-wide authentication policy that <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>protects</span> its most valuable <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>applications, resources</span> and <span style={{ fontFamily: 'Josefin Sans', fontWeight: 600 }}>information.</span></div>,
  },
  // {
  //   label: <div style={{ display: "flex" }}><span>AutharcCard</span></div>,
  //   description:
  //     <div>
  //       <div style={{ margin: "10px 0px" }}>
  //         Simple, safe and easy to use. Five times faster than traditional methods to submit a payment. Can be used anytime, anywhere (no Internet connection required). Unprecedented fraud prevention that minimizes monetary losses.
  //       </div>

  //       <div style={{ margin: "10px 0px" }}>
  //         Works with existing credit/debit card accounts.As merchants deploy "contactless" terminals, they can immediately accept <span>AutharcCard</span> transactions.
  //       </div>

  //       <div style={{ margin: "10px 0px" }}>
  //         The actual account number is stored and accessible only by the issuing bank.The <span>AutharcCard</span> encoder is unique to every user and every device.
  //       </div>
  //     </div>
  //   ,
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Josefin Sans",
    fontWeight: 300,
    lineHeight: 1.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // width:"60%",
    //background:'#296099',
    //'#2a619a', 
    //'#112A46',
    height: '100%',
    //borderRadius: 10,
    padding: "0 2rem",
    [theme.breakpoints.only('md')]: {
      padding: "10px 2rem",
    },

    "& div.react-swipeable-view-container > div": {
      fontFamily: "Josefin Sans",
      margin: 0,
      color: "rgba(255,255,255,1)",
      marginTop: "1rem",
      fontWeight: 300,
      lineHeight: 1.5,
      fontSize: 16,
      textAlign: "justify"
    },
    "& > div:first-child": {
      background: "none",
      paddingLeft: 0,
      // content: '©',
      // color:"red"
    },
    "& p": {
      fontFamily: "Josefin SansLight",
      fontWeight: 600,
      color: "rgba(255,255,255,1)",
      margin: 0,
      fontSize: "2rem",
      lineHeight: 1.2,
    },


    "& div.MuiMobileStepper-root": {
      background: "none",
      "& div.MuiMobileStepper-dots .MuiMobileStepper-dot": {
        background: "rgba(255,255,255,0.5)"
      },

      "& div.MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive": {
        background: "#fff",
        width: 15,
        borderRadius: 15,
        transition: 'all 0.3s ease 0s'
      },

      "& button.MuiButton-root.Mui-disabled": {
        color: "rgba(255,255,255,0.26)",
        fontFamily: 'Noto Sans'
      },

      "& button.MuiButton-root": {
        color: "#fff",
        fontFamily: 'Noto Sans'
      },

    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    // paddingLeft: theme.spacing(2),
    backgroundColor: "transparent",
    [theme.breakpoints.only('md')]: {
      height: 'unset'
    },
  },

  logoDG: {
    height: "4rem",
    //margin: "1.25rem 2rem",
    position: "relative",

    borderRadius: '50%',
    border: '1px solid #f8f8f8',
    padding: 10,
    background: 'white',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      margin: "1.25rem 1rem",
    },

  },

  imgContainer: {
    display: "flex",
    // backgroundColor: `${color.SCROLLBARTHUMB}`,
    "& + div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      flex: "1 0 auto",
      alignItems: "baseline"
    }
  },
}));

function Slider() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer}>
        <img
          src={Logo} className={classes.logoDG}
        ></img>
      </div>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div className={classes.description}>
                {step.description}
              </div>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      /> */}
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  );
}

export default Slider;