import * as actionType from "../../action_type/action_type";
import Networking from "../../../utils/network/apiaccess";
import url from "../../../utils/ApiValues/url";
export function getProductList(data) {
  return (dispatch) => {
    getProductListAction(dispatch, data);
  };
}
function getProductListAction(dispatch, data) {
    dispatch({
        type: actionType.LOADER_PRODUCT_LIST,
        payload: true,
      })
      dispatch({
        type: actionType.EMPTY_PRODUCT_LIST,
        payload: [],
      })
      Networking.GetApiWithToken(url.Products,"",data)
      .then(result=>{ 
        if(result.status=="S"){
          dispatch({
            type: actionType.LOADER_PRODUCT_LIST,
            payload: false,
          })
          dispatch({
            type: actionType.PRODUCT_LIST,
            payload: result,
          })
        }else{
          
          dispatch({
            type: actionType.LOADER_PRODUCT_LIST,
            payload: false,
          })
          if(result.status==401||result.status==405){
            dispatch({
              type: actionType.SESSION_EXPIRE,
              payload:true,
            });
          }
        }
        })
      .catch(function (error) {
       
          dispatch({
            type: actionType.LOADER_PRODUCT_LIST,
            payload: false,
          })
               // 
       });
}
