import React from "react";
import { withStyles } from "@material-ui/core";
import loginBackground from "../../utils/assets/circle-scatter.svg";
import Background from "../../utils/assets/background.jpg";
import LoginBackgroundImage from "../../utils/assets/loginBackground.jpg";
import logo from "../../utils/assets/Dglogo.png";
import webcam from "../../utils/assets/webcam.png";
import next from "../../utils/assets/login-arrow.png";
import QrScanner from "../../component/qrscanner/qrscanner";
import path from "../../utils/constants/path";
import { withRouter } from "react-router";
import Card from "@material-ui/core/Card";
import { getLogin } from "../../redux/action/login/login";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import color from "../../utils/constants/color";
import label from "../../utils/constants/label";
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Hidden from '@material-ui/core/Hidden';
import Logo from "../../../src/utils/assets/favicon.png";
import {
  alphaNumericOnly,
  fnBrowserDetect,
  getClientIp,
} from "../../utils/common_function/common_function";
import Loader from "../../component/loader/loader";
import Alert from "../../component/alert/alert";
import ErrorBoundary from "../../component/error_boundary/error_boundary";
import Slider from "./slider";

const styles = (theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      // border: `45px solid ${color.WHITE}`,
      boxSizing: "border-box",
      //padding: '13vh',
      // borderRadius: 10,
      minHeight: "100vh",
      padding: "3rem",
      // background: `url(${loginBackground})`,
      backgroundSize: 'auto',
      // borderRadius: 9,

      backgroundPosition: "left",
      //backgroundColor: `${color.SCROLLBARTHUMB}`,
      backgroundOrigin: "border-box",
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        // flexWrap: "wrap"
        padding: "1rem",
      },
      // [theme.breakpoints.down('xs')]: {
      //   border: `20px solid ${color.LIGHT_PRIMARY}`,
      // }
    },

    mainContainer: {
      background: color.SECONDARY,
      display: "flex",
      borderRadius: 10
    },

    loginHeader: {
      fontSize: 21,
      fontFamily: 'Josefin Sans',
      marginBottom: 10,
      color: color.BLACK,
      [theme.breakpoints.down('1165')]: {
        fontSize: 24,
      },

      [theme.breakpoints.up('xl')]: {
        fontSize: 30,
      },

      "& + div": {
        // color: color.SECONDARY,
        color: "#ADADAD",
        fontSize: 14,
        "& +div": {
          marginTop: "1.5rem",
          [theme.breakpoints.only('sm')]: {
            minHeight: 160
          },
        }
      }
    },

    scanner: {
      width: 25,
      height: 25,
      paddingBottom: 10,
      cursor: "pointer",
      [theme.breakpoints.down('sm')]: {
        width: 22,
        height: 22,
      },
    },

    root: {
      "& input:-webkit-autofill": {
        boxShadow: "0 0 0 30px #FFF0FFB2 inset",
      },

      "& .MuiInputBase-input": {
        outline: "none",
        color: color.BLACK,
        fontFamily: "Noto Sans",
      },

      "& .MuiInput-root": {
        outline: "none",
      },

      "& label.Mui-focused": {
        color: color.BLACK,
      },

      "& .MuiInput-underline:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },

      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },
    firstContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      flex: 1,
      padding: "1rem 0"
    },

    inputFormControl: {
      width: "100%",
      "& label.MuiFormLabel-root": {
        color: color.LIGHT_BLACK,
        fontFamily: "Noto Sans",
        fontWeight: 600,
        fontSize: 12,
        [theme.breakpoints.down('1165')]: {
          fontSize: 10,
        },

        "&.Mui-focused": {
          color: color.LIGHT_BLACK,
        }
      },



      "& div.MuiInput-underline:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      }
    },

    secondContainer: {
      width: "100%",
      display: "flex",
      height: "100%",
      justifyContent: 'center',
      // opacity: 0.7,


      [theme.breakpoints.down('sm')]: {
        justifyContent: "center",
      },

      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        // margin: "0 auto",
        width: "70%",
        justifyContent: "center",
        // "@media (max-width: 990px)": {
        //   width: "85%",
        // },

        // "@media (max-width: 990px)": {
        //   width: "85%",
        // },

        [theme.breakpoints.down('sm')]: {
          width: "100%",
        },
      },


    },



    logo: {
      height: "5rem",
      marginLeft: "-1.5rem",
      position: "relative",
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },

    logoDG: {
      height: "6rem",
      //margin: "1.25rem 2rem",
      position: "relative",
      [theme.breakpoints.down('xs')]: {
        margin: "1.25rem 1rem",
      },

    },
    header: {
      marginBottom: 6,
      fontFamily: "Josefin Sans",
      fontWeight: "bold",
      marginLeft: "25%",
      marginTop: "10%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    slider: {
      // height: '100vh',
      // background: color.SECONDARY,
      background: `url(${Background})`,
      borderRadius: "10px 0px 0px 10px",
      justifyContent: "center",
      //background:"#f1f2f6",
      // opacity: 0.8,
      backgroundSize: 'cover',
      //padding: "3rem",
      display: "flex", flexDirection: "column", width: "100%",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        display: "none"
      },
    },
    buttonDesign: {
      width: "100%",
      borderRadius: 20,
      height: 40,
      display: "flex",
      alignItems: "center",
      backgroundImage:
        "linear-gradient(to right, #ffecd2 0%, #fcb69f 51%, #ffecd2 100%)",
    },
    mainHeader: {
      margin: 0,
      color: "rgba(255,255,255,0.9)",
      fontSize: "2rem",
      textTransform: "uppercase",
      fontFamily: "Josefin SansLight",
      marginLeft: "2rem",
      lineHeight: 1.2,
    },
    header1: {
      fontWeight: 100,
    },
    header2: {
      fontWeight: 600,
    },
    subHeader: {
      fontFamily: "Josefin SansLight",
      margin: 0,
      color: "rgba(255,255,255,0.9)",
      marginTop: "2rem",
      marginBottom: "1rem",
      fontWeight: 300,
      width: "55%",
      marginLeft: "2rem",
      lineHeight: 1.5,
      fontSize: 18,
    },
    footer: {
      fontSize: 14,
      position: "relative",
      bottom: "2rem",
      right: "1rem",
      float: "right",
      color: "#ADADAD",
      "@media (max-width: 990px)": {
        fontSize: 13,
      },
      [theme.breakpoints.down('xs')]: {
        display: "flex",
        justifyContent: "flex-end",
        right: 0,
        bottom: 0
      },
    },

    cardContent: {
      // backgroundColor: '#fff',
      width: "75%",
      background: color.WHITE,
      borderRadius: '0px 10px 10px 0',
      // clipPath: 'polygon(0 0, 100% 0, 100% 100%, 35% 100%)',
      paddingRight: '2rem',

      // width: '30%',
      // position: 'absolute',
      // height: '100%',
      // right: 0,

      [theme.breakpoints.down('sm')]: {
        width: "100%", clipPath: 'unset', padding: '2rem',
        borderRadius: 10,
        padding: "1rem",
      },

      [theme.breakpoints.down('xs')]: {
        display: "flex",
        flexDirection: "column",
        clipPath: 'unset',
        padding: '1rem',
        borderRadius: 10
      },
    },

    roundButton: {
      backgroundColor: color.SECONDARY,
      // "&:hover": {
      //   // boxShadow: "0 0 20px 0 rgb(0 0 0 / 19%)",
      //   transform: `scale(${1.1})`,
      // },
      // float: "right",
      width: 45,
      height: 45,
      // padding: 0,
      cursor: "pointer",
      boxSizing: "content-box",
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    width: {
      "& .MuiDialog-paper": {
      },
      "& .MuiDialog-scrollPaper": {
        justifyContent: "flex-end",
        marginRight: "10%",
        "& div.MuiDialog-paperScrollPaper": {

          boxShadow: "0px 0px 30px",
          color: color.SHADOW_PURPLE,
        }
      },
    },
    backgroundSlider: {
      background: `url(${loginBackground}) no-repeat`,
      width: '50%',
    }
  };
};
class Login extends React.Component {
  constructor() {
    super();
    this.myDiv = React.createRef();
    this.state = {
      open: false,
      code: "",
      codeError: "",
      openAlert: false,
      message: "",
      loader: false
    };
  }

  componentDidUpdate(prev) {
    const { openAlert, message } = this.props;
    if (prev.openAlert != openAlert) {
      this.setState({ openAlert: openAlert });
    }
    if (prev.message != message) {
      this.setState({ message: message });
    }
  }
  handleScan = (data) => {

    if (data != null) {
      this.setState({ codeError: "" })
      if (data != "" && data.replaceAll(/\s/g, "").length == 16) {
        this.setState({ code: data })
        this.handleClick()
        this.setState({ open: false })
      }
    }
  };

  handleError = () => { };
  componentDidMount() {
    getClientIp()
    this.myDiv.current.addEventListener('keydown', (e) => {

      if (e.key == "Enter") {
        this.handleClick()
      }
    })
    this.myDiv.current.focus()
    if (this.props.login == undefined || this.props.login.length == 0) {
    } else {
      this.props.history.push(path.ProductSelection);
    }

    if (this.props.location.pathname == path.Login) {
      if (this.props.token != "") {
        this.props.history.push(path.dashboard)
      }
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleError = () => { };
  handleOpen = () => {
    this.setState({ open: true });
  };

  onChange = (e) => {
    if (alphaNumericOnly(e.target.value.replaceAll(/\s/g, ""))) {
      let trimValue = e.target.value.replace(/ /g, "").toUpperCase();
      let setSpace = trimValue.match(/.{1,4}/g);
      if (setSpace == null) {
        this.setState({ code: "", codeError: "" });
      } else {
        this.setState({ code: setSpace.join(" "), codeError: "" });
      }
    }
  };

  handleClick = async () => {
    // this.props.history.push(path.dashboard)
    this.setState({ loader: true });

    if (this.state.code == "") {
      this.setState({ codeError: `${label.messages.kindlyProvide} AutharcId` });
      this.setState({ loader: false })
    } else {
      if (this.state.code.replaceAll(/\s/g, "").length != 16) {
        this.setState({ loader: false })
        this.setState({ codeError: `${label.messages.kindlyProvide} Valid AutharcId` });
      } else {
        if (this.state.code.replaceAll(/\s/g, "").length != 16) {
          this.setState({ loader: false })
          this.setState({ codeError: `${label.messages.kindlyProvide} Valid AutharcId` });
        } else {
          var data = new FormData();
          data.append("miriID", this.state.code.replaceAll(/\s/g, ""))
          data.append("ipAddress", localStorage.getItem("IPAddress"))
          data.append("browserName", fnBrowserDetect())
          data.append("version", "")
          this.props.getLogin(data, this.onSuccess, this.onFailure);
        }
      }
    }
  };


  onSuccess = (data) => {
    this.setState({ loader: false });
    this.setState({ openAlert: true, message: data })
    this.props.history.push(path.dashboard)
  }

  onFailure = (data) => {
    this.setState({ loader: false });
    this.setState({ openAlert: true, message: data })
  }

  onClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { classes } = this.props;
    try {
      return (
        <div ref={this.myDiv} tabIndex="0" style={{ outline: "none" }}>
          <ErrorBoundary>
            <div className={classes.container}>
              <Alert
                open={this.state.openAlert}
                message={this.state.message}
                onClose={this.onClose}
                severity={"warning"}
              ></Alert>

              <div className={classes.mainContainer} >
                <div className={classes.slider}>
                  <div className={classes.background}>
                    <Slider></Slider>
                  </div>
                </div>



                <div className={classes.cardContent}>
                  {/* <Hidden smUp>
                  <div>
                    <img
                      src={logo} className={classes.logo}
                    ></img>
                  </div>
                </Hidden> */}
                  <div style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${LoginBackgroundImage})`,
                    // backgroundRepeat: "repeat-x",
                    backgroundSize: 'cover',
                  }}>
                    <div className={classes.secondContainer} >
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={logo} className={classes.logo}></img>
                        </div>
                        <h2 className={classes.loginHeader}>Autharc Demo Suite</h2>
                        <div style={{color:"#444"}}>Sign in your account</div>
                        <Card elevation={0} className={classes.cardLogin}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 15,
                              }}
                            >

                              <div style={{ flex: 1, borderRadius: 7, paddingTop: 10, height: 55, backgroundColor: "#DEDEDE", paddingRight: 10, paddingLeft: 10, marginRight: 5 }}>
                                <FormControl className={classes.inputFormControl}  >
                                  {/* <InputLabel>{'Enter Vken'}<sup>{`\u00a9`}</sup>{'Sign-In or Scan Vken'}<sup>{`\u00a9`}</sup>{'Sign-In QR Code'}</InputLabel> */}
                                  <InputLabel >{'Enter AuthMarkId or Scan AuthMarkId QR Code'}</InputLabel>
                                  <Input
                                    className={classes.root}
                                    style={{ width: "100%" }}
                                    value={this.state.code}
                                    onChange={(e) => this.onChange(e)}
                                    variant="outlined"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <div onClick={this.handleOpen}>
                                          <img src={webcam} className={classes.scanner} />
                                        </div>
                                      </InputAdornment>}
                                    InputLabel={{
                                      color: "red",
                                    }}
                                    inputProps={{
                                      autoComplete: "off",
                                      maxLength: 19,

                                      style: { textTransform: "uppercase", },
                                    }}
                                  />
                                </FormControl>


                                <span style={{ fontSize: 12, color: color.ERROR }}>
                                  {this.state.codeError}
                                </span>
                              </div>
                              <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                                <div
                                  id="submitClick"
                                  className={classes.roundButton}
                                  onClick={this.handleClick}
                                >
                                  {this.state.loader ? (
                                    <Loader thickness={4} type={'loader'} size={20} style={{ paddingTop: '31%' }} color={color.WHITE} />
                                  ) : (
                                    <img src={next} style={{ maxWidth: "100%" }}></img>
                                  )}
                                </div>
                              </div>
                              <QrScanner
                                handleClose={this.handleClose}
                                handleScan={this.handleScan}
                                className={classes.width}
                                handleError={this.handleError}
                                open={this.state.open}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                    <div className={classes.footer}>
                      Autharc © {new Date().getFullYear()}. All rights reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </div>
      );
    } catch (err) {
      console.log(err)
    }
  }
}
const mapStateToProps = (state) => ({
  login: state.Login.loginDetails,
  loader: state.Login.isLoading,
  openAlert: state.Login.openAlert,
  message: state.Login.message,
  token: state.Login.token,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getLogin }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
);
