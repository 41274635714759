import React from 'react'
import { Redirect } from "react-router-dom";
import path from "../../utils/constants/path";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
     console.log(error,errorInfo)
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Redirect to={path.notFound}></Redirect>
   
    } else {
      //
      return (<div>{this.props.children}</div>)
    }

  }
}
export default ErrorBoundary