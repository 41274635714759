import React from "react";
import { Divider, withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import color from "../../utils/constants/color";
import TextBox from "../../component/textbox/textbox";
import Upload from "../../component/upload/upload";
import Button from "../../component/button/button";
import label from "../../utils/constants/label";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import path from "../../utils/constants/path";
import User from "../../utils/assets/user.png";
import {
  getEntryFormList,
  checkEmailDuplicate,
} from "../../redux/action/List/entry_form_list";
import { bindActionCreators } from "redux";
import {
  alphabetSpaceOnly,
  emailValidator,
  fnBrowserDetect,
  getClientIp,
  numbersOnly,
  validatePhoneNumber
} from "../../utils/common_function/common_function";
import Alert from "../../component/alert/alert";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Dropdown from "../../component/dropdown/dropdown";
import { updateHeader } from "../../redux/action/header/header";
import Visa from "../../utils/assets/visa.png";
import MasterCard from "../../utils/assets/mastercard.png";
import RuPay from "../../utils/assets/rupay.png";
import DialogBox from "../../component/dialog/dialog";
import classNames from "classnames";
import moment from "moment";
import MobileTextBox from '../../component/textbox/mobiletextbox';
const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
    },

    txtRegister: {
      color: color.BLACK,
    },

    link: {
      color: color.SECONDARY,
      cursor: "pointer",
      fontWeight: 600,
      backgroundColor: "transparent",
      border: "none",
      fontSize: 17,
      outlineColor: color.SECONDARY,
      fontFamily: 'Noto Sans'
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "0px 20px 10px 20px",
      "& .MuiStepper-root": {
        padding: "24px 0px",
      },
    },
    secondaryImageStyle: {
      height: 40,
      width: 40,
      marginTop: -5,
    },
    headerStyle: {
      margin: 0,
      color: color.BLACK,
      fontWeight: 600,
      fontSize: 16,
      fontFamily: "Noto Sans",
      padding: "10px 0px 5px",
      justifyContent: "flex-start",
      display: "flex",
    },

    detailsCard: {
      margin: "0.5rem 0rem",
      // boxShadow: "0px 0px 20px",
      // color: color.SHADOW_PURPLE,
      borderRadius: "0.75rem",
      boxShadow:'0px 2px 8px 0px',
      color: color.LIGHT_BLUE,
    },

    cardStepper: {
      margin: "0rem 2.15rem",
      boxShadow: "0px 0px 10px",
      color: color.SHADOW_PURPLE,
      background: "none",

      "& .MuiStepper-root": {
        borderRadius: "0.75rem",
        padding: 15,
      },
    },

    detailContainer: {
      display: "flex",
      alignItems: "center",
      margin: 0,
    },

    btnClient: {
      justifyContent: "flex-end",
      gap: 10,
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
    },

    divider: {
      marginTop: "0.5rem",
    },
    clearIcon: {
      float: "right",
      cursor: "pointer",
      top: -3,
      display: "flex",
      justifyContent: "center",
      zIndex: 2,
      right: 0,
      margin: "4px 0px",
      borderRadius: 4,
      fontSize: 10,
      width: 20,
      height: 20,
      color: color.WHITE,
      alignItems: "center",
      background: color.BLACK,
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },

    dialogHeader: {
      textTransform: "uppercase",
      flexGrow: 1,
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      },
    }
  };
};

class EntryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      userImageError: "",
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: "",
      companyName: "",
      designation: "",
      firstNameError: "",
      lastNameError: "",
      mobileNoError: "",
      emailError: "",
      companyNameError: "",
      designationError: "",
      alreadyRegisteredEmail: "",
      alreadyRegisteredEmailError: "",
      userName: "",
      userNameError: "",
      bankList: [],
      cardTypeList: [],
      cardBrandList: [],
      bankID: "",
      cardBrandID: "",
      cardTypeID: "",
      bank: "",
      cardBrand: "",
      cardType: "",
      bankIDError: "",
      cardBrandIDError: "",
      cardTypeIDError: "",
      open: false,
      openAlert: false,
      message: "",
      severity: "success",
      accountCreationLoader: false,
      checkEmailLoader: false,
      profileID: "",
      ApiImage: "",
      Name: "",
      isLoading: false
    };
  }
  componentDidMount() {
    if (Object.keys(this.props.bankList).length != 0 && JSON.parse(this.props.bankList).length != 0) {
      this.setState({
        bankList:
          JSON.parse(this.props.bankList).Table.length != 0
            ? JSON.parse(this.props.bankList).Table.map((item) => {
              return { label: item.Name, value: item.ID };
            })
            : [],
        cardTypeList:
          JSON.parse(this.props.bankList).Table1.length != 0
            ? JSON.parse(this.props.bankList).Table1.map((item) => {
              return { label: item.Name, value: item.ID };
            })
            : [],
        cardBrandList:
          JSON.parse(this.props.bankList).Table2.length != 0
            ? JSON.parse(this.props.bankList).Table2.map((item) => {
              return { label: `${item.Name}`, value: item.ID };
            })
            : [],
      });
    }

  }
  handleAlert = () => {
    this.setState({ openAlert: false });
  };
  profileImageUpload = (event) => {
    if (event.target) {
      if (event.target.files[0]) {
        //
        var extension = event.target.files[0].name.split('.').pop();
        if (extension == "png" || extension == "jpg" || extension == "jpeg" || extension == "PNG" || extension == "JPG" || extension == "JPEG") {
          this.setState({
            image: URL.createObjectURL(event.target.files[0]),
            Name: "",
            ApiImage: event.target.files[0],
          });
        } else {
          this.setState({
            openAlert: true,
            message: label.messages.imageError,
            severity: "error",
          });
        }
        // here you can do whatever you want with your image. Now you are sure that it is an image
      }
    } else {
      const myRenamedFile = new File([event], "captureImage" + moment().format("YYYYMMDDHHmmss").toString() + ".png");
      if (myRenamedFile) {
        //
        this.setState({
          image: URL.createObjectURL(myRenamedFile),
          Name: "",
          ApiImage: myRenamedFile,
        });


      }
    }

  };

  MobileOfFocus = (value) => {
    const re = /^((?:\+94))[0-9]{9,10}$/
    if (!re.test(this.state.mobileNo)) {
      if (!validatePhoneNumber(this.state.mobileNo)) {
        this.setState({ mobileNoError: `${label.messages.kindlyProvide} Valid ${label.entryFormLabel.mobile}` });
      } else {
        this.setState({ mobileNoError: "" });
      }
    } else {
      this.setState({ mobileNoError: "" });
    }

  }

  onSubmit = () => {
    var isValid = true;
    if (this.state.firstName == "") {
      isValid = false;
      this.setState({
        firstNameError: `${label.messages.kindlyProvide} ${label.entryFormLabel.firstName}`,
      });
    }
    if (this.state.mobileNo != "" && this.state.mobileNo != null) {
      if (!validatePhoneNumber(this.state.mobileNo)) {
        isValid = false;
        this.setState({
          mobileNoError: `${label.messages.kindlyProvide} Valid ${label.entryFormLabel.mobile}`,
        });
      }
    }
    if (this.state.lastName == "") {
      isValid = false;
      this.setState({
        lastNameError: `${label.messages.kindlyProvide} ${label.entryFormLabel.lastName}`,
      });
    }

    if (this.state.email == "") {
      isValid = false;
      this.setState({
        emailError: `${label.messages.kindlyProvide} ${label.entryFormLabel.email}`,
      });
    } else {
      if (emailValidator(this.state.email)) {
        isValid = false;
        this.setState({
          emailError: `${label.messages.kindlyProvide} Valid ${label.entryFormLabel.email}`,
        });
      }
    }

    if (this.state.companyName == "") {
      isValid = false;
      this.setState({
        companyNameError: `${label.messages.kindlyProvide} ${label.entryFormLabel.companyName}`,
      });
    }
    if (isValid) {

      var data = new FormData();
      data.append("FirstName", `${this.state.firstName}`);
      data.append("LastName", this.state.lastName);
      data.append("Email", this.state.email);
      data.append(
        "Mobile",
        `${this.state.mobileNo == null ? "" : this.state.mobileNo}`
      );
      data.append("Company", `${this.state.companyName}`);
      data.append("Designation", `${this.state.designation}`);
      data.append(
        "UserImage",
        this.state.ApiImage == null || this.state.ApiImage == undefined
          ? ""
          : this.state.ApiImage
      );
      data.append("BankID", `${this.state.bankID}`);
      data.append("CardID", `${this.state.cardTypeID}`);
      data.append(
        "ImageName",
        this.state.Name != null &&
          this.state.Name != undefined &&
          this.state.Name != ""
          ? this.state.Name.split(window.globalConfig.UserImage)[1]
          : ""
      );
      data.append("CardBrandID", `${this.state.cardBrandID}`);
      data.append("ProductID", `${this.props.productID}`);
      data.append("UserID", this.props.loginDetails[0].UserID);
      data.append("IssuerID", this.props.issuerID);
      data.append("ProfileCreatedDate", "");
      data.append("ProfileExpireDate", "");
      data.append("ProfileNumber", this.state.profileID);
      data.append("Country", "india");

      let storeData = [
        {
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          Email: this.state.email,
          Mobile: this.state.mobileNo,
          Company: this.state.companyName,
          Designation: this.state.designation,
          UserImage: `${window.globalConfig.UserImage}${this.state.name}`,
          BankID: this.state.bankID,
          CardTypeID: this.state.cardTypeID,
          CardBrandID: this.state.cardBrandID,
          ProductID: this.props.ProductID,
          issuerID: this.props.issuerID,
        },
      ];
      this.setState({ accountCreationLoader: true });
      this.props.getEntryFormList(
        data,
        this.props.token,
        storeData,
        this.onSuccess,
        this.onFailure
      );
    }
  };
  onSuccess = (data) => {
    this.setState({
      openAlert: true,
      message: data.message,
      accountCreationLoader: false,
    });
    this.onReset();
    let step = {};
    step.activeStep = 2;
    step.id = this.props.productID
    step.issuerID = this.props.issuerID
    step.completedStep = 1
    step.mainHeader = this.props.productID === 1 ? label.product.vkenSignIn : this.props.productID == 2 ? label.product.vken2FA : this.props.productID == 3 ? label.product.vkenPay : "Welcome"
    step.subHeader = ""
    step.enableBack = false
    this.props.updateHeader(step)
    this.props.handleNextStep(step);
  };
  onFailure = (data) => {
    if (data.status == 401) {
      this.setState({
        accountCreationLoader: false,
      });
    } else {
      this.setState({
        openAlert: true,
        message: data.message,
        accountCreationLoader: false,
        severity: "error",
      });
    }
  };
  onAlreadyRegisteredSuccess = (data) => {
    this.setState({ checkEmailLoader: false, isLoading: false });

    if (data.data != null) {
      this.setState({
        checkEmailLoader: false
        , isLoading: false,
        firstName: JSON.parse(data.data).Table1[0].FirstName,
        lastName: JSON.parse(data.data).Table1[0].LastName,
        mobileNo:
          JSON.parse(data.data).Table1[0].Mobile == null
            ? ""
            : JSON.parse(data.data).Table1[0].Mobile,
        email: JSON.parse(data.data).Table1[0].Email,
        companyName: JSON.parse(data.data).Table1[0].Company,
        designation:
          JSON.parse(data.data).Table1[0].Designation == null
            ? ""
            : JSON.parse(data.data).Table1[0].Designation,
        profileID: JSON.parse(data.data).Table1[0].ProfileID,
        image:
          JSON.parse(data.data).Table1[0].Image == "" || JSON.parse(data.data).Table1[0].Image == null
            ? ""
            : JSON.parse(data.data).Table1[0].Image.search(
              window.globalConfig.UserImage
            ) != -1
              ? JSON.parse(data.data).Table1[0].Image
              : `${window.globalConfig.UserImage}${JSON.parse(data.data).Table1[0].Image
              }`,
        open: false,
        Name:
          JSON.parse(data.data).Table1[0].Image == "" || JSON.parse(data.data).Table1[0].Image == null
            ? ""
            : JSON.parse(data.data).Table1[0].Image.search(
              window.globalConfig.UserImage
            ) != -1
              ? JSON.parse(data.data).Table1[0].Image
              : `${window.globalConfig.UserImage}${JSON.parse(data.data).Table1[0].Image
              }`,
        alreadyRegisteredEmail: "",
        alreadyRegisteredEmailError: "",
        userName: "",
        userNameError: "",
      });
    }

  };
  onAlreadyRegisteredFailure = (data) => {
    if (data.status == 401 || data.status == 405) {
      this.setState({
        checkEmailLoader: false
        , isLoading: false
      });
    } else {
      this.setState({
        openAlert: true,
        message: data,
        severity: "error",
        checkEmailLoader: false,
        isLoading: false
      });
    }
  };
  onReset = () => {
    this.setState({
      image: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      email: "",
      emailError: "",
      mobileNo: "",
      mobileNoError: "",
      activationFieldOne: "",
      activationFieldOneError: "",
      activationFieldTwo: "",
      activationFieldTwoError: "",
      companyName: "",
      companyNameError: "",
      designation: "",
      designationError: "",
      alreadyRegisteredEmail: "",
      alreadyRegisteredEmailError: "",
      userName: "",
      userNameError: "",
      profileID: "",
      Name: "",
      ApiImage: "",
      isLoading: false,
      cardBrand: "",
      cardType: "",
      bank: "",
      bankID: "",
      cardBrandID: "",
      cardTypeID: "",
      bankIDError: "",
      cardBrandIDError: "",
      cardTypeIDError: ""
    });
  };
  onChangeFirstName = (e) => {
    if (alphabetSpaceOnly(e.target.value) && e.target.value.length < 31) {
      this.setState({ firstName: e.target.value, firstNameError: "" });
    } else {
      e.preventDefault();
    }
  };
  onChangeLastName = (e) => {
    if (alphabetSpaceOnly(e.target.value) && e.target.value.length < 31) {
      this.setState({ lastName: e.target.value, lastNameError: "" });
    } else {
      e.preventDefault();
    }
  };
  onChangeMobile = (value) => {
    // if (numbersOnly(e.target.value) && e.target.value.length < 11) {
    this.setState({ mobileNo: value, mobileNoError: "" });
    // } else {
    //   e.preventDefault();
    // }
  };
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value, emailError: "" });
  };
  onAlreadyRegisteredSubmit = async () => {
    var isValid = true;
    if (this.state.userName == "") {
      isValid = false;
      this.setState({
        userNameError: `${label.messages.kindlyProvide} ${label.entryFormLabel.firstName}`,
      });
    }
    if (this.state.alreadyRegisteredEmail == "") {
      isValid = false;
      this.setState({
        alreadyRegisteredEmailError: `${label.messages.kindlyProvide} ${label.entryFormLabel.email}`,
      });
    } else if (emailValidator(this.state.alreadyRegisteredEmail)) {
      isValid = false;
      this.setState({
        alreadyRegisteredEmailError: `${label.messages.kindlyProvide} Valid ${label.entryFormLabel.email}`,
      });
    }
    if (isValid) {

      let ip = ""

      getClientIp().then((res) => {
        ip = res
      })
      var data = new FormData();
      data.append("email", this.state.alreadyRegisteredEmail);
      data.append("firstName", this.state.userName);
      data.append("ipAddress", localStorage.getItem("IPAddress"));
      data.append("browserName", fnBrowserDetect());
      data.append("ProductID", this.props.productID);
      data.append("issuerID", this.props.issuerID);
      data.append("userID", this.props.loginDetails[0].UserID);
      data.append("version", "");
      this.setState({ checkEmailLoader: true, isLoading: true });
      this.props.checkEmailDuplicate(
        data,
        this.props.token,
        this.onAlreadyRegisteredSuccess,
        this.onAlreadyRegisteredFailure
      );
    }
  };
  onChangeAlreadyRegisteredEmail = (e) => {
    this.setState({
      alreadyRegisteredEmail: e.target.value,
      alreadyRegisteredEmailError: "",
    });
  };
  onChangeUserName = (e) => {
    if (alphabetSpaceOnly(e.target.value) && e.target.value.length < 31) {
      this.setState({ userName: e.target.value, userNameError: "" });
    }
  };
  onChangeCompanyName = (e) => {
    this.setState({ companyName: e.target.value, companyNameError: "" });
  };
  onChangeDesignation = (e) => {
    this.setState({ designation: e.target.value, designationError: "" });
  };
  onBankChange = (value) => {
    this.setState({ bankID: value[0].value, bank: value[0].label });
  };
  onCardBrandChange = (value) => {
    this.setState({ cardBrandID: value[0].value, cardBrand: value[0].label });
  };
  onCardTypeChange = (value) => {
    this.setState({ cardTypeID: value[0].value, cardType: value[0].label });
  };
  openAlreadyRegisteredDialog = () => {
    this.setState({
      open: true,
      firstName: "",
      Name: "",
      ApiImage: "",
      firstNameError: "",
      lastName: "",
      lastNameError: "",
      mobileNo: "",
      mobileNoError: "",
      designation: "",
      designationError: "",
      image: "",
      companyName: "",
      companyNameError: "",
      email: "",
      emailError: "",
      profileID: "",
      userName: "",
      userNameError: "",
      alreadyRegisteredEmail: "",
      alreadyRegisteredEmailError: "",
      cardBrand: "",
      cardType: "",
      bank: "",
      bankID: "",
      cardBrandID: "",
      cardTypeID: "",
      bankIDError: "",
      cardBrandIDError: "",
      cardTypeIDError: ""
    });
  };
  closeAlreadyRegisteredDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    try {
      return (
        <Card className={classes.detailsCard} elevation={0}>
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={6}>
                <h2 className={classes.headerStyle}>Client Details</h2>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                spacing={2}
                item
                xs={12}
                sm={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 0,
                }}
              >

                <Upload
                  id={"profile"}
                  type="Primary"
                  placeholder={<img
                    src={User}
                    className={classes.secondaryImageStyle}
                  ></img>}
                  URL={this.state.image}
                  error={this.state.userImageError}
                  onChange={this.profileImageUpload}
                ></Upload>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                sm={8}
                className={classes.detailContainer}
              >
                <Grid item xs={12} sm={6}>
                  <TextBox
                    label={label.entryFormLabel.firstName}
                    error={this.state.firstNameError}
                    value={this.state.firstName}
                    required
                    type="Primary"
                    disabled={this.state.profileID == "" || this.state.profileID == undefined ? false : true}
                    onChange={this.onChangeFirstName}
                  ></TextBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextBox
                    value={this.state.lastName}
                    label={label.entryFormLabel.lastName}
                    error={this.state.lastNameError}
                    required
                    disabled={this.state.profileID == "" ? false : true}
                    onChange={this.onChangeLastName}
                    type="Primary"
                  ></TextBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextBox
                    value={this.state.email}
                    label={label.entryFormLabel.email}
                    error={this.state.emailError}
                    disabled={this.state.profileID == "" ? false : true}
                    required
                    onChange={this.onChangeEmail}
                    type="Primary"
                  ></TextBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MobileTextBox
                    value={this.state.mobileNo}
                    label={label.entryFormLabel.mobile}
                    error={this.state.mobileNoError}
                    onChange={this.onChangeMobile}
                    type="Primary"
                    onBlur={() => { this.MobileOfFocus(this.state.mobileNo) }}
                  ></MobileTextBox>
                  {/* <TextBox
                    otherProps={{ maxLength: 10 }}
                    value={this.state.mobileNo}
                    label={label.entryFormLabel.mobile}
                    error={this.state.mobileNoError}
                    onChange={this.onChangeMobile}
                    type="Primary"
                  ></TextBox> */}
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.detailContainer}>
                {this.props.productID == 3 && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <Dropdown
                        isSearchable={true}
                        id="bank"
                        label={label.entryFormLabel.bankName}
                        onChange={this.onBankChange}
                        value={this.state.bank}
                        placeholder={label.placeholderLabel.select}
                        List={this.state.bankList}
                      ></Dropdown>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Dropdown
                        id="cardType"
                        value={this.state.cardType}
                        label={label.entryFormLabel.cardType}
                        onChange={this.onCardTypeChange}
                        placeholder={label.placeholderLabel.select}
                        List={this.state.cardTypeList}
                      ></Dropdown>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Dropdown
                        id="cardBrand"
                        value={this.state.cardBrand}
                        icon={true}
                        iconList={[Visa, MasterCard, RuPay]}
                        label={label.entryFormLabel.cardBrand}
                        onChange={this.onCardBrandChange}
                        placeholder={label.placeholderLabel.select}
                        List={this.state.cardBrandList}
                      ></Dropdown>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Divider className={classes.divider}></Divider>

            <h2 className={classes.headerStyle}>Company Details</h2>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextBox
                  label={label.entryFormLabel.companyName}
                  error={this.state.companyNameError}
                  value={this.state.companyName}
                  required
                  type="Primary"
                  onChange={this.onChangeCompanyName}
                ></TextBox>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextBox
                  label={label.entryFormLabel.designation}
                  error={this.state.designationError}
                  value={this.state.designation}
                  type="Primary"
                  onChange={this.onChangeDesignation}
                ></TextBox>
              </Grid>
            </Grid>

            <Grid
              container
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={8}
                style={{
                  justifyContent: "flex-start",
                  display: "flex",
                  fontSize: 14,
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <div className={classes.txtRegister}>
                    Already Registered?{" "}
                    <button
                      className={classes.link}
                      onClick={this.openAlreadyRegisteredDialog}
                    >
                      Click here
                    </button>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                sm={6}
                md={4}
                className={classes.btnClient}
              >
                <Grid item xs={4} xl={3}>
                  <Button
                    text={label.buttonLabel.next}
                    radius={10}
                    color={color.WHITE}
                    type="Primary"
                    isLoading={this.state.accountCreationLoader}
                    onClick={this.onSubmit}
                    backgroundColor={color.SECONDARY}
                  ></Button>
                </Grid>

                <Grid item xs={4} xl={3}>
                  <Button
                    text={label.buttonLabel.reset}
                    type="Primary"
                    radius={10}
                    onClick={this.onReset}
                    color={color.SECONDARY}
                  ></Button>
                </Grid>
              </Grid>
            </Grid>
            <Alert
              open={this.state.openAlert}
              message={this.state.message}
              onClose={this.handleAlert}
              severity={this.state.severity}
            ></Alert>
            <DialogBox
              width={320}
              open={this.state.open}
              onClose={this.closeAlreadyRegisteredDialog}
            >
              <Grid container style={{ paddingBottom: 20 }}>
                <div
                  className={classNames(classes.headerStyle, classes.dialogHeader)}>
                  Already Registered User
                </div>

                <div
                  className={classes.clearIcon}
                  onClick={this.closeAlreadyRegisteredDialog}
                >
                  &#x2715;
                </div>
              </Grid>
              <TextBox
                label={label.entryFormLabel.firstName}
                error={this.state.userNameError}
                value={this.state.userName}
                required
                type="Primary"
                onChange={this.onChangeUserName}
              ></TextBox>
              <br />
              <TextBox
                label={label.entryFormLabel.email}
                error={this.state.alreadyRegisteredEmailError}
                value={this.state.alreadyRegisteredEmail}
                required
                type="Primary"
                onChange={this.onChangeAlreadyRegisteredEmail}
              ></TextBox>
              <br />
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Grid item xs={5} sm={4}>
                  <Button
                    text={label.buttonLabel.submit}
                    radius={10}
                    color={color.WHITE}
                    type="Primary"
                    isLoading={this.state.checkEmailLoader}
                    onClick={this.onAlreadyRegisteredSubmit}
                    backgroundColor={color.SECONDARY}
                  ></Button>
                </Grid>
              </div>
            </DialogBox>
          </div>
        </Card>
      );
    } catch (err) {

      return <Redirect to={path.notFound} />;
    }
  }
}
const mapStateToProps = (state) => ({
  bankList:
    state.BankList.bankList.data != null &&
      state.BankList.bankList.data.length != 0
      ? state.BankList.bankList.data
      : [],
  loader: state.EntryFormList.isLoading,
  loginDetails:
    state.Login.loginDetails.Table1 != null &&
      state.Login.loginDetails.Table1.length != 0
      ? state.Login.loginDetails.Table1
      : [],
  token: state.Login.token,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateHeader, getEntryFormList, checkEmailDuplicate },
    dispatch
  );
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryForm))
);

