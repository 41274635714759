import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import coolor from '../../utils/constants/color'
import color from '../../utils/constants/color';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Steps = [

  {
    label:   <div style={{
      fontFamily: "Noto Sans !important", color: "#212529  !important", fontSize: 15, lineHeight: 1.2,
      fontWeight: 600
    }}>

      <span>What is <span>AutharcId</span>?</span></div>,
    description:        
      <div>
        <ul>
          <li>
          <span>AutharcId</span> is an easy-to-use, safe and secure identification and authentication
            product solution that uniquely identifies and authenticates the user, ensuring
            identity protection and security.
          </li>

          <li>
          <span>AutharcId</span> is used for financial services, insurance,
            healthcare, government programs, transportation and myriad of other services
            where secure personal identification is required.
          </li>

          <li>
            It lets consumers securely and
            anonymously access their accounts.
          </li>
        </ul>

        <div style={{
          fontFamily: "Noto Sans !important", color: "#212529  !important", fontSize: 15, lineHeight: 1.2,
          fontWeight: 600
        }}>

          What is the need of <span>AutharcId</span>?</div>
        <ul>
          <li>
            Identity management is very critical for business and consumer activities. To some extent,
            business sectors and individuals are constantly threatened by the burden of proving one’s
            identity.
          </li>

          <li>
            If an individual's identity can be verified in the most reliable manner, such
            identification becomes ‘highly’ trustworthy in the market today.
          </li>


          <li>
          <span>Id</span> helps to build an
            efficient governance, risk, and compliance program that supports collaboration across IT,
            Finance, Operations, and Legal units.
          </li>
        </ul>
      </div>
  },

  {
    label: 
    <div style={{
      fontFamily: "Noto Sans !important", color: "#212529  !important", fontSize: 15, lineHeight: 1.2,
      fontWeight: 600
    }}>

      <span>Benefits of <span>AutharcCard</span></span></div>,
    description:
      <div style={{ fontWeight: 100 }}>
        <ul>
          <li>
            Faster than traditional methods to submit a payment.    </li>

          <li>
            Unprecedented fraud prevention that minimizes monetary losses.
          </li>

          <li>
            As merchants deploy "contactless" terminals, they can immediately accept <span>AutharcCard</span> transactions.
          </li>

          <li>
            Only the issuing bank can access the stored actual account number.
          </li>

          <li>
          <span>AutharcCard</span> encoder is unique to every user and every device.
          </li>

          <li>
            Low Overhead on backend systems.
          </li>

          <li>
            Seamless integration with any system.
          </li>

          <li>
            Can be used anytime, anywhere (no Internet connection required).
          </li>
          <li style={{ fontWeight: 100 }}>
            Works with existing credit/debit card systems.
          </li>
          <li>
            Offers virtually unlimited number of user accounts under a single BIN (Bank Identification Number).
          </li>
        </ul>
      </div>,
  },

  // {
  //   label: 
  //   <div style={{
  //     fontFamily: "Noto Sans !important", color: "#212529  !important", fontSize: 15, lineHeight: 1.2,
  //     fontWeight: 600
  //   }}>

  //     <span>Benefits of <span>AutharcToken</span></span></div>,

  //   description:
  //     <div>
  //       <ul>
  //         <li>
  //           Generates an authentication token at fixed intervals using a built-in clock and a specific factory-encoded random key.
  //         </li>

  //         <li>
  //           Provides businesses with a simple and efficient authentication solution for the distribution, activation, and use of tokens.
  //         </li>

  //         <li>
  //           Helps customers to login securely in any public or private setting.
  //         </li>

  //         <li>
  //           Replaces hardware generated tokens and thereby reduces overall cost.
  //         </li>

  //         <li>
  //           Works on smart phones, laptops, PCs, Mac, tablets and other PDAs without requiring a connection to the Internet.
  //         </li>
  //       </ul>
  //     </div>,
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    color:color.BLACK,
    fontFamily: "Noto Sans !important",
    fontWeight: 300,
    lineHeight: 1.5,
    margin: 0,
    "& div.react-swipeable-view-container":{
      transition: 'all 0.5s ease 0s !important'
    },

    "& div.react-swipeable-view-container > div": {
      fontFamily: "Noto Sans !important",
      margin: 0,
      color:`${color.BLACK} !important`,
      fontWeight: "300 !important",
      lineHeight: 1.5,
      fontSize: 14,
      textShadow: "none",
      maxHeight: 470,
      marginLeft: -0.4,
      minHeight: 464,
      transition: 'opacity 1.5s',
      opacity: 1,

      "@media (max-height: 767px)": {
        minHeight: "100%",
      },

      "@media (min-width:1000px) and (min-height: 990px)": {
        minHeight: "100%",
        maxHeight: "100%"
      },

      [theme.breakpoints.down('sm')]: {
        minHeight: "100%",
      },


      "&::-webkit-scrollbar": {
        width: 4,
        height: 4
      },

      '&::-webkit-scrollbar-track': {
        //'-webkit-box-shadow': 'inset 0 0 6px #f1f1f1',       
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: color.SCROLLBARTHUMB,
        borderRadius:10
      },

      "& ul": {
        marginTop: 0,
        paddingLeft: 20,
        "& + div + ul":{          
        marginTop: 10,
        }
      }
    },
    "& > div:first-child": {
      background: "none !important",
      paddingLeft: "0 !important",

    },
    "& p": {
      fontFamily: "Noto Sans !important",
      color:`${color.BLACK} !important`,
      fontSize: "1rem",
      lineHeight: 1.2,
      fontWeight: 600,
    },

   "& div.MuiMobileStepper-root": {    
      background: color.WHITE,
      justifyContent: "flex-end",
      "& button.MuiButtonBase-root": {
        minWidth: "unset"
      },

      "& div.MuiMobileStepper-dots": {
        display: "none"
      },

      "& div.MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive": {

      "& div.MuiMobileStepper-dots .MuiMobileStepper-dot.MuiMobileStepper-dotActive":{
        
        backgroundColor: color.BLACK,
      },

      "& button.MuiButton-root.Mui-disabled": {
        color: "rgba(0,0,0,0.26)"
      },

      "& button.MuiButton-root":{
        color:color.BLACK
      },

    },
  },  
},

  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
    paddingLeft: theme.spacing(4),
    background: `${color.WHITE} !important`,
    zIndex: 1,
    width: "100%",
    flexGrow: 1,
    paddingTop: 20,
    position: "sticky",
    top: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
    },
    "& p": {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      paddingLeft: 2,

      "& div": {
        flexGrow: 1,
        display: 'flex',
      }
    },
  },
}));

function InfoSlider() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{Steps[activeStep].label}</Typography>  

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />       

      </Paper>
      
       {/* <Typography>{Steps[activeStep].description}</Typography>   */}

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {Steps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div className={classes.description}>
                {step.description}
              </div>
            ) : null}
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
}

export default InfoSlider;