import React from "react";
import { withStyles } from "@material-ui/core/styles";
import color from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
import Loader from "../loader/loader";
const styles = (theme) => ({
  roundContainer: {
    fontFamily: 'Noto Sans',
    width: "100%",
    padding: "8px 35px",
    borderRadius: (p) => (p.radius != undefined ? p.radius : "29px"),
    color: (p) => (p.color != undefined ? p.color : color.BLACK),
    border: (p) => `1px solid ${p.color != undefined ? p.color : color.BLACK}`,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 15,
    alignItems: "center",
    textTransform: (p) =>
      p.textStyle != undefined ? p.textStyle : "uppercase",
    fontWeight: "bold",
    outline: (p) =>
      // p.backgroundColor != undefined ? p.backgroundColor : "#f7941d",
      p.backgroundColor != undefined ? p.backgroundColor : "transparent",
    backgroundColor: (p) =>
      // p.backgroundColor != undefined ? p.backgroundColor : "#f7941d",
      p.backgroundColor != undefined ? p.backgroundColor : "transparent",
    "&:hover": {
      backgroundColor: (p) =>
        p.backgroundColor != undefined
          ? p.backgroundColor
          : p.color != undefined
            ? p.color
            : "transparent",
      // color: (p) => (p.color != undefined ? "#f7941d" : color.BLACK),
      color: (p) => (p.color != undefined ? color.WHITE : color.BLACK),
      alignItems: "center"
      // border:"1px solid #f7941d"
    },
  },
  secondaryContainer: {
    width: "auto",
    fontFamily: 'Noto Sans',
    color: (p) => (p.color != undefined ? p.color : color.LIGHT_BLACK),
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    lineHeight: 2,
    fontSize: 12,
    textTransform: (p) =>
      p.textStyle != undefined ? p.textStyle : "uppercase",
    fontWeight: "bold",
  },
  buttonProgress: {
    // top: "50%",
    // left: " 50%",
    /* position: absolute; */
    // marginTop: "-3px",
    // marginRight: "-74px",
    // marginLeft: "-23px",
    alignSelf:'center'
  },
});

class Button extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onClick = () => {
    if (this.props.onClick != undefined) {
    // } else {
      this.props.onClick();
    }
  };




  render() {
    const { classes } = this.props;
    if (this.props.type == "Primary") {
      return (
        <ErrorBoundary>
          <button className={classes.roundContainer} onClick={this.onClick}>
            {this.props.isLoading ?
              <Loader type={'loader'} size={15} color={color.WHITE} className={classes.buttonProgress} />
            :
            this.props.text}
              {this.props.endIcon}
          </button>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary>
          <button className={classes.secondaryContainer} onClick={this.onClick}>
            {this.props.isLoading ? <Loader type={'loader'} size={15} color={color.WHITE} /> : this.props.text}
          </button>
        </ErrorBoundary>
      );
    }
  }
}
export default withStyles(styles)(Button);
