import * as actionType from "../../action_type/action_type";
const initialState = {
  entryFormList: {
    head: [],
    body: []
  },
  profile: [],
  isLoading: false,
};
const entry_form_list_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ENTRY_FORM_DETAILS:
      
      return {
        ...state,
        profile: JSON.parse(action.payload)
      };
    case actionType.LOADER_ENTRY_FORM_DETAILS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.EMPTY_ENTRY_FORM_DETAILS:
      return {
        ...state,
        profile: [],
        entryFormList: {
          head: [],
          body: []
        },
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
export default entry_form_list_reducer;
