import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import path from "../../utils/constants/path";
import { connect } from "react-redux";
import StepperContainer from "../stepper/stepper";
import color from '../../utils/constants/color'
const styles = (theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "15% 85%",
  },

  stepper: {
    "& .MuiStepLabel-alternativeLabel": {
      fontFamily: "Noto Sans",
      fontSize: 14,
      color: color.BLACK,
      "& .MuiStepIcon-root.MuiStepIcon-completed,.MuiStepIcon-root.MuiStepIcon-active":
      {
        color: color.SECONDARY,
      },

      "& .MuiStepIcon-root": {
        color: color.GREY,
      },
    },

    "& .MuiStep-alternativeLabel": {
      padding: 0,
      "& .MuiStepConnector-line": {
        borderColor: "rgba(124, 124, 124, 0.21)",
      },
    },

    "& .MuiStepper-alternativeLabel": {
      justifyContent: "center",
    },
  },
  cardStepper: {
    margin: "0rem 2.15rem",
    boxShadow: "0px 0px 10px #00000019",
    background: "none",

    "& .MuiStepper-root": {
      borderRadius: "0.75rem",
      padding: 15,
    },
  },
});
class App extends Component {
  constructor(props) {
    super();
    this.state = {

    };
  }
  componentDidMount() {

    // if (this.props.location.pathname == path.dashboard) {
    //   if (this.props.token== "") {
    //     this.props.history.push(path.Login)
    //   }
    // }

  }
  render() {
    return (
      <div>
        <StepperContainer></StepperContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

  token: state.Login.token,
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(App))
);
