import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import ProductCard from "../../component/productcard/productcard";
import color from "../../utils/constants/color";
import Card from "@material-ui/core/Card";
import path from "../../utils/constants/path";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateHeader } from "../../redux/action/header/header";
import Loader from "../../component/loader/loader";
import ErrorBoundary from "../../component/error_boundary/error_boundary";
import { Redirect } from "react-router-dom";
import label from "../../utils/constants/label";
const styles = (theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      background: color.LIGHT_PURPLE,
      marginTop: "0.5rem",
    },

    header: {
      fontSize: 16, marginBottom: 20, width: "100%", display: "flex", justifyContent: "center", 
      color: "#000",
      minHeight: 22,
      "& + div": {
        width: "100%",
        color:"#000"
      },

      "& + div > div": {
        margin: 0,
        [theme.breakpoints.down('xs')]: {
          justifyContent: "center"
        },

        [theme.breakpoints.up('xl')]: {
          justifyContent: "center",
          gap: 35
        },
      }
    },

    productCard: {
      padding: 20,
      // backgroundImage: `linear-gradient(${color.WHITE},#738cf2)`,
      boxShadow: "0px 0px 30px",
      color: color.SHADOW_PURPLE,
      borderRadius: "0.75rem",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      flex: 1,
      //background: 'radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%,  #a8a4e0 100%)',
      // background:`radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%,  ${color.SECONDARY} 100%)`,
    },

    baseloaderProductSelect: {
      [theme.breakpoints.up('xl')]: {
        justifyContent: "center",
        gap: 35
      },
    },

    loaderProductSelection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      borderRadius: 10,
      alignItems: "center",
      height: 268,
      transform: "none"
    }
  };
};
class ProductSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }
  onClick = (val) => {
    this.props.updateHeader({
      mainHeader:
        val.id == 1 ? label.product.vkenSignIn : val.id == 2 ? label.product.vken2FA : label.product.vkenPay,
      productID: val.id,
      issuerID: val.issuerID,
      subHeader:
        this.props.loginDetails.length != 0 &&
          this.props.loginDetails[0].UserName != undefined &&
          this.props.loginDetails[0].UserName != null &&
          this.props.loginDetails[0].UserName != ""
          ? this.props.loginDetails[0].UserName
          : "NA",
      stepperActive: 1,
      enableBack: true,
    });

    let data = {};
    data.activeStep = 1;
    data.issuerID = val.issuerID;
    data.id = val.id;
    data.completedStep = 0;
    data.mainHeader =
      val.id === 1
        ? label.product.vkenSignIn
        : val.id == 2
          ? label.product.vken2FA
          : val.id == 3
            ? label.product.vkenPay
            : "Welcome";
    data.subHeader = "";
    data.enableBack = true;
    this.props.updateHeader(data);
    this.props.handleNextStep(data);
  };

  render() {
    const { classes } = this.props;

    const List = this.props.productList.map((item, index) => {
      return {
        recName: '',
        name: item.name,
        productID: item.id,
        buttonText: "ENTER",
        color:
          index % 2 === 0
            ? color.SECONDARY && index == 2
              ? "#18D2EC"
              : color.SECONDARY
            : color.GREEN,
        image: item.iconUrl,
        onClick: () => this.onClick(item),
      };
    });
    try {
      return (
        <ErrorBoundary>
          <div className={classes.container}>
            <div
              style={{
                // marginLeft: "3%",
                display: "flex",
                flexDirection: "column",
                // gap: 24,
                // alignItems: "center",
              }}
            >
              <Card elevation={0} className={classes.productCard}>
                <div className={classes.header}>
                  {!this.props.isLoading && List.length != 0 && "Select one of our product to continue"}
                </div>
                {this.props.isLoading ?
                  <Grid container xs={12} spacing={1} className={classes.baseloaderProductSelect}>
                    <Grid item xs={8} sm={4} xl={3}>
                      <Loader className={classes.loaderProductSelection}></Loader>
                    </Grid>

                    <Grid item xs={8} sm={4} xl={3}>
                      <Loader className={classes.loaderProductSelection}></Loader>
                    </Grid>

                    <Grid item xs={8} sm={4} xl={3}>
                      <Loader className={classes.loaderProductSelection}></Loader>
                    </Grid>
                  </Grid>
                  :
                  List.length == 0 ? <div style={{ display: "flex", justifyContent: "center" }}>{label.messages.noDataAvailable}</div> :
                    <ProductCard List={List} activeStep={0} />}
              </Card>
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (err) {
      console.log(err);
      return <Redirect to={path.notFound}></Redirect>;
    }
  }
}
const mapStateToProps = (state) => ({
  productList:
    state.ProductList.productList.data != null &&
      state.ProductList.productList.data.length != 0
      ? state.ProductList.productList.data
      : [],
  bankList:
    state.BankList.bankList.data != null &&
      state.BankList.bankList.data.length != 0
      ? state.BankList.bankList.data
      : [],
  headerName: state.Header.headerName,
  isLoading: state.ProductList.isLoading,
  token: state.Login.token,
  loginDetails:
    state.Login.loginDetails.Table1 != null &&
      state.Login.loginDetails.Table1.length != 0
      ? state.Login.loginDetails.Table1
      : [],
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateHeader }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelection))
);
