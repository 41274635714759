module.exports = {
    LIGHT_BLACK: "rgba(0,0,0,0.5)",
    LIGHT_WHITE: "rgba(255,255,255,.5)",
    BLACK: "#212529",
    // LIGHT_BLUE: '#296099',
    LIGHT_BLUE: 'rgb(41 96 153 / 20%)',
    WHITE: "#FFF",
    BROWN: "#AF772D",
    // PRIMARY: "#92278F",
    // SECONDARY: "#738cf2",  
    //dgtech app theme
    PRIMARY:"#f09346",
    // SECONDARY: "#2d659f",   
    //Changed secondary color by Dharani on Apr 24,2023
    SECONDARY: "#0B0146",

    SECONDARY_LIGHT:"#f3edfd",
    GREEN: "#3DBF28",
    ASH: "#b5b5c3",
    GREY: "#C7C7C7",
    BLUE: "#3B2E58",
    ERROR: "#F64E60",
    SUCCESS: "#1bcfb4",
    WARNING: "#FFA800",
    SCROLLBARTHUMB: "#BBBBBB",
    SHADOW_PURPLE: "#00000019",
    DARK_GREY: "#ADADAD",
    // LIGHT_PURPLE:"#FFF0FFB2",
    LIGHT_PRIMARY: "#92278FAA",
    LIGHT_ASH: "#F6F5F0",
    LIGHT_PINK: "#FAF7F2",
    LIGHT_LAVENDER: "#EFD9FD",
    LIGHT_WARNING: "#FFE2E5",
    LIGHT_SECONDARY_BACKGROUND: "rgba(115, 140, 242, 0.05)",
    LIGHT_SECONDARY: "rgba(233, 142, 21, 0.7)",
    LIGHT_PURPLE: "rgba(115 140 242 / 9%)",
    LIGHT_SUCCESS: "rgba(27, 207, 180, 0.2)",
    GRAY:"#e5e9f4"
}