module.exports = {
  product: {
    vkenSignIn: "WhizId",
    vken2FA: "WhizToken",
    vkenPay: "WhizCard",

    signIn: 'AutharcId',
    twoFA: 'AutharcToken',
    pay: 'AutharcCard',
    vken: 'DG'
  },
  messages: {
    required: "Required",
    valid: 'Valid',
    kindlyProvide: "Kindly Provide",
    noDataAvailable: "No Data Available",
    apiError: "No response from server.please try later..",
    imageError: 'Kindly Select Image File'
  },
  flag: {
    success: "S",
    failure: "F"
  },
  buttonLabel: {
    reset: "Reset",
    submit: "Submit",
    tryNow: "Try Now",
    next: "Next",
    login: "Login",
    signIn: 'Sign-In',
    visitDemoSite: "Visit Demo Site",
  },
  placeholderLabel: {
    select: "Select",
  },
  apiError: {
    status: {
      status: "F",
      message: "No response from server.please try later..",
    },
  },
  fundTransferEntryForm: {
    remarks: 'Remarks',
    amount: 'Amount',
    mobileNo: 'Mobile',
    fromaccount: 'From Account',
    name: ' Beneficiary Name',
    accountNo: 'Beneficiary Account Number',
  },
  entryFormLabel: {
    firstName: "First Name",
    lastName: "Last Name",
    mobile: "Mobile No",
    email: "Email",
    bankName: "Bank Name",
    cardType: "Card Type",
    cardBrand: "Card Brand",
    activationFieldOne: "Activation Field One",
    activationFieldTwo: " Activation Field Two",
    companyName: "Company Name",
    designation: "Designation",
    userName: "User Name",

  },
};
