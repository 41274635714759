import React from "react";
import { withStyles, Grid, Divider } from "@material-ui/core";
import webcam from "../../utils/assets/webcam.png";
import Button from "../../component/button/button";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router";
import { getLogin } from "../../redux/action/login/login";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import color from "../../utils/constants/color";
import QrScanner from "../../component/qrscanner/qrscanner";
import label from "../../utils/constants/label";
import Alert from "../../component/alert/alert";
import { fnBrowserDetect, getClientIp, emailValidator } from "../../utils/common_function/common_function";
import ProductCard from "../../component/productcard/productcard";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Card from "@material-ui/core/Card";
import successImage from '../../utils/assets/PngItem_343419.png'
import { TestMiri } from "../../redux/action/login/test_miri";
import next from "../../utils/assets/loginarrow.svg";
import Loader from "../../component/loader/loader";
import BankLogo from '../../utils/assets/banklogo.png';
import Typography from '@material-ui/core/Typography';
import { alphaNumericOnly } from "../../utils/common_function/common_function";
import { updateHeader } from "../../redux/action/header/header";
import classNames from "classnames";
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = (theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      minHeight: "278px",
    },

    welcome: {
      margin: "20px 0",
    },

    txtTry: {
      padding: "0px 20px 0px 0px",
      position: "relative",
      "& div > div": {
        padding: "8px 25px",
        borderRadius: 10
      }
    },
    banklogo: {
      height: 75
    },
    nxtNavigate: {
      // color: "#009ef7",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        display: "flex",
        color: color.SECONDARY,
        cursor: "pointer",
      }
    },

    featureSubHeader: {
      color: color.BLUE,
      fontSize: 16,
    },


    header: {
      fontWeight: 600,
      color: color.BLUE,
      textTransform: "uppercase"
    },

    securityFeatures: {
      padding: "6px 0px",
      color: `${color.BLACK} !important`,
      fontSize: 14,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
      '&:last-child': {
        paddingBottom: 0
      },
    },

    signinCard: {
      boxShadow: "0px 0px 20px",
      color: color.SHADOW_PURPLE,
      borderRadius: "0.75rem",
      padding: 20,
      marginBottom: 20

    },

    roundButton: {
      backgroundColor: color.SECONDARY,
      float: "right",
      width: 40,
      height: 40,
      padding: 0,
      cursor: "pointer",
      boxSizing: "content-box",
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    card: {
      gap: 10,
      padding: 20,
      background: color.WHITE,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "initial",
      borderRadius: "0.75rem",
      boxShadow: "0px 0px 10px",
      color: color.SHADOW_PURPLE
    },

    vkenCard: {
      width: "50%", margin: "auto", marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        width: "60%"
      },
      [theme.breakpoints.down('xs')]: {
        width: "87%"
      },
      [theme.breakpoints.up('xl')]: {
        width: "40%",
      },
    },


    inputFormControl: {
      width: "100%",
      "& label.MuiFormLabel-root": {
        color: color.LIGHT_BLACK,
        fontFamily: "Noto Sans",
        fontWeight: 600,
        fontSize: 12,
        [theme.breakpoints.down('1165')]: {
          fontSize: 10,
        },

        "&.Mui-focused": {
          color: color.LIGHT_BLACK,
        }
      },
      "& div.MuiInput-underline:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      }
    },

    scanner: {
      width: 30,
      height: 30,
      paddingBottom: 10,
      cursor: "pointer",
      [theme.breakpoints.down('sm')]: {
        width: 22,
        height: 22,
      },
    },

    btnClient: {
      display: "flex",
      justifyContent: "center",
    },
    root: {
      "& input:-webkit-autofill": {
        boxShadow: "0 0 0 30px #FFF0FFB2 inset",
      },

      "& label": {
        color: color.LIGHT_BLACK,
        fontFamily: "Noto Sans",
        fontSize: 12.5,
        fontWeight: 600,
      },
      "& .MuiInputBase-input": {
        outline: "none",
      },

      "& .MuiInput-root": {
        outline: "none",
      },
      "& label.Mui-focused": {
        color: color.LIGHT_BLACK,
      },
      "& .MuiInput-underline:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    featurecardContainer: {
      borderRadius: "0 0 0.75rem 0.75rem ",
      margin: "0 auto !important",
      left: 0,
      bottom: 0,
      right: 0,
      padding: "10px 0px 0px",
      [theme.breakpoints.only('xs')]: {
        padding: 20
      },
      [theme.breakpoints.down('sm')]: {
        height: "25%"
      },
      [theme.breakpoints.only('md')]: {
        margin: "15px 0 !important",
      },
    },

    userEmail: {
      textOverflow: "ellipsis",
      overflow: "hidden"
    },

    featureHeader: {
      textTransform: "uppercase",
      backgroundSize: "150%",
      backgroundPositionX: "25%",
      padding: 15,
      fontWeight: "600 !important",
      textAlign: "center",
      borderRadius: "0.75rem",
      color: color.PRIMARY,
      fontFamily: 'Noto Sans !important',
      background: "#f8f9fa",
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: "15px 10px"
      },

    },

    trymiriHeader: {
      display: "flex",
      alignItems: "flex-end",
    },

    tryHeader: {
      margin: 0
    },

    verificationHeader: {
      paddingBottom: 5,
      fontSize: 14,
      color: color.PRIMARY,
      "& + div": {
        color: color.BLACK,
        fontSize: 16
      }
    },

    serviceCard: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: color.LIGHT_LAVENDER,
      border: `1px solid ${color.LIGHT_LAVENDER}`,
      borderRadius: "0.75rem",
      margin: "auto 0",
    },

    link: {
      color: color.SECONDARY,
      cursor: "pointer",
      fontWeight: 600,
      backgroundColor: "transparent",
      border: "none",
      fontSize: 17,
      outlineColor: color.SECONDARY,
    },
  };
};
class TryMiri extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      userID: "",
      userIDError: "",
      code: "",
      codeError: "",
      openAlert: false,
      message: "",
      showCard: false,
      loader: false,
      miriAccountNo: '',
      TestMiri: [],
      email: '',
      emailError: '', severity: "success"
    };
  }
  componentDidMount() {
    if (!this.state.showCard) {
      let value = {}
      value.id = this.props.productID
      value.issuerID = this.props.issuerID
      value.activeStep = 3
      value.enableBack = true
      value.completedStep = 2
      value.mainHeader = this.props.productID === 1 ? label.product.vkenSignIn : this.props.productID == 2 ? label.product.vken2FA : this.props.productID == 3 ? label.product.vkenPay : "Welcome"
      value.subHeader = ""
      this.props.updateHeader(value)
    }
  }
  onChangeUserName = (e) => {
    this.setState({ userID: e.target.value, userIDError: "" })
  }
  handleScan = (data) => {

    if (this.props.productID == 2) {
      if (data != null) {
        this.setState({ codeError: "" })
        if (data != "" && data.replaceAll(/\s/g, "").length == 16) {
          this.setState({ code: data })
          this.handleClick()
          this.setState({ open: false })
        } else {
          this.setState({
            open: false,
            loader: false,
            openAlert: true,
            message: label.messages.kindlyProvide + " " + label.messages.valid + " " + "Sign-In Code",
            severity: "warning",
          });
        }
      }
    } else {
      if (data != null) {
        this.setState({ codeError: "" })
        if (data != "" && data.replaceAll(/\s/g, "").length == 16) {
          this.setState({ code: data })
          this.onSubmit()
          this.setState({ open: false })
        }
      }
    }
  };


  handleError = () => { };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleError = () => { };
  handleOpen = () => {
    this.setState({ open: true });
  };
  onSubmit = async () => {
    var isValid = false;
    if (this.state.code == "") {
      isValid = false;
      this.setState({ codeError: `${label.messages.kindlyProvide} Code` });
    } else if (this.state.code.replaceAll(/\s/g, "").length != 16) {
      isValid = false;
      this.setState({
        codeError: `${label.messages.kindlyProvide} Valid Code`,
      });
    } else {
      isValid = true
    }

    if (isValid) {

      let ip = ""

      getClientIp().then((res) => {
        ip = res
      })
      var data = new FormData();
      data.append("miriID", this.state.code.replaceAll(/\s/g, ""))
      data.append("ipAddress", localStorage.getItem("IPAddress"))
      data.append("browserName", fnBrowserDetect())
      data.append("version", "")
      this.setState({ loader: true });
      this.props.TestMiri(data, this.onSuccess, this.onFailure);
    }
  };
  onSuccess = (data) => {

    this.setState({
      loader: false,
      openAlert: true,
      message: data.message,
      severity: "success",
      showCard: true, TestMiri: data.data != null ? JSON.parse(data.data).Table : []
    });
    let value = {}
    value.id = this.props.productID
    value.issuerID = this.props.issuerID
    value.activeStep = 3
    value.enableBack = true
    value.completedStep = 3
    value.mainHeader = this.props.productID === 1 ? label.product.vkenSignIn : this.props.productID == 2 ? label.product.vken2FA : this.props.productID == 3 ? label.product.vkenPay : "Welcome"
    value.subHeader = ""
    this.props.updateHeader(value)
    this.props.handleNextStep(value)
  };
  onFailure = (data) => {

    this.setState({
      loader: false,
      openAlert: true,
      message: data.message,
      severity: "warning",
      showCard: false
    });
  };
  onClose = () => {
    this.setState({ openAlert: false });
  };
  onClose = () => {
    this.setState({ openAlert: false });
  };

  onChange = (e) => {
    if (alphaNumericOnly(e.target.value.replaceAll(/\s/g, ""))) {
      let trimValue = e.target.value.replace(/ /g, "").toUpperCase();
      let setSpace = trimValue.match(/.{1,4}/g);
      if (setSpace == null) {
        this.setState({ code: "", codeError: "" });
      } else {
        this.setState({ code: setSpace.join(" "), codeError: "" });
      }
    }
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value, emailError: "" });
  }


  handleClick = async () => {

    this.setState({ loader: true });
    if (this.state.email == '') {
      this.setState({ loader: false })
      this.setState({ emailError: `${label.messages.kindlyProvide} Email` });
    } else {
      if (emailValidator(this.state.email)) {
        this.setState({ loader: false })
        this.setState({ emailError: `${label.messages.kindlyProvide} Valid Email` });
      } else {
        this.onSuccessFundTransfer(this.state.email)
      }
    }
  };

  onSuccessFundTransfer = (email) => {
    this.setState({ loader: false });

    let profileData = this.props.profileData?.Table1[0];
    if (profileData.Email.toUpperCase() == email.toUpperCase()) {
      window.open(window.globalConfig.FundTransfer + `?${profileData.MiriAccountNumber}`)
    } else {
      this.setState({ emailError: `${label.messages.kindlyProvide} Registered Email` });
    }
  }

  onFailureFundTransfer = (msg) => {
    this.setState({
      loader: false,
      openAlert: true,
      message: msg,
      severity: "warning",
    });
  }
  onClickTryAgain = () => {
    this.setState({ showCard: false, code: "", codeError: "" })
    let value = {}
    value.id = this.props.productID
    value.issuerID = this.props.issuerID
    value.activeStep = 3
    value.enableBack = true
    value.completedStep = 2
    value.mainHeader = this.props.productID === 1 ? label.product.vkenSignIn : this.props.productID == 2 ? label.product.vken2FA : this.props.productID == 3 ? label.product.vkenPay : "Welcome"
    value.subHeader = ""
    this.props.updateHeader(value)
  }

  render() {
    const { classes } = this.props;
    try {
      return (
        <>
          <div className={classes.container}>
            <Alert
              open={this.state.openAlert}
              message={this.state.message}
              onClose={this.onClose}
              severity={this.state.severity}
            ></Alert>
            <div style={{ marginTop: "0.5rem", width: "100%" }}>
              {this.props.productID == 1 ? (<>
                {this.state.showCard ?
                  <div className={classNames(classes.card, classes.vkenCard)}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 21 }}>
                      <img
                        src={successImage}
                        style={{ width: 43, height: 43 }}
                      ></img>
                      <p
                        style={{
                          fontSize: 20,
                          color: color.GREEN,
                          fontWeight: "bold",
                          margin: 0
                        }}
                      >
                        Verification Successful
                      </p>
                    </div>
                    <Divider className={classes.divider}></Divider>
                    {this.state.TestMiri != null && this.state.TestMiri.length != 0 ?
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Grid container spacing={2} style={{ margin: "5px 0" }}>
                          {this.state.TestMiri != null && this.state.TestMiri.length != 0 && this.state.TestMiri[0].UserName != undefined && this.state.TestMiri[0].UserName != null &&
                            <Grid item xs={12} sm={6}>
                              <div className={classes.verificationHeader}>User Name</div>
                              <div>{`${this.state.TestMiri[0].UserName}`}</div>
                            </Grid>}
                          {this.state.TestMiri != null && this.state.TestMiri.length != 0 && this.state.TestMiri[0].UserName != undefined && this.state.TestMiri[0].UserName != null &&

                            <Grid item xs={12} sm={6}>
                              <div className={classes.verificationHeader}>Email</div>
                              <div className={classes.userEmail}>{this.state.TestMiri[0].Email || "NA"}</div>
                            </Grid>}
                        </Grid>

                        <Grid container spacing={2} style={{ margin: "5px 0" }}>

                          {this.state.TestMiri != null && this.state.TestMiri.length != 0 && this.state.TestMiri[0].UserName != undefined && this.state.TestMiri[0].UserName != null &&
                            <Grid item xs={12} sm={6}>
                              <div className={classes.verificationHeader}>Mobile</div>
                              <div>{this.state.TestMiri[0].Mobile || "NA"}</div>
                            </Grid>}
                          {this.state.TestMiri != null && this.state.TestMiri.length != 0 && this.state.TestMiri[0].UserName != undefined && this.state.TestMiri[0].UserName != null &&

                            <Grid item xs={12} sm={6}>
                              <div className={classes.verificationHeader}>Company</div>
                              <div style={{ fontSize: 16 }}>{this.state.TestMiri[0].Company || "NA"}</div>
                            </Grid>}
                          {this.state.TestMiri != null && this.state.TestMiri.length != 0 && this.state.TestMiri[0].UserName != undefined && this.state.TestMiri[0].UserName != null &&

                            <Grid item xs={12} sm={6} style={{ margin: "5px 0" }}>
                              <div className={classes.verificationHeader}>Designation</div>
                              <div>
                                {this.state.TestMiri[0].Designation || "NA"}
                              </div>
                            </Grid>}
                        </Grid>
                        <Grid item xs={6} style={{ margin: "auto" }}>
                          <button className={classes.link} onClick={this.onClickTryAgain}>Try Again</button>
                        </Grid>

                      </div> : <div style={{ display: "flex", justifyContent: "center" }}><h5>No Data Available</h5></div>}
                  </div> : <div className={classNames(classes.card, classes.vkenCard)}>

                    <Grid container >
                      <h3 className={classes.tryHeader}>
                        <span style={{ margin: 0, color: "#92278f" }}>Try</span>
                        <span style={{ margin: 0, color: color.SECONDARY }}>
                          {this.props.productName == label.product.vkenSignIn ?
                            <span>
                              <span> AutharcId</span>
                            </span>
                            : this.props.productName == label.product.vken2FA ?
                              <span>
                                <span> AutharcToken</span>
                              </span> :

                              <span>
                                <span> AutharcCard</span>
                              </span>
                          }
                        </span>
                      </h3>
                    </Grid>
                    <div style={{ minHeight: 80, width: "100%", marginTop: 10 }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <FormControl className={classes.inputFormControl}>
                          <InputLabel>{'Enter AutharcId or Scan AutharcId QR Code'}</InputLabel>
                          <Input
                            className={classes.root}
                            style={{ width: "100%" }}
                            value={this.state.code}
                            onChange={(e) => this.onChange(e)}
                            endAdornment={
                              <InputAdornment position="end">
                                <div onClick={this.handleOpen}>
                                  <img src={webcam} className={classes.scanner} />
                                </div>
                              </InputAdornment>}
                            inputProps={{
                              autoComplete: "off",
                              maxLength: 19,
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </FormControl>
                      </div>

                      <span style={{ fontSize: 12, color: color.ERROR }}>
                        {this.state.codeError}
                      </span>
                      {/* <TextField
                        inputProps={{
                          autoComplete: 'off',
                          maxLength: 19,
                          style: { textTransform: "uppercase" }
                        }}
                        value={this.state.code}
                        style={{ width: "100%" }}
                        id="standard-basic"
                        label="Enter Vken Sign-In or Scan Vken Sign-In QR Code"
                        className={classes.root}
                        onChange={(e) => this.onChange(e)}
                      />
                      <div
                        onClick={this.handleOpen}
                        style={{
                          borderBottom: "1px solid rgba(0, 0, 0,0.42)",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={webcam}
                          className={classes.scanner}
                        ></img>
                      </div>
                    </div>
                    <div style={{ fontSize: 12, color: color.ERROR }}>
                      {this.state.codeError}
                    </div> */}
                    </div>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <Grid item xs={4} sm={3}>
                        <Button
                          text={label.buttonLabel.submit}
                          radius={10}
                          color={color.WHITE}
                          type="Primary"
                          isLoading={this.state.loader}
                          onClick={this.onSubmit}
                          backgroundColor={color.SECONDARY}
                        ></Button>
                      </Grid>
                    </div>
                  </div>}
              </>
              )
                : this.props.productID == 2 ?
                  (
                    <Card elevation={0} className={classes.signinCard}>
                      <div className={classes.card} style={{ padding: 0, boxShadow: "none" }}>
                        <Grid container spacing={3} style={{ justifyContent: "space-evenly" }}>
                          <Grid item xs={12} sm={6} md={5} xl={4}>
                            <Grid container>
                              <h3 className={classes.tryHeader}>
                                <span style={{ margin: 0, color: "#92278f" }}>Try</span>
                                <span style={{ margin: 0, color: color.SECONDARY }}>
                                  {this.props.productName == label.product.vkenSignIn ?
                                    <span>
                                      <span> AutharcId</span>
                                    </span>
                                    : this.props.productName == label.product.vken2FA ?
                                      <span>
                                        <span> AutharcToken</span>
                                      </span> :

                                      <span>
                                        <span> AutharcCard</span>
                                      </span>
                                  }
                                </span></h3>
                            </Grid>
                            <div>
                              <img src={BankLogo} className={classes.banklogo} />
                            </div>
                            <Grid item xs={12}>
                              <h4 style={{ margin: 0, color: color.SECONDARY, textTransform: "uppercase", display: "flex", alignItems: "center" }}>LOGIN TO NET BANKING</h4>
                            </Grid>
                            <Grid item xs={12}>
                              <div style={{ width: "100%", marginTop: 10, minHeight: 80 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: 10,
                                  }}
                                >
                                  <TextField
                                    inputProps={{
                                      autoComplete: 'off',
                                      maxLength: 200
                                    }}
                                    value={this.state.email}
                                    style={{ width: "100%" }}
                                    id="standard-basic"
                                    label="Email"
                                    className={classes.root}
                                    onChange={(e) => this.onChangeEmail(e)}
                                  />

                                </div>
                                <div style={{ fontSize: 12, color: color.ERROR }}>
                                  {this.state.emailError}
                                </div>
                              </div>
                              <Grid item xs={12} className={classes.btnClient} style={{ justifyContent: "flex-end" }}>
                                <div
                                  id="submitClick"
                                  className={classes.roundButton}
                                  onClick={this.handleClick}
                                >
                                  {this.state.loader ? (
                                    <Loader thickness={4} type={'loader'} size={20} style={{ paddingTop: '31%' }} color={color.WHITE} />
                                  ) : (
                                    <img src={next} style={{ maxWidth: "100%" }}></img>
                                  )}
                                </div>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                                  <Grid item xs={12} md={12}>
                                    <Typography className={classes.featureHeader}>
                                      get a secure payment service
                                    </Typography>
                                  </Grid>

                                  <Grid className={classes.featurecardContainer} item xs={12} md={12} style={{ background: "#fff" }}>
                                    <div>
                                      <div className={classes.securityFeatures}> Built-in Two-Factor Authentication</div>
                                      <div className={classes.securityFeatures}> Help customers to login securely</div>
                                      <div className={classes.securityFeatures}>
                                        Secure Identification, Authorization & Authentication
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>




                          <Grid item xs={12} sm={6} md={5} xl={4} className={classes.serviceCard}>
                            <div className={classes.welcome}>
                              <span className={classes.header}> We have added a host of new features!</span>
                            </div>

                            <div className={classes.featureSubHeader}>
                              You can now do so much more:
                            </div>

                            <ul>
                              <li className={classes.securityFeatures}>
                                Available as a Local App for mobile platforms</li>
                              <li className={classes.securityFeatures}> Enable users to send/receive money almost instantly </li>
                              <li className={classes.securityFeatures}>
                                Unique Secure ID Number for each Transaction
                              </li>
                              <li className={classes.securityFeatures}>
                                Payment is processed only upon confirmation
                              </li>
                              <li className={classes.securityFeatures}>
                                You never need forgot password option
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  ) :
                  (
                    <Card elevation={0} className={classes.signinCard}>
                      <div className={classes.card} style={{ width: "100%", padding: 0, boxShadow: "none" }}>
                        <Grid container>
                          <h3 className={classes.tryHeader}>
                            <span style={{ margin: 0, color: "#92278f" }}>Try</span>
                            <span style={{ margin: 0, color: color.SECONDARY }}>
                              {this.props.productName == label.product.vkenSignIn ?
                                <span>
                                  <span> AutharcId</span>
                                </span>
                                : this.props.productName == label.product.vken2FA ?
                                  <span>
                                    <span> AutharcToken</span>
                                  </span> :

                                  <span>
                                    <span> AutharcCard</span>
                                  </span>
                              }
                            </span></h3>
                        </Grid>
                        <ProductCard activeStep={this.props.activeStep}></ProductCard>

                      </div>
                    </Card>
                  )}
              {this.props.productID != 2 &&
                <div onClick={() => {

                  let value = {}
                  value.id = this.props.productID
                  value.issuerID = this.props.issuerID
                  value.activeStep = 0
                  value.enableBack = true
                  value.completedStep = ""
                  value.mainHeader = "Welcome"
                  value.subHeader = ""
                  this.props.updateHeader(value)
                  this.props.goToStep(value)
                }} style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: 20 }}>
                  <div className={classes.txtTry} style={{ cursor: "pointer", display: "flex", justifyContent: "flex-start", marginLeft: "0%", marginTop: "0%" }}>
                    <div className={classes.nxtNavigate}>
                      <NavigateBeforeIcon />
                    </div>
                    <div>
                      <h4 style={{ margin: 0, color: color.SECONDARY, cursor: "pointer", fontWeight: 600 }}>Try Other Products</h4>
                    </div>
                  </div>
                </div>
              }


            </div>
          </div>
          <QrScanner
            handleClose={this.handleClose}
            handleScan={this.handleScan}
            handleError={this.handleError}
            open={this.state.open}
          />
        </>
      );
    }
    catch (err) {

    }
  }
}
const mapStateToProps = (state) => ({
  login: state.Login.loginDetails,
  loader: state.Login.isLoading,
  openAlert: state.Login.openAlert,
  message: state.Login.message,
  profileData: state.EntryFormList.profile,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getLogin, TestMiri, updateHeader }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TryMiri))
);