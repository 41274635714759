import "./utils/stylesheet/App.css";
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import './utils/stylesheet/style.css';

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
