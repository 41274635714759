import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import ErrorBoundary from "../error_boundary/error_boundary";
import color from "../../utils/constants/color"

const styles = () => ({
  filterBox: {
    width: "fit-content",
    position:"absolute",
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 5%)",
    border: `solid 1px ${color.LIGHT_ASH}`,
    backgroundColor: color.WHITE,
    right: 0,
    zIndex: 3,
    top: 24,
    marginTop: -5,
    marginLeft: 9,
    borderRadius: 8,
    fontSize: 16,
    color: color.BLACK,
    fontWeight: 500,
    '& .MuiSvgIcon-root': {
      color: color.BLACK,
      minWidth:46
    },

    '& div':{
      fontFamily: "Josefin Sans"
    }
  },
  filterBoxSHow: {
    position: "relative",
  },
  filterIcon: {
    // position: "absolute",
    left: 20,
    top: 15,
    cursor: "pointer",
  },
});
class ZPopover extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <div className={classes.filRight}>
          <div className={classes.filterBoxSHow}>
            <div className={classes.filterIcon} onClick={this.props.onClick}>
              {this.props.PopoverText}
            </div>
            {this.props.open && (
              <ClickAwayListener onClickAway={this.props.onClose}>
                <Grow
                  in={this.props.open}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(this.props.open ? { timeout: 1000 } : {})}
                >
                  <div className={classes.filterBox}>{this.props.children}</div>
                </Grow>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(ZPopover);
