import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import List from "./list";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ResetIcon from "../../utils/assets/reset.png";
import SearchImage from "../../utils/assets/img30.png";
import color from "../../utils/constants/color";
import ErrorBoundary from "../error_boundary/error_boundary";
const styles = (theme) => ({
  dropBoxLabel: {
    fontSize: 14,
    paddingBottom: 10,
    color: color.BLACK,
    fontWeight: 400,
  },
  easySelect: {
    borderRadius: 5,
    padding: "6px",
    fontSize: "0.8rem !important",
    cursor: "default",
    display: "block",
    position: "relative",
    color: color.BLACK,
    border: `1px solid ${color.LIGHT_BLACK}!important`,
    height: "28px",
    marginTop: 10,
  },

  clearSelectfromDiv: {
    background: `url(${ResetIcon})  no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    top: "12px",
  },

  normalDropdownArrow: {
    background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 1,
    top: "14px",
  },

  normalDropdownStyledSelect: {
    color: color.BLACK,
    fontSize: "14px",
    paddingRight: 18,
    left: 10,
    top: 6,
    paddingLeft: 0,
    fontWeight: 500,
    backgroundColor: "transparent",
    borderRadius: "0.2rem",
    position: "absolute",
    right: 0,
    bottom: 0,
    paddingTop: 5,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "left",
    overflow: "hidden",
    "&::after": {
      background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
      width: "18px",
      height: "18px",
      zIndex: 0,
      position: "absolute",
      right: 10,
      top: "12px",
    },
  },

  dropdownListPlaceholder:{
     color: color.DARK_GREY, 
     fontWeight: 400 
  },

  divSearcheasySelect: {
    borderBottom: "solid 1px #e6e7ea !important",
    padding: 0,
    overflow: "hidden",
    background: `url(${SearchImage}) no-repeat 9px  center !important`,
    height: 50,
    borderRadius: 0,
    paddingLeft: "15px",
    position: "relative",
  },

  searchInputeasySelect: {
    width: "87%",
    marginRight: "14px",
    color: color.BLACK,
    fontFamily: "Noto Sans",
    fontSize: "12px",
    textIndent: "8px",
    fontWeight: 500,
    background: `url(${SearchImage}) no-repeat -19px  center !important`,
    border: 0,
    height: 50,
    borderRadius: 0,
    paddingLeft: "15px",
    outline: "none",
    position: "relative",
  },
});
const compositeRef = [];
class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.myRef = React.createRef("");
    this.state = {
      List: [],
      backupList: [],
      openDropdown: false,
      selectedListDropdown: [],
      searchvalue: "",
      backup: [],
      style: "1px solid black",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", (e) => {
      var a = document.activeElement;
      this.keyDownHandler(e, a);
    });
  }

  async componentDidMount() {
    window.addEventListener("keydown", (e) => {
      var a = document.activeElement;
      this.keyDownHandler(e, a);
    });
    if (this.props.List.length != 0) {
      if (typeof this.props.List[0].isChecked == "undefined") {
        let newArrayList = [];
        if (this.props.autoselect) {
          this.props.List.filter(
            (item) => item.label != "" && item.value != ""
          ).map((response, index) => {
            newArrayList.push({
              value: response.value,
              label: response.label,
              isChecked:
                this.props.defaultvalue != undefined &&
                this.props.defaultvalue != ""
                  ? this.props.defaultvalue === response.label
                    ? true
                    : false
                  : this.props.List[0].value === response.value
                  ? true
                  : false,
            });
          });
        } else {
          this.props.List.filter(
            (item) => item.label != "" && item.value != ""
          ).map((response, index) => {
            newArrayList.push({
              value: response.value,
              label: response.label,
              isChecked:
                this.props.value != undefined && this.props.value != ""
                  ? this.props.value === response.label ||
                    this.props.value === response.value
                    ? true
                    : response.isChecked
                  : response.isChecked,
            });
          });
        }
        await this.setState({
          List: [...newArrayList],
          backupList: [...newArrayList],
        });
        let filteredvalue = newArrayList.filter(
          (item) => item.isChecked === true
        );
        if (newArrayList.filter((item) => item.isChecked).length != 0) {
          this.props.onChange(
            newArrayList.filter((item) => item.isChecked)
          );
        }
        this.setState({ selectedListDropdown: [...filteredvalue] });
      } else {
        await this.setState({
          List: [...this.props.List],
          backupList: [...this.props.List],
        });
        let filteredvalue = this.props.List.filter(
          (item) => item.isChecked
        );
        this.setState({ selectedListDropdown: [...filteredvalue] });
      }
    }
    this.setState({
      backup: this.state.List.map((item) => {
        item.isChecked = false;
        return item;
      }),
    });
  }
  componentDidUpdate(prev) {
    const { List, value, loader } = this.props;
    if (prev.List != List) {
      if (List.length != 0) {
        if (typeof List[0].isChecked != "undefined") {
          this.setState({
            List: List,
            backupList: List,
          });
          let filteredvalue = this.props.List.filter(
            (item) => item.isChecked
          );
          this.setState({ selectedListDropdown: [...filteredvalue] });
        } else {
          if (this.props.autoselect == true) {
            this.setState({
              List: List.map((item, index) => {
                return {
                  label: item.label,
                  value: item.value,
                  isChecked: index === 0 ? true : false,
                };
              }),
              backupList: List.map((item, index) => {
                return {
                  label: item.label,
                  value: item.value,
                  isChecked: index === 0 ? true : false,
                };
              }),
            });
            let filteredvalue = List.map((item, index) => {
              return {
                label: item.label,
                value: item.value,
                isChecked: index === 0 ? true : false,
              };
            }).filter((items) => items.isChecked);
            this.setState({ selectedListDropdown: [...filteredvalue] });
          } else {
            this.setState({
              List: List.filter(
                (items) => items.label != "" && items.value != ""
              ).map((item, index) => {
                return {
                  label: item.label,
                  value: item.value,
                  isChecked: false,
                };
              }),
              backupList: List.filter(
                (items) => items.label != "" && items.value != ""
              ).map((item, index) => {
                return {
                  label: item.label,
                  value: item.value,
                  isChecked: false,
                };
              }),
            });
          }
        }
      } else {
        this.setState({ List: [], backup: [], selectedListDropdown: [] });
      }
    }
    if (prev.loader != loader) {
    }
    if (value != undefined) {
      if (prev.value != value) {
        this.setState({
          List: List.filter((items) => items.label != "" && items.value != "").map(
            (item) => {
              return {
                ...item,
                isChecked:
                  value === item.label || value === item.value ? true : false,
              };
            }
          ),
          backupList: List,
        });
        let filteredvalue = List.filter(
          (items) => items.label != "" && items.value != ""
        )
          .map((item) => {
            return {
              ...item,
              isChecked:
                value === item.label || value === item.value ? true : false,
            };
          })
          .filter((val) => val.isChecked);
        this.setState({ selectedListDropdown: [...filteredvalue] });
      }
    } else {
    }
  }
  closeDropdown = () => {
    this.setState({ openDropdown: false });
  };
  openListDropdown = () => {
    this.setState({ openDropdown: true });
  };

  onKeyPressList = async (index) => {
    let list = this.state.List.map((el) => {
      return Object.assign({}, el, { isChecked: false });
    });
    const elementsIndex = index;
    let newArray = [...list];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChecked: !newArray[elementsIndex].isChecked,
    };
    const elementsIndex2 = index;
    let backuparray = [...this.state.backupList];
    backuparray[elementsIndex2] = {
      ...backuparray[elementsIndex2],
      isChecked: !backuparray[elementsIndex2].isChecked,
    };
    await this.setState({
      List: newArray,
      backupList: backuparray,
      selectedListDropdown: newArray.filter((item) => item.isChecked ),
    });
    this.props.onChange(newArray.filter((item) => item.isChecked ));
    this.closeDropdown();
  };
  SelectedList = async (item) => {
    let list = this.state.List.map((el) => {
      return Object.assign({}, el, { isChecked: false });
    });
    const elementsIndex = list.findIndex(
      (element) => element.value == item.value
    );

    let newArray = [...list];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isChecked: !newArray[elementsIndex].isChecked,
    };
    const elementsIndex2 = this.state.backupList.findIndex(
      (element) => element.value == item.value
    );
    let backuparray = [...this.state.backupList];
    backuparray[elementsIndex2] = {
      ...backuparray[elementsIndex2],
      isChecked: !backuparray[elementsIndex2].isChecked,
    };
    await this.setState({
      List: newArray,
      backupList: backuparray,
      selectedListDropdown: newArray.filter((item) => item.isChecked),
    });
    this.props.onChange(newArray.filter((item) => item.isChecked));
    this.closeDropdown();
  };

  clearSearch = () => {
    this.setState({ List: this.state.backupList, searchvalue: "" });
  };
  searchOnchange = (event) => {
    if (this.props.onKeyDown != undefined) {
      this.props.onKeyDown(event);
    }
    this.setState({ searchvalue: event.target.value });

    this.setState({
      List: this.state.backupList.filter((el) =>
        el.label.toUpperCase().includes(event.target.value.toUpperCase())
      ),
    });
  };

  keyDownHandler = (e, activeTextarea) => {
    if (e.key === "Tab") {
      if (activeTextarea.id != `${"normalwithsearch"}${this.props.id}`) {
      // } else {
        this.closeDropdown();
      }
    }
    if (e.key === "Enter") {
      if (activeTextarea.id == `${"normalwithsearch"}${this.props.id}`) {
        this.openListDropdown();
      } 
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <ErrorBoundary>
        <ClickAwayListener onClickAway={this.closeDropdown}>
          <div className={classes.dropDown}>
            <label className={classes.dropBoxLabel}>{this.props.label}</label>
            <div
              id={`${"normalwithsearch"}${this.props.id}`}
              className={classes.easySelect}
              tabIndex={0}
              style={{ width: this.props.width, border: this.state.style }}
              onClick={this.openListDropdown}
            >
              <div
                className={classes.normalDropdownArrow}
                onClick={this.openListDropdown}
              ></div>

              <div
                className={classes.normalDropdownStyledSelect}
                onClick={this.openListDropdown}
              >
                {this.props.value != undefined && this.props.value != "" ? (
                  this.props.value
                ) : this.state.selectedListDropdown.length == 0 ? (
                  <span className={classes.dropdownListPlaceholder}>
                    {this.props.placeholder}
                  </span>
                ) : this.state.selectedListDropdown.length > 1 ? (
                  `${this.state.selectedListDropdown.length} Selected`
                ) : (
                  this.state.selectedListDropdown[0].label
                )}
              </div>
              {this.state.openDropdown && (
                <List
                  type={"withoutcheckbox"}
                  onClick={this.SelectedList}
                  height={this.props.height}
                  loader={this.props.loader}
                  List={this.state.List}
                  icon={this.props.icon}
                  iconList={this.props.iconList}
                  onKeyPress={this.onKeyPressList}
                  onClickAway={this.closeDropdown}
                >
                  {this.props.isSearchable && (
                    <div className={classes.divSearcheasySelect}>
                      <div></div>

                      <input
                        type="text"
                        onChange={this.searchOnchange}
                        value={this.state.searchvalue}
                        className={[classes.searchInputeasySelect]}
                        placeholder={this.props.searchPlaceholder}
                      ></input>
                      {this.state.searchvalue.length != 0 ? (
                        <div
                          className={classes.clearSelectfromDiv}
                          style={{ marginTop: 6 }}
                          onClick={this.clearSearch}
                        ></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </List>
              )}
            </div>
            <span className={classes.errorDesign}>{this.props.error}</span>
          </div>
        </ClickAwayListener>
      </ErrorBoundary>
    );
  }
}

export default withStyles(styles)(connect(null, null)(Dropdown));
