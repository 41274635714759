import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../error_boundary/error_boundary";
import color from '../../utils/constants/color'

const styles = (theme) => ({
  editingDiv:{
    backgroundColor:color.WHITE,
    outline:"none",
    padding:6,
    borderRadius:5,
    // height:40,
    overflow:"hidden"
  }
});

class LabelWithText extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
      <div
        style={{
          // display: "flex",
          // flexDirection: "row",
          // alignItems: "center",
          gap: 10,
        }}
      >
        <span
          style={{
            // padding: 5,
            fontSize: 12,
            // backgroundColor: "rgb(251 251 251 / 56%)",
            borderRadius: 5,
          }}
        >
          {this.props.labelName}
        </span>
        <div style={{ fontSize: "16px", }} contentEditable={this.props.isEditable} className={this.props.isEditable?classes.editingDiv:""}   onInput={e =>{ if(this.props.onChange!=undefined){
                                this.props.onChange(e.currentTarget.textContent)
                            }}}>{this.props.text}</div>
      </div>
      </ErrorBoundary>
    );
  }
}

export default withStyles(styles)(withRouter(LabelWithText));
