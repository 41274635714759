import React from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import Button from "../../component/button/button";
import color from "../../utils/constants/color";
import label from "../../utils/constants/label";
import Profile from "../../utils/assets/user.png";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
// import VkenSignin from "../../utils/assets/vkensignin.png";
// import Vkenpwd from "../../utils/assets/vkenpwd.png";
// import Vkenbank from "../../utils/assets/vkeninternet.png";
// import Vken2faSignin from "../../utils/assets/2fasignin.png";
// import Vken2fapwd from "../../utils/assets/2fapwd.png";
// import Vken2fabank from "../../utils/assets/2fainternet.png";
import QrGenerator from "../../component/qrgenerator/qrgenerator";
// import VkenPayQR from "../../utils/assets/Pay-QR.png";
// import Vkenpaypassword from "../../utils/assets/pay-password.png";
// import VkenConfirmation from "../../utils/assets/Confirmation-Pay.png";
import LabelWithText from "../../component/label/label_with_text";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { updateHeader } from "../../redux/action/header/header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ErrorBoundary from "../../component/error_boundary/error_boundary";
import { Redirect } from "react-router-dom";
import path from "../../utils/constants/path";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import Grid from "@material-ui/core/Grid";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import AndroidOutlinedIcon from "@material-ui/icons/AndroidOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Loader from "../../component/loader/loader";
import Card from "@material-ui/core/Card";

const styles = (theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: color.LIGHT_PURPLE,
      "& > div:first-child": {
        display: "grid",
        gridTemplateColumns: "50% auto",
        gap: 30,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: "100%",
          gap: "unset",
        },
      }
    },

    activationCodeQR: {
      height: 120, width: 120,
      position: "relative",
      padding: "0px 10px 10px",
    },

    skeletonActivationCodeQR: {
      width: 120,
      height: 120,
      position: "absolute",
      transform: "none",
      transformOrigin: "unset",

    },


    activationQR: {
      "& div": {
        display: "flex",
        justifyContent: "center",
        paddingTop: 8
      }
    },

    skeletonAndroidQR: {
      height: 35,
      width: 35,
      transform: "none",
      transformOrigin: "unset",
      position: "absolute",
    },
    editingDiv: {
      backgroundColor: color.WHITE,
      outline: "none",
      padding: 6,
      borderRadius: 5,
      height: 40,
      overflow: "hidden",
    },

    slider: {
      textAlign: "justify",
      backgroundColor: color.WHITE,
      boxShadow: "0px 0px 30px",
      color: color.SHADOW_PURPLE,
      borderRadius: 12,
      padding: 20,
      maxHeight: 412,
      minHeight: 415,
      fontSize: 14,
      overflowY: "auto",
      marginTop: "0.5rem",
      "& div:not(div.MuiPaper-root)": {
        color: color.BLACK,
      },

      [theme.breakpoints.down('1159')]: {
        minHeight: 474,
      },

      "& div.MuiMobileStepper-root": {
        padding: 0,
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },

      "&::-webkit-scrollbar-track": {
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: color.SCROLLBARTHUMB,
        borderRadius: 12,
      },


    },

    imgvken: {
      height: 250,
      [theme.breakpoints.down('1160')]: {
        height: 210,
      },

      [theme.breakpoints.down('990')]: {
        height: 190,
      },

      [theme.breakpoints.down('sm')]: {
        height: "unset",
      },
      [theme.breakpoints.up('lg')]: {
        height: 300,
      },
      [theme.breakpoints.up('xl')]: {
        height: 450,
      },

    },

    userName: {
      fontSize: "1.15rem",
      fontWeight: 600,
      color: color.BLACK
    },

    loaderUserDetails: {
      width: "100%",
      height: 30.25,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      margin: 1
    },

    VkenSign: {
      listStyleType: "disc",
      margin: "1em 0",
    },

    qrDownload: {
      background: color.LIGHT_ASH,
      height: 35,
      width: 35,
      padding: 20,
      margin: "auto",
      borderRadius: 50,
    },

    imgContainer: {
      display: "flex",
      gap: 30,
      marginTop: 20,
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        justifyContent: "unset",
        overflowX: "auto",
      },
      "&::-webkit-scrollbar": {
        width: 4,
        height: 4
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: color.SCROLLBARTHUMB,
        borderRadius: 10
      },
    },

    nxtNavigate: {
      position: "absolute",
      padding: "10px 0px",
      display: "flex",
      alignItems: "flex-end",
      right: 30,
      "& .MuiSvgIcon-root": {
        fill: color.white
      },
    },

    txtTry: {
      width: "100%",
      padding: "20px 20px 0px 0px",
      position: "relative",
      alignItems: "flex-end",
      "& div > div": {
        padding: "8px 25px",
        borderRadius: 10,
      },
    },

    profilebg: {
      background: color.WHITE,
      boxShadow: "0px 0px 10px",
      color: color.SHADOW_PURPLE,
      borderRadius: "0.75rem",
    },

    appDownload: {
      fontWeight: "bold"
    },

    qrscan: {
      "& :hover": {
        height: 100,
      },
    },

    rightContent: {
      "& > div:first-child": {
        paddingBottom: 5,
        fontSize: 14,
        color: color.PRIMARY,
        letterSpacing: "0.00938em",
      },
    },

    userDetails: {
      // display: "grid",
      gap: 5,
      display: "flex",
      flexWrap: "wrap"
    },

    Icon: {
      padding: 2,
      borderRadius: "20%",
      marginRight: 10,
      fill: color.BLACK,
      "& + div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: color.BLACK,
        alignItems: "center",
        display: "flex",
      }
    },

    sliderHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: color.BLACK,
      "& + div": {
        color: color.BLACK,
        "& + ul": {
          color: color.BLACK,
        }
      }
    },
    txtTryOtherProduct: {
      position: "relative",
      marginBottom: 20,

      "& div > div": {
        padding: "8px 25px",
        borderRadius: 10,
      },
    },

    nxtNavigateTryOtherProduct: {
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        display: "flex",
        color: color.SECONDARY,
        cursor: "pointer",
      },
    },
    app: {
      background: "rgb(146, 39, 143) none repeat scroll 0% 0%",
      margin: "0px -10px 0px",
      color: color.WHITE,
      textAlign: "center",
      padding: 10,
    },

    downloadCard: {
      boxShadow: "0px 0px 10px ",
      color: color.SHADOW_PURPLE,
      borderRadius: "0.75rem",
      padding: 15,
      "&:hover": {
        cursor: "pointer",
        "& div > div > img": {
          transform: "scale(3.5)",
          marginTop: -14,
          paddingTop: 16
        },
      },
    },

    downloadQR: {
      height: 35,
      background: color.WHITE,
    },

    download: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      fontSize: 16,
      textAlign: "center",
      fontWeight: "bold",
      width: "100%",
      padding: 10,
      cursor: "pointer",
      fill: color.BLACK,
      "& .MuiSvgIcon-root": {
        fill: color.BLACK,
        "& + div": {
          color: color.BLACK,
        }
      }
    },


    activationCard: {
      marginTop: "0.5rem",
      padding: 20,
      boxShadow: "rgb(0 0 0 / 10%) 0px 0px 30px",
      background: color.WHITE,
      borderRadius: 12,
    },

    activation: {
      display: "flex",
      alignItems: "center",
      "& img": {
        height: "2rem",
        boxShadow: "rgba(0, 0, 0, 0.098) 0px 0px 2px",
        padding: 2,
        background: color.LIGHT_PURPLE,
        borderRadius: "20%",
      },
    },


    profile: {
      width: 122,
      height: 122,
      borderRadius: "50%",
      // backgroundColor: color.LIGHT_PURPLE,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      // boxShadow: "rgb(0 0 0 / 5%) 0px 0.1rem 1rem 0.25rem",
      // border: `3px solid ${color.SECONDARY}`,
      margin: 7,
    },

    profileGrid: {
      display: "flex",
      justifyContent: "center",
      padding: "0px !important"
    },

    loaderUserProfile: {
      width: 133,
      height: 133,
      borderRadius: "100%",
      margin: 7,
      transform: "none",
      transformOrigin: "initial",
    },

    profileInfo: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
      "& .MuiSvgIcon-root": {
        "& + div": {
          textOverflow: "ellipsis",
          overflow: "hidden",
          alignItems: "center",
          display: "flex",
        }
      },
    },


  };
};
class UserDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isEditable: false,
      email: "",
      mobile: "",
      name: "",
      designation: "",
      company: "",
      isLoading: false,
      image: "",
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
    if (this.props.profileData.length != 0) {
      if (this.props.profileData.Table1.length != 0) {
        this.setState({
          name: this.props.profileData.Table1[0].FirstName,
          email: this.props.profileData.Table1[0].Email,
          designation: this.props.profileData.Table1[0].Designation,
          companyName: this.props.profileData.Table1[0].Company,
        });
      }
    }
  }
  componentDidUpdate(prev) {
    const { profileData } = this.props;

    if (profileData.length != 0) {
      if (prev.profileData != profileData) {
        this.setState({
          name: profileData.Table1[0].FirstName,
          email: profileData.Table1[0].Email,
          designation: profileData.Table1[0].Designation,
          companyName: profileData.Table1[0].Company,
        });
      }
    }
  }
  onEditing = () => {
    this.setState({ isEditable: true });
  };

  onClickTryNow = () => {
    let data = {};
    data.activeStep = 3;
    data.id = this.props.productID;
    data.issuerID = this.props.issuerID;
    data.completedStep = 2;
    data.mainHeader =
      this.props.productID === 1
        ? label.product.vkenSignIn
        : this.props.productID == 2
          ? label.product.vken2FA
          : this.props.productID == 3 ? label.product.vkenPay : "Welcome";
    data.subHeader = "";
    data.enableBack = true;
    this.props.updateHeader(data);
    this.props.handleNextStep(data);
  };
  render() {
    const { classes } = this.props;
    try {
      return (
        <ErrorBoundary>
          <div className={classes.container}>
            <div>
              <div
                style={{
                  marginTop: "0.5rem",
                  height: "fit-content",
                  borderRadius: 12,
                }}
              >
                <div
                  className={classes.profilebg}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 20,
                  }}
                >
                  {this.state.isLoading ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader className={classes.loaderUserProfile}></Loader>
                      </Grid>
                      <Grid item xs={12} sm={8}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Loader className={classes.loaderUserDetails}></Loader>
                        <Loader className={classes.loaderUserDetails}></Loader>
                        <Loader className={classes.loaderUserDetails}></Loader>
                        <Loader className={classes.loaderUserDetails}></Loader>
                        <Loader className={classes.loaderUserDetails}></Loader>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={3} style={{ display: "flex", alignItems: "center", flex: 1 }}>
                      <Grid item xs={12} sm={4} className={classes.profileGrid}>
                        <div className={classes.profile}>
                          {this.props.profileData.length != 0 &&
                            this.props.profileData.Table1.length != 0 &&
                            this.props.profileData.Table1[0].Image != "" &&
                            this.props.profileData.Table1[0].Image != null ? (
                            <div
                              style={{
                                backgroundImage:
                                  this.props.profileData.Table1[0].Image.search(
                                    window.globalConfig.Image
                                  ) != -1
                                    ? `url(${this.props.profileData.Table1[0].Image})`
                                    : `url(${window.globalConfig.UserImage}${this.props.profileData.Table1[0].Image})`,
                                // backgroundSize: "cover",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                width: 122,
                                height: 122,
                                borderRadius: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                border: "3px solid #f3edfd",
                              }}
                            ></div>
                          ) : (
                            <img
                              src={Profile}
                              style={{
                                width: 40,
                                height: 40,
                                marginTop: -5,
                              }}
                            ></img>
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className={classes.userName}>
                              {this.props.profileData.length != 0 &&
                                this.props.profileData.Table1.length != 0
                                ? `${this.props.profileData.Table1[0].UserName}`
                                : "NA"}
                            </div>
                          </div>


                          <Grid className={classes.userDetails}>
                            <Grid container>
                              <AssignmentIndOutlinedIcon
                                className={classes.Icon}
                              />
                              <LabelWithText
                                text={
                                  this.props.profileData.length != 0 &&
                                    this.props.profileData.Table1.length != 0
                                    ? this.props.profileData.Table1[0]
                                      .Designation || "NA"
                                    : "NA"
                                }
                              ></LabelWithText>
                            </Grid>
                            {/* )} */}

                            <Grid container>
                              <BusinessOutlinedIcon className={classes.Icon} />
                              <LabelWithText
                                text={
                                  this.props.profileData.length != 0 &&
                                    this.props.profileData.Table1.length != 0
                                    ? this.props.profileData.Table1[0].Company ||
                                    "NA"
                                    : "NA"
                                }
                              ></LabelWithText>
                            </Grid>
                            {this.props.profileData.length != 0 &&
                              this.props.profileData.Table1.length != 0 ?
                              <div className={classes.profileInfo}>
                                <MailOutlinedIcon className={classes.Icon} />{" "}
                                <div> {this.props.profileData.Table1[0].Email || "NA"} </div>
                              </div> : <div className={classes.profileInfo}>
                                <MailOutlinedIcon className={classes.Icon} />
                                <div>NA
                                </div>
                              </div>}
                            <Grid container>
                              <CallOutlinedIcon className={classes.Icon} />
                              <div>
                                {this.props.profileData.length != 0 &&
                                  this.props.profileData.Table1.length != 0
                                  ? this.props.profileData.Table1[0].Mobile ||
                                  "NA"
                                  : "NA"}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>

                <div className={classes.activationCard}>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <div className={classes.activationQR}>
                          <QrGenerator
                            word={
                              this.props.profileData.length != 0 &&
                                this.props.profileData.Table1.length != 0
                                ? this.props.profileData.Table1[0].ActivationCode
                                : ""
                            }
                            skeletonClassName={classes.skeletonActivationCodeQR}
                            className={classes.activationCodeQR}
                          ></QrGenerator>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <div
                          style={{
                            width: "100%",
                            alignItems: "flex-start",
                            borderRadius: 10,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <h3
                              style={{
                                margin: 0,
                                marginBottom: 8,
                                letterSpacing: "0.00938em",
                              }}
                            >
                              {this.props.productName == label.product.vkenSignIn ?
                                <span>
                                  <span> AutharcId</span>
                                </span>
                                : this.props.productName == label.product.vken2FA ?
                                  <span>
                                    <span> AutharcToken</span>
                                  </span> :

                                  <span>
                                    <span> AutharcCard</span>
                                  </span>
                              }

                            </h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 10,
                              width: "100%",
                            }}
                          >
                            <Grid container>
                              <div className={classes.rightContent}>
                                <div>Activation Code</div>
                                <LabelWithText
                                  text={
                                    this.props.profileData.length != 0 &&
                                      this.props.profileData.Table1.length != 0
                                      ? this.props.profileData.Table1[0]
                                        .ActivationCode
                                      : "NA"
                                  }
                                ></LabelWithText>
                              </div>
                            </Grid>

                            <Grid container style={{ gap: "10px 40px" }}>
                              <div className={classes.rightContent}>
                                <div>Activation Field One</div>
                                <LabelWithText
                                  text={
                                    this.props.profileData.length != 0 &&
                                      this.props.profileData.Table1.length != 0
                                      ? this.props.profileData.Table1[0]
                                        .ActivationFieldOne
                                      : "NA"
                                  }
                                ></LabelWithText>
                              </div>

                              <div className={classes.rightContent}>
                                <div>Activation Field Two</div>
                                <LabelWithText
                                  text={
                                    this.props.profileData.length != 0 &&
                                      this.props.profileData.Table1.length != 0
                                      ? this.props.profileData.Table1[0]
                                        .ActivationFieldTwo
                                      : "NA"
                                  }
                                ></LabelWithText>
                              </div>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <div
                      className={classes.txtTry}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginLeft: "0%",
                        marginTop: "0%",
                      }}
                    >
                      <Button
                        onClick={this.onClickTryNow}
                        text={label.buttonLabel.tryNow}
                        radius={40}
                        color={color.WHITE}
                        type="Primary"
                        backgroundColor={color.SECONDARY}
                        endIcon={<NavigateNextIcon />}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.slider}>
                <div
                  style={{
                    display: "flex",
                    gap: 28,
                    justifyContent: "center",
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <span className={classes.sliderHeader}>
                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId</span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken</span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }

                    </span>
                    <div>
                      The
                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId </span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken </span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }
                      applet can also be used on a
                      cell phone to create a

                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId </span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken </span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }

                      ID
                      single-use login number that can then be typed into a
                      computer to create the most secure means possible of
                      accessing online systems.
                    </div>
                    <div>
                      Our Apps are now available on <span className={classes.appDownload}>Apple App Store/Android PlayStore. </span>
                      Search for Autharc
                    </div>

                    <Grid
                      container
                      style={{
                        gap: 20,
                        justifyContent: "center",
                      }}
                    >

                      <Grid item xs={12} sm={5}>
                        <Card elevation={0} className={classes.downloadCard}>

                          <div className={classes.qrDownload}>
                            <QrGenerator
                              word={window.globalConfig.Android}
                              skeletonClassName={classes.skeletonAndroidQR}
                              className={classes.downloadQR}
                            ></QrGenerator>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className={classes.download}>
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  gap: 10,
                                  justifyContent: "center",
                                }}
                              >
                                <AndroidOutlinedIcon />
                                <div>Android</div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <Card elevation={0} className={classes.downloadCard}>
                          <div className={classes.qrDownload}>
                            <QrGenerator
                              skeletonClassName={classes.skeletonAndroidQR}
                              word={window.globalConfig.IOS}
                              className={classes.downloadQR}
                            ></QrGenerator>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className={classes.download}>
                              <div
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  gap: 10,
                                  justifyContent: "center",
                                }}
                              >
                                <PhoneAndroidIcon />
                                <div>IOS</div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600 }}>Activation process</span> is
                  vital to accessing
                  {this.props.productName == label.product.vkenSignIn ?
                    <span>
                      <span> AutharcId </span>
                    </span>
                    : this.props.productName == label.product.vken2FA ?
                      <span>
                        <span> AutharcToken </span>
                      </span> :

                      <span>
                        <span> AutharcCard</span>
                      </span>
                  }
                  . It not only
                  registers the app, but also retrieves the relevant data that
                  is applicable only to that particular user.
                  <ul>
                    <li>
                      On Activation Page One, enter the Activation Code, enter
                      the Activation Field One and Activation Field Two
                      information, click Submit.
                    </li>
                    <li>
                      On Activation Page Two. Set a Password and Verify
                      Password. Click Submit.
                    </li>
                  </ul>
                  {this.props.productID == 1 ? (
                    <div className={classes.imgContainer}>
                      {/* <img src={VkenSignin} className={classes.imgvken} />
                      <img src={Vkenpwd} className={classes.imgvken} />
                      <img src={Vkenbank} className={classes.imgvken} /> */}
                    </div>
                  ) : this.props.productID == 2 ? (
                    <div className={classes.imgContainer}>
                      {/* <img src={Vken2faSignin} className={classes.imgvken} />
                      <img src={Vken2fapwd} className={classes.imgvken} />
                      <img src={Vken2fabank} className={classes.imgvken} /> */}
                    </div>
                  ) : (
                    <div className={classes.imgContainer}>
                      {/* <img src={VkenPayQR} className={classes.imgvken} /> */}

                      {/* <img src={VkenConfirmation} className={classes.imgvken} />
                      <img src={Vkenpaypassword} className={classes.imgvken} /> */}
                    </div>
                  )}
                  <div>
                    <div
                      className={classes.sliderHeader}
                      style={{ margin: "20px 0" }}
                    >
                      Generate a
                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId </span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken </span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }
                      Number
                    </div>
                    <div>
                      On successful activation, the user account seed data is
                      stored in the
                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId </span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken </span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }
                      app in an encrypted
                      format. The password entered into the app is also encoded
                      and used as a key to unlock the encrypted parts of the
                      seed data.
                      {this.props.productName == label.product.vkenSignIn ?
                        <span>
                          <span> AutharcId </span>
                        </span>
                        : this.props.productName == label.product.vken2FA ?
                          <span>
                            <span> AutharcToken </span>
                          </span> :

                          <span>
                            <span> AutharcCard</span>
                          </span>
                      }
                      is generated locally
                      as follows:
                    </div>

                    <ul className={classes.VkenSign}>
                      <li>
                        Enter the Password in the
                        {this.props.productName == label.product.vkenSignIn ?
                          <span>
                            <span> AutharcId </span>
                          </span>
                          : this.props.productName == label.product.vken2FA ?
                            <span>
                              <span> AutharcToken </span>
                            </span> :

                            <span>
                              <span> AutharcCard</span>
                            </span>
                        }
                        app and click Submit.
                      </li>

                      <li>
                        A one-time use
                        {this.props.productName == label.product.vkenSignIn ?
                          <span>
                            <span> AutharcId </span>
                          </span>
                          : this.props.productName == label.product.vken2FA ?
                            <span>
                              <span> AutharcToken </span>
                            </span> :

                            <span>
                              <span> AutharcCard</span>
                            </span>
                        }
                        number is
                        generated. A QR Code will be displayed which can be
                        easily scan.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              let value = {};
              value.id = this.props.productID;
              value.issuerID = this.props.issuerID;
              value.activeStep = 0;
              value.enableBack = true;
              value.completedStep = "";
              value.mainHeader = "Welcome";
              value.subHeader = "";
              this.props.updateHeader(value);
              this.props.goToStep(value);
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              className={classes.txtTryOtherProduct}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "0%",
                marginTop: "0%",
              }}
            >
              <div className={classes.nxtNavigateTryOtherProduct}>
                <NavigateBeforeIcon />
              </div>
              <div>
                <h4
                  style={{
                    margin: 0,
                    color: color.SECONDARY,
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  Try Other Products
                </h4>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
    } catch (err) {
      console.log(err);
      return <Redirect to={path.notFound} />;
    }
  }
}
const mapStateToProps = (state) => ({
  profileData: state.EntryFormList.profile,

  headerName: state.Header.headerName,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateHeader }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails))
);
// export default withStyles(styles)(withRouter(UserDetails));
