import { replaceNull } from "../../../utils/common_function/common_function";
import * as actionType from "../../action_type/action_type";
const initialState = {
  productList:[],
  isLoading: false,
};
const product_list_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PRODUCT_LIST:
     
        return {
          ...state,
          productList: action.payload.length != 0 ? replaceNull(action.payload,"") : state.entryFormList,
        };
    case actionType.LOADER_PRODUCT_LIST:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.EMPTY_PRODUCT_LIST:
      return {
        ...state,
         productList:  [],
         isLoading: action.payload,
      };
    default:
      return state;
  }
};
export default product_list_reducer;
