import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import path from "../utils/constants/path";
import { withRouter } from "react-router";
import Login from "../container/login/login";
import App from "../container/application/app";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { CompanyAsset } from '../utils/constants/company_assets'
import reducer from "../redux/reducer/index";
import PageNotFound from "../component/error_boundary/pagenotfound";
import NotFound from "../component/error_boundary/notfound";
import { Redirect } from "react-router-dom";
const styles = (theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "15% 85%",
  },
});
const enhancers = [applyMiddleware(thunk)];
const composeEnhancers = process.env.NODE_ENV !== 'production'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: false
  })
  : compose;
const persistConfig = {
  key: 'root',
  transforms: [
    encryptTransform({
      secretKey: CompanyAsset.companyName,
      onError: function (error) {
      },
    }),
  ],
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer, composeEnhancers(...enhancers))
class ZRouter extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {

    let persist = persistStore(store)

    return (
      <>
        <Provider store={store}>
          <div>
            <PersistGate loading={null} persistor={persist}>
              <Switch>
                <Route
                  path={path.Login}
                  exact
                  render={(props) => (
                    <Login header="Login" {...props} />
                  )}
                />
                <Route
                  path={path.dashboard}

                  render={(props) => (
                    <App header="Avatar" {...props} />
                  )}
                />
                <Route path={path.notFound} exact component={NotFound} />
                <Route path={path.pageNotFound} exact component={PageNotFound} />

                <Redirect from='*' to={path.pageNotFound} />
              </Switch>
            </PersistGate>
          </div>
        </Provider>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(ZRouter));
