import * as actionType from "../../action_type/action_type";
import Networking from "../../../utils/network/apiaccess";
import url from "../../../utils/ApiValues/url";

export function getBankList(data) {
  return (dispatch) => {
    getBankListAction(dispatch, data);
  };
}
function getBankListAction(dispatch, data) {
    dispatch({
        type: actionType.LOADER_BANK_LIST,
        payload: true,
      })
      dispatch({
        type: actionType.EMPTY_BANK_LIST,
        payload: [],
      })
 
      Networking.GetApiWithToken(url.BankList,"",data)
      .then(result=>{ 
        if(result.status=="S"){
          dispatch({
            type: actionType.LOADER_BANK_LIST,
            payload: false,
          })
          dispatch({
            type: actionType.BANK_LIST,
            payload: result,
          })

        }else{
          
          dispatch({
            type: actionType.LOADER_BANK_LIST,
            payload: false,
          })
          if(result.status==401||result.status==405){
            dispatch({
              type: actionType.SESSION_EXPIRE,
              payload:true,
            });
          }
        }
        })
      .catch(function (error) {
       
          dispatch({
            type: actionType.LOADER_BANK_LIST,
            payload: false,
          })
               // 
       });
}
