import { replaceNull } from "../../../utils/common_function/common_function";
import * as actionType from "../../action_type/action_type";
const initialState = {
  loginDetails: [],
  isLoading: false,
  message: "",
  openAlert: false,
  token: "",
  sessionExpire: false,
};
const login_reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_DETAILS:
      return {
        ...state,
        loginDetails: replaceNull(JSON.parse(action.payload.data), ""),
        token: action.payload.token,
        sessionExpire: false,
      };
    case actionType.UPDATE_PROFILE_IMAGE:
      let login = state.loginDetails;
      let newArray = [...state.loginDetails.Table1];
      newArray[0] = {
        ...newArray[0],
        UserImage: action.payload,
      };
      login.Table1 = newArray;

      return {
        ...state,
        loginDetails: replaceNull(login, ""),
      };
    case actionType.LOADER_LOGIN_DETAILS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.SESSION_EXPIRE:
      return {
        ...state,
        sessionExpire: action.payload,
      };
    case actionType.EMPTY_LOGIN_DETAILS:
      return {
        ...state,
        loginDetails: [],
        isLoading: false,
        message: "",
        openAlert: false,
        token: "",
        sessionExpire: false,
      };
    case actionType.LOGIN_FAILURE:
      return {
        ...state,
        message: action.payload.message,
        openAlert: action.payload.openAlert,
      };
    default:
      return state;
  }
};
export default login_reducer;
