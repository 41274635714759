import url from "../../../utils/ApiValues/url";
import label from "../../../utils/constants/label";
import path from "../../../utils/constants/path";
import Networking from "../../../utils/network/apiaccess";
import * as actionType from "../../action_type/action_type";
import storage from "redux-persist/lib/storage";

export function getLogin(data, onSuccess, onFailure) {
  return (dispatch) => {
    getLoginDetailsAction(dispatch, data, onSuccess, onFailure);
  };
}
function getLoginDetailsAction(dispatch, data, onSuccess, onFailure) {
  dispatch({
    type: actionType.LOADER_LOGIN_DETAILS,
    payload: true,
  });
  dispatch({
    type: actionType.EMPTY_LOGIN_DETAILS,
    payload: [],
  });
  Networking.PostApi(url.Login, data)
    .then((result) => {
      if (result.status == "S") {
        if (result.data != null) {
          if (JSON.parse(result.data).Table[0].Status == "S") {
            dispatch({
              type: actionType.LOADER_LOGIN_DETAILS,
              payload: false,
            });
            dispatch({
              type: actionType.LOGIN_DETAILS,
              payload: result,
            });
            onSuccess(JSON.parse(result.data).Table[0].Message);
          } else {
            dispatch({
              type: actionType.LOADER_LOGIN_DETAILS,
              payload: false,
            });
            onFailure(JSON.parse(result.data).Table[0].Message);
          }
        } else {
          onFailure(label.messages.apiError);
        }
      } else {
        dispatch({
          type: actionType.LOADER_LOGIN_DETAILS,
          payload: false,
        });
        onFailure(result.message);
      }
    })
    .catch(function (error) {
      onFailure(label.messages.apiError);
      dispatch({
        type: actionType.LOADER_LOGIN_DETAILS,
        payload: false,
      });
    });
}

export const loggedOutAction = (history) => {
  storage.removeItem('persist:root')
  return (dispatch) => {
    dispatch({
      type: actionType.EMPTY_LOGIN_DETAILS,
      payload: [],
    });
    dispatch({
      type: actionType.EMPTY_ENTRY_FORM_DETAILS,
      payload: [],
    });
    dispatch({
      type: actionType.EMPTY_CHECK_DUPLICATE,
      payload: [],
    });
    dispatch({
      type: actionType.EMPTY_BANK_LIST,
      payload: [],
    });
    dispatch({
      type: actionType.EMPTY_HEADER,
      payload: [],
    });
    dispatch({
      type: actionType.EMPTY_PRODUCT_LIST,
      payload: [],
    });
    dispatch({
      type: actionType.SESSION_EXPIRE,
      payload: false,
    });
    history.push(path.Login)
  };
};
export const updateProfileImage = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionType.UPDATE_PROFILE_IMAGE,
      payload: data,
    });

  };
};
