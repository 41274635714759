import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import color from '../../utils/constants/color'
import ErrorBoundary from "../error_boundary/error_boundary";
import { withStyles } from "@material-ui/styles";
const styles=(theme)=>{
  return{
    root:{
    //  "& .MuiCircularProgress-colorPrimary": {
    //     color:color.WHITE
    // }
    }
  }
}
class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
try{
    if (this.props.type == "loader") {
    
      return (
        <ErrorBoundary>
        <div
        //   className="Loader"
        >
          <CircularProgress
            size={this.props.size==undefined?40:this.props.size}
            // thickness={5}
            className={this.props.className}
            thickness={this.props.thickness != undefined ? this.props.thickness : 2}
            style={{color:this.props.color}}
          />
        </div>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary>
          <Skeleton className={this.props.className} style={this.props.style}>
          </Skeleton>
       </ErrorBoundary>
      )
    }}catch(err){
console.log(err)
    }
  }
}
export default withStyles(styles)(Loader);