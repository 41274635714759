//LOGIN
export const EMPTY_LOGIN_DETAILS = 'EMPTY_LOGIN_DETAILS';
export const LOADER_LOGIN_DETAILS = 'LOADER_LOGIN_DETAILS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_DETAILS = 'LOGIN_DETAILS';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SESSION_EXPIRE="SESSION_EXPIRE";
export const UPDATE_PROFILE_IMAGE="UPDATE_PROFILE_IMAGE"

//TEST MIRI
export const EMPTY_TEST_MIRI = 'EMPTY_TEST_MIRI';
export const LOADER_TEST_MIRI = 'LOADER_TEST_MIRI';
export const TEST_MIRI = 'TEST_MIRI';
//ENTRY FORM LIST
export const LOADER_ENTRY_FORM_DETAILS="LOADER_ENTRY_FORM_DETAILS";
export const EMPTY_ENTRY_FORM_DETAILS="EMPTY_ENTRY_FORM_DETAILS";
export const ENTRY_FORM_DETAILS="ENTRY_FORM_DETAILS";

//CHECK DUPLICATE
export const LOADER_CHECK_DUPLICATE="LOADER_CHECK_DUPLICATE";
export const EMPTY_CHECK_DUPLICATE="EMPTY_CHECK_DUPLICATE";
export const CHECK_DUPLICATE="CHECK_DUPLICATE";
//BANK LIST
export const LOADER_BANK_LIST="LOADER_BANK_LIST";
export const EMPTY_BANK_LIST="EMPTY_BANK_LIST";
export const BANK_LIST="BANK_LIST";
//PRODUCT LIST
export const LOADER_PRODUCT_LIST="LOADER_PRODUCT_LIST";
export const PRODUCT_LIST="PRODUCT_LIST";
export const EMPTY_PRODUCT_LIST="EMPTY_PRODUCT_LIST";
//HEADER
export const UPDATE_HEADER="UPDATE_HEADER";
export const EMPTY_HEADER="EMPTY_HEADER";