import React from "react";
import { withStyles } from "@material-ui/core/styles";
import color from "../../utils/constants/color";
import Button from "../button/button";
import '../../utils/stylesheet/productcard.css'
import ErrorBoundary from "../error_boundary/error_boundary";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import label from "../../utils/constants/label";

const styles = (theme) => ({
  container: {
    "& div.MuiGrid-grid-xs-8": {

      [theme.breakpoints.down('500')]: {
        margin: 8
      },

      [theme.breakpoints.between('375', '500')]: {
        flexGrow: 0,
        maxWidth: "51%",
        flexBasis: "51%",
      },

      [theme.breakpoints.between('500', '600')]: {
        flexGrow: 0,
        maxWidth: "33.333333%",
        flexBasis: "33.333333%",
      }
    }
  },

  btnSignIn: {
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: 10,
    alignItems: "center",
    height: 180,
    // width: 100,
    // border: `solid 1px ${color.BLACK} !important`,
    [theme.breakpoints.down('sm')]: {
      padding: "0px 10px"
    }
  },
  textStyle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    color: color.SECONDARY,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    }
  },
  image: {
    height: 90,
  },

  signinCard: {
    width: 190,
    // boxShadow: "0 0 20px 0 rgb(0 0 0 / 10%)",
    boxShadow: "0 0 20px 0 rgb(243 237 253)",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0 0 20px 0 rgb(243 237 253 / 19%)",
      transform: `scale(${1.03})`,
    },
    borderRadius: "0.75rem",
    background: color.WHITE,
  },

  copyright: {
    fontWeight: 100,
    fontSize: "unset",
    fontStyle: "unset",
    textAlign: "unset",
  }
});

class ProductCard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    if (this.props.activeStep == 0) {
      return (
        <ErrorBoundary>
          <Grid container xs={12} className={classes.container} spacing={1}>
            {this.props.List.map((item, index) => {

              return (
                  <Grid item xs={8} sm={4} xl={3}>
                  <div className={classes.signinCard} onClick={item.onClick}>
                    <div className={classes.card}>
                      {/* <img src={`${window.globalConfig.Image}${item.image}`} className={classes.image}></img> */}
                      <span className={classes.textStyle} style={{ color: item.color }}>
                        {item.name}
                        {/* <sup>{`\u00a9`}</sup> */}
                        {item.productID != 3 ?
                          <span>
                            <span className={classes.copyright}>
                              {/* <sup>{`\u00a9`}</sup>  */}
                            </span>
                            {/* {item.productID == 1 ? label.product.signIn : item.productID == 2 ? label.product.twoFA : label.product.pay} */}
                          </span>
                          :
                          <span>
                            {/* {item.productID == 1 ? label.product.signIn : item.productID == 2 ? label.product.twoFA : label.product.pay} */}
                            <span className={classes.copyright}>
                              {/* <sup>{`\u00a9`}</sup>  */}
                            </span>
                          </span>
                        }
                      </span>
                      {/* <div className={classes.btnSignIn}>
                        <Button text={item.buttonText} color={item.color} type="Primary" onClick={item.onClick}></Button>
                      </div> */}
                    </div>
                  </div>
                </Grid>
              );

            })}
          </Grid>
        </ErrorBoundary>
      );
    } else {
      const products = [
        {
          id: "rec1Ntk7siEEW9ha1",
          name: "Apple iPhone 13 Pro Max",
          price: 129900,
          image: "https://i.ibb.co/thf0SL3/61rrisp8qi-L-SL1500.png",
          company: "Only",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          category: "Casual",
          shipping: true,
          amount: 1,
          stock: 2,
        },
        {
          id: "recNZ0koOqEmilmoz",
          name: "Samsung Galaxy A32",
          price: 23499,
          image: "https://i.ibb.co/D5nGKrs/81-TTzz-RL-SL1500.png",
          company: "Zebronics ZEB-KM2100 Multimedia USB Keyboard",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          category: "Office",
          shipping: true,
          amount: 1,
          stock: 3,
        },
        {
          id: "recrfxv3EwpvJwvjq",
          name: "Redmi Note 11T 5G",
          price: 19999,
          image: "https://i.ibb.co/TMzG7fJ/81-L7-WGg-Dk-L-SL1500.png",
          company: "Only",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, provident. Itaque totam neque, nesciunt consequatur error quasi ipsam accusamus facere!Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          category: "Office",
          shipping: true,
          amount: 1,
          stock: 4,
        }]
      return (
        <>
          <div className="cocktails-center">
            {products.map((product) => {
              const { id, image, name, price } = product;
              return (
                <article key={id} className="cocktail">
                  <div className="img-container">
                    <img src={image} alt={name} className="product-img" />
                  </div>
                  <div className="cocktail-footer">
                    <div className="product">
                      <h4>{name}</h4>
                      <h4 className="price">&#8377;{price}</h4>
                    </div>
                  </div>
                  <div onClick={() => { window.open(window.globalConfig.Ecommerce + "?" + id) }}>
                    <Link
                      className="btn-view"
                    >
                      {/*className="prod-details">*/}
                      Buy Now
                    </Link>
                  </div>
                </article>
              );
            })}
          </div>
        </>
      );
    }

  }
}

const mapStateToProps = (state) => ({
  isLoading: state.ProductList.isLoading
});
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(ProductCard))
);
