import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import NotfoundImage from '../../utils/assets/1630646331589.jpeg';
import Button from '../button/button';
import { withRouter } from "react-router";
import color from '../../utils/constants/color';
import { loggedOutAction } from '../../redux/action/login/login';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const styles = theme => ({
    errorPage: {
        backgroundImage: `url(${NotfoundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        margin: 0,
        padding: 0,
        position: "fixed",
        width: "100%"
    },
    errorWrapper: {
        maxWidth: "50%",
        margin: "6% auto",
        zIndex: 10,
        textAlign: "center",
        [theme.s.down('sm')]: {
            maxWidth: "100%",
        }
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(99, 95, 184 ,0.5)"
    },
    header1: {
        fontSize: "10em",
        color: color.LIGHT_WHITE,
        margin: 0
    },
    header2: {
        fontSize: "2em",
        color: color.WHITE,
        lineHeight: "1.5em",
        margin: 0
    }
})

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
onClick=()=>{
  this.props.loggedOutAction(this.props.history)
}

    render() {
        const { classes } = this.props
            return (<div className={classes.errorPage}>
                <div className={classes.overlay}>
                    <div className={classes.errorWrapper}>
                        <p className={classes.header1}>
                            500
                        </p>
                        <p className={classes.header2}>
                            <span>Whoops!</span>
                            <br />

                            Something went wrong on our end,
                            <br />
                            we've alerted our engineers to see what happened.

                        </p>
                        <div style={{ width: "35%", justifyContent: "center", marginLeft: "32%",display:"flex" }}>
                        <Button type="Primary" color={color.WHITE}  radius={10}  backgroundColor={color.SECONDARY} text="Let's Go Login" onClick={this.onClick}></Button>
                        </div>
                    </div>
                    </div>
            </div>)
      

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ loggedOutAction }, dispatch);
  };
export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(NotFound)))
